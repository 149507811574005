import { useEffect } from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { IntakeFormUpdate } from '../types/steps'

export const useErrorsDebug = (
  errors: DeepMap<IntakeFormUpdate, FieldError>
) => {
  useEffect(() => {
    if (
      process.env.REACT_APP_DEBUG_VALIDATION_ERROR === 'true' &&
      Object.keys(errors).length
    ) {
      console.info('INTAKE_FORM_VALIDATION_ERRORS', errors)
    }
  }, [errors])
}
