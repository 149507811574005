import React from 'react'
import { Route, RouteComponentProps } from 'react-router'
import { Layout } from '../components/Layout'
import { Basics } from '../steps/Basics'
import { HealthHistory } from '../steps/HealthHistory/HealthHistory'
import { Insurance } from '../steps/Insurance/Insurance'
import { PatientBackgroud } from '../steps/PatientBackgroud'
import { ReviewSubmission } from '../steps/ReviewSubmission/ReviewSubmission'
import { VisionHistory } from '../steps/VisionHistory'
import { step } from '../utils/routes'

type FormStepsProps = RouteComponentProps

export const FormSteps: React.FC<FormStepsProps> = () => {
  return (
    <Layout>
      <Route path={step('1_CONTACT_INFO')} component={Basics} />
      <Route path={step('2_INSURANCE_INFO')} component={Insurance} />
      <Route path={step('4_VISION_HISTORY')} component={VisionHistory} />
      <Route path={step('3_HEALTH_HISTORY')} component={HealthHistory} />
      <Route path={step('5_PATIENT_BACKGROUND')} component={PatientBackgroud} />
      <Route path={step('6_CONFIRMATION')} component={ReviewSubmission} />
    </Layout>
  )
}
