import React from 'react'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'
import { useFormMode } from '../../../../hooks/useFormMode'
import { Button, ButtonProps } from '../../../Button'
import { ContinueLaterModal } from '../ContinueLaterModal'

export interface ContinueLaterButtonProps extends ButtonProps {}

export const ContinueLaterButton: React.FC<ContinueLaterButtonProps> = (
  props
) => {
  const { t } = useTranslation()
  const [isOpen, onRequestClose] = useToggle(false)
  const { showContinueLater } = useFormMode()

  return showContinueLater ? (
    <>
      <Button $outlined onClick={onRequestClose} {...props}>
        {t('core.continueLater')}
      </Button>
      <ContinueLaterModal isOpen={isOpen} onRequestClose={onRequestClose} />
    </>
  ) : null
}
