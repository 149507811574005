import { Typography } from '@components/Typography'
import { StoreNameProps, stylesBasedOnThemes } from '@root/theme'
import { lighten } from 'polished'
import styled, { css } from 'styled-components'

import { SelectProps } from './Select'

const LCWrapper = css`
  margin-bottom: 30px;
`

const OPSMWrapper = css``

const StyledWrapper = styled.div<StoreNameProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.media.large} {
    &.lg-w-45 .react-select__control,
    &.lg-w-45 .react-select__menu {
      width: 45%;
    }
  }

  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCWrapper,
        PV: LCWrapper,
        TO: LCWrapper,
        FE: LCWrapper,
        VT: LCWrapper,
        OPSM: OPSMWrapper,
      },
    })};
`

const LCSelect = css<Omit<SelectProps, 'label'>>`
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ error, theme: { colors } }) =>
    error ? colors.error : colors.darkShade};
  background-color: #ffffff;
  height: 32px;
  margin-top: 4px;
  vertical-align: top;
  width: 100%;
  max-width: ${({ $fullWidth }) => ($fullWidth ? '250px' : '150px')};
  padding: 1px 2px;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
`

const OPSMSelect = css`
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 8px;
  max-width: 320px;
  height: 48px;

  padding: 12px 16px;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC4yMTk2NyAwLjIxOTY3QzAuNTEyNTYzIC0wLjA3MzIyMzMgMC45ODc0MzcgLTAuMDczMjIzMyAxLjI4MDMzIDAuMjE5NjdMNi40MDY4NSA1LjM0NjE5TDExLjUzMzQgMC4yMTk2N0MxMS44MjYzIC0wLjA3MzIyMzMgMTIuMzAxMSAtMC4wNzMyMjMzIDEyLjU5NCAwLjIxOTY3QzEyLjg4NjkgMC41MTI1NjMgMTIuODg2OSAwLjk4NzQzNyAxMi41OTQgMS4yODAzM0w2LjQwNjg1IDcuNDY3NTFMMC4yMTk2NyAxLjI4MDMzQy0wLjA3MzIyMzMgMC45ODc0MzcgLTAuMDczMjIzMyAwLjUxMjU2MyAwLjIxOTY3IDAuMjE5NjdaIiBmaWxsPSIjMkQyQTI2Ii8+Cjwvc3ZnPgo=)
    no-repeat 95% 50%;
`

const StyledSelect = styled.select<Omit<SelectProps, 'label'> & StoreNameProps>`
  font: ${({ theme: { fonts } }) => fonts.paragraph};

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCSelect,
        PV: LCSelect,
        TO: LCSelect,
        FE: LCSelect,
        VT: LCSelect,
        OPSM: OPSMSelect,
      },
    })};
`

const LCSpan = css`
  color: ${({ theme: { colors } }) => lighten(0.125, colors.darkShade)};
`

const OPSMSpan = css`
  position: absolute;
  top: -10px;
  left: 8px;
  background: white;
  padding: 0 4px;

  &::after {
    color: ${({ theme: { colors } }) => colors.offer};
  }
`

const StyledSpan = styled(Typography) <
  Pick<SelectProps, 'required'> & StoreNameProps
>`
  z-index: 1;
  &::first-letter {
    text-transform: capitalize;
  }

  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCSpan,
        PV: LCSpan,
        TO: LCSpan,
        FE: LCSpan,
        VT: LCSpan,
        OPSM: OPSMSpan,
      },
    })};

  &::after {
    content: ${({ required }) => (required ? "'*'" : '')};
  }
`

export { StyledWrapper, StyledSelect, StyledSpan }
