import { StoreNameProps, stylesBasedOnThemes } from '@root/theme'
import styled, { css } from 'styled-components'

const LCSection = css`
  border-radius: 10px;
`

const OPSMSection = css`
  border: 1px solid ${({ theme }) => theme.colors.darkShade};
`

export const StyledSection = styled.section<StoreNameProps>`
  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCSection,
        PV: LCSection,
        TO: LCSection,
        FE: LCSection,
        VT: LCSection,
        OPSM: OPSMSection,
      },
    })};

  background-color: white;
  padding: 35px;
  position: relative;
  width: 100%;
  overflow: hidden auto;

  & h1 {
    margin-top: 0;
  }

  &:not(:first-of-type) {
    margin-top: 55px;
  }
`

export const TypographyWrapper = styled.div`
  display: flex;
  align-items: center;

  & p {
    margin-bottom: 0;

    &:first-of-type {
      margin-right: .3rem;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
    }

    &:nth-of-type(2) {
      display: flex;
      justify-content: flex-start;
      word-break: break-all;
    }
  }
`

export const StyledTest = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  & > * {
    width: 100%;

    @media screen and (min-width: 425px) {
      width: calc((100% - 2rem) / 2);
    }
  }
`
