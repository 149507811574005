import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { OtpWithMode, OtpSystem, CensoredContacts } from '@root/api/models/Otp'
import { OtpService } from '@root/api/services/OtpService'
import { useConfig } from '@root/Context'

import ModalStyle from './OTPModalStyling'
import { Typography } from '@components/Typography'
import { Loader } from '@components/Loader'

import emailIcon from '../../assets/icons/email.svg'
import arrowIcon from '../../assets/icons/arrow.svg'
import smsIcon from '../../assets/icons/sms.svg'
import { useState } from 'react'

type Props = {
  navigateToStepTwo: (otpResponse: OtpWithMode) => void
  contacts: CensoredContacts | undefined
  isLoading: boolean
}

const StyledTargetsWrapper = styled.div`
  & > :first-child {
    margin-bottom: 15px;
  }
`

const StyledTargetContainer = styled.button`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  margin: 0;
  width: 100%;
  border: none;
  padding: 1rem;
  background-color: #f6f6f6;

  cursor: pointer;

  & > :nth-child(1),
  & > :nth-child(3) {
    flex: 0;
  }
  & > :nth-child(2) {
    flex: 1;
  }
`

const StyledArrowIcon = styled.img`
  transform: rotate(90deg);
`

const StyledTypographyContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    ${theme.breakpoints.media.medium} {
      flex-direction: row;
    }
  `}
`

const SendToSMSOrEmail = ({
  type,
  navigateToStepTwo,
  contact,
  handleLoading,
}: Pick<Props, 'navigateToStepTwo'> & {
  type: OtpSystem
  contact: string | undefined
  handleLoading: (status: boolean) => void
}) => {
  const { t } = useTranslation()

  const {
    brand,
    appointmentId,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
    country,
  } = useConfig()

  const handleClick = async () => {
    handleLoading(true)
    const result = await OtpService.generateOtp({
      appointmentId,
      brand,
      caller,
      ocpApimSubscriptionKey,
      // @ts-ignore
      acceptedLanguage: `en-${country || 'AU'}`,
      system: type,
    })
    handleLoading(false)

    navigateToStepTwo({ ...result, type })
  }

  return (
    <StyledTargetContainer onClick={handleClick}>
      {type === 'sms' ? <img src={smsIcon} alt="Sms icon" /> : null}
      {type === 'mail' ? <img src={emailIcon} alt="Email icon" /> : null}
      
      <StyledTypographyContainer>
        {type === 'sms' ? (
          <Typography $noMargin $align="left">{t(`anz.otp.stepOne.sms`, { contact })}</Typography>
        ) : (
          <>
            <Typography $noMargin $align="left"> {t(`anz.otp.stepOne.mail`)} </Typography>
            <Typography $noMargin $align="left" $wordBreak="break-all">{contact}</Typography>
          </>
        )}
      </StyledTypographyContainer>
      <StyledArrowIcon src={arrowIcon} alt="Arrow icon" />
    </StyledTargetContainer>
  )
}

const StepOne: React.FC<Props> = ({
  navigateToStepTwo,
  isLoading,
  contacts,
}) => {
  const { t } = useTranslation()

  const [isLoadingResponse, setIsLoadingResponse] = useState<boolean>(false)

  return (
    <ModalStyle.StyledContent>
      <ModalStyle.StyledTypographyWithMarginBottom
        $variant="h1"
        $weight={700}
        $align="left"
      >
        {t('anz.otp.stepOne.heading')}
      </ModalStyle.StyledTypographyWithMarginBottom>
      <ModalStyle.StyledTypographyWithMarginBottom $align="left">
        {t('anz.otp.stepOne.description')}
      </ModalStyle.StyledTypographyWithMarginBottom>
      {!isLoading ? (
        isLoadingResponse ? (
          <Loader height={200} />
        ) : (
          <StyledTargetsWrapper>
            <SendToSMSOrEmail
              handleLoading={setIsLoadingResponse}
              type="sms"
              navigateToStepTwo={navigateToStepTwo}
              contact={contacts?.phone}
            />
            <SendToSMSOrEmail
              handleLoading={setIsLoadingResponse}
              type="mail"
              navigateToStepTwo={navigateToStepTwo}
              contact={contacts?.email}
            />
          </StyledTargetsWrapper>
        )
      ) : null}
    </ModalStyle.StyledContent>
  )
}

export default StepOne
