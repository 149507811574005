import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Layout } from '@components/Layout'
import { StepLayout } from '@components/StepLayout'
import { PersonalInfoRecap } from '@root/steps/ReviewSubmission/PersonalInfoRecap'
import { OtherPagesRecap } from './OtherPagesRecap'

const StyledStepLayout = styled(StepLayout)`
  background-color: transparent;
  padding: 0;
  border-radius: 0;
`

export const ReviewPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Layout.Content>
      <StyledStepLayout hideRequiredHint>
        <StepLayout.Title>{t('reviewSubmission.title')}</StepLayout.Title>
        <StepLayout.Description>
          {t('reviewSubmission.description')}
        </StepLayout.Description>

        <PersonalInfoRecap />

        <OtherPagesRecap />
      </StyledStepLayout>
    </Layout.Content>
  )
}
