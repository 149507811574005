import React, { Children } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import { Option } from './Option'
import { lighten } from 'polished'
import { Typography } from '../Typography'
export interface TypeAheadProps extends React.ComponentPropsWithRef<'input'> {
  name: string
  label: string
  error?: boolean
  errorMessage?: string
  helperText?: React.ReactNode
}

// ? Contrary to SFC/FC interface, it seems one can't define static property so easily when using React.forwardRef
// ? https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-488848720
interface CompoundedComponent
  extends React.ForwardRefExoticComponent<
    TypeAheadProps & React.RefAttributes<HTMLInputElement>
  > {
  Option: typeof Option
}

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

const StyledInput = styled.input<Omit<TypeAheadProps, 'label'>>`
  border-radius: 2px;
  border-style: solid;
  border-width: 0.5px;
  border-color: ${({ error, theme: { colors } }) =>
    error ? colors.error : colors.darkShade};
  background-color: #ffffff;
  width: 175px;
  height: 32px;
  margin-top: 4px;
  vertical-align: top;
  cursor: pointer;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`

const StyledLabel = styled.label`
  position: relative;
`

const StyledSpan = styled(Typography)<Pick<TypeAheadProps, 'required'>>`
  &::first-letter {
    text-transform: capitalize;
  }

  &::after {
    content: ${({ required }) => (required ? "'*'" : '')};
    color: ${({ theme: { colors } }) => lighten(0.125, colors.primary)};
  }
`

const StyledError = styled(Typography)`
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: left;
`

export const TypeAhead = React.forwardRef<
  HTMLInputElement,
  TypeAheadProps & {
    Option: typeof Option
  }
>(
  (
    {
      children,
      className,
      label,
      error,
      errorMessage,
      name,
      helperText,
      ...props
    },
    ref
  ) => {
    const datalistId = `${name}-${Children.count(children)}`

    return (
      <StyledWrapper
        className={clsx(
          'intake__typeahead',
          {
            'intake__typeahead--error': error,
          },
          className
        )}
      >
        {label && (
          <StyledLabel htmlFor={name}>
            <StyledSpan $variant="span" required={props.required}>
              {label.trim()}
            </StyledSpan>
          </StyledLabel>
        )}
        <StyledInput
          list={datalistId}
          error={error}
          name={name}
          {...props}
          ref={ref}
          aria-invalid={error ? 'true' : 'false'}
          role="combobox"
        />
        <datalist id={datalistId}>{children}</datalist>
        {helperText}
        {error && (
          <StyledError $variant="span" $color="error" role="alert">
            {errorMessage}
          </StyledError>
        )}
      </StyledWrapper>
    )
  }
) as CompoundedComponent

TypeAhead.Option = Option
