/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Document } from '../models/Document'
import type { DocumentVisibilities } from '../models/DocumentVisibilities'
import { request as __request } from '../core/request'

export class DocumentService {
  /**
   * Get a document to sign.
   * Get a document to sign for a Privacy or Health information, based on brand/store number.
   * @returns Document OK
   * @throws ApiError
   */
  public static async getDocuments({
    brand,
    docType,
    language,
    formId,
    caller,
    ocpApimSubscriptionKey,
    clinicName,
    acceptedLanguage,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** The document type (HEALTH or PRIVACY) **/
    docType: string
    /** The language code of requested document (ISO language code uppercase, ex. EN, ES...). **/
    language: string
    /** Form ID related to the document to retrieve. **/
    formId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** Clinic name related to the document to retrieve.' **/
    clinicName?: string
    /** Language in use */
    acceptedLanguage?: 'en-NZ' | 'en-AU'
  }): Promise<Document> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/document/${docType}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Accept-Language': acceptedLanguage,
      },
      query: {
        language: language,
        formId: formId,
        clinicName: clinicName,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Get a document by clinic.
   * Get a document by clinicId.
   * @returns Document OK
   * @throws ApiError
   */
  public static async getDocumentsForClinic({
    brand,
    docType,
    clinicId,
    clinicType,
    language,
    caller,
    clinicName,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** The document type (HEALTH or PRIVACY) **/
    docType: string
    /** Clinic ID related to the document to retrieve. **/
    clinicId: string
    /** The clinic type **/
    clinicType: string
    /** The language code of requested document (ISO language code uppercase, ex. EN, ES...). **/
    language: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Clinic name related to the document to retrieve.' **/
    clinicName?: string
  }): Promise<Document> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/document/${docType}/${clinicId}/${clinicType}`,
      headers: {
        caller: caller,
      },
      query: {
        language: language,
        clinicName: clinicName,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Get documents type availables
   * Get a list of documents type available with visibilities for each clinic type
   * @returns DocumentVisibilities OK
   * @throws ApiError
   */
  public static async getDocumentVisibility({
    brand,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<DocumentVisibilities> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/documents`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }
}
