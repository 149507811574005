import { FormValidation } from '@rjsf/core'
import { TFunction } from 'i18next'
import { JSONSchema7, JSONSchema7Definition } from 'json-schema'
import { isString } from './../../../utils/stringUtils'

import { validateFormat } from '@components/RJSF/customFormats'
import { jsonSchemaCustom } from '@root/anz/types/anzTypes'
import { QuestionAnswer } from '@root/api/models/Form'

export type ValidateFormatReturnType = ReturnType<typeof validateFormat>
export type CustomValidateFunctionType = {
  formData: QuestionAnswer
  errors: FormValidation
  t: TFunction
  customFormats: ValidateFormatReturnType
}

type CheckMutuallyExclusiveProperties = (
  errors: FormValidation,
  formData: QuestionAnswer,
  properties: {
    [key: string]: JSONSchema7Definition
  },
  propertiesToExclude: string[],
  t: TFunction
) => FormValidation

const checkMutuallyExclusiveProperties: CheckMutuallyExclusiveProperties = (
  errors,
  formData,
  properties,
  propertiesToExclude,
  t
) => {
  if (propertiesToExclude.every((p) => p in properties)) {
    if (propertiesToExclude.every((p) => !formData[p])) {
      propertiesToExclude.forEach((p) =>
        errors[p].addError(t('anz.formErrorLabels.mutuallyExclusiveFields'))
      )
    }
  }

  return errors
}

const clearFieldIfNotUsed = (
  currentSchema: Partial<jsonSchemaCustom>,
  formData: QuestionAnswer
): QuestionAnswer => {
  const dependencies = currentSchema.dependencies

  const localFormData = { ...formData }

  if (dependencies) {
    const dependenciesKeys = Object.keys(dependencies)

    // If there are conditional fields, I itereate them...
    dependenciesKeys.forEach((key) => {
      // ...and I read for the conditional properties.
      const dependentProperties = (
        (dependencies[key] as JSONSchema7).oneOf![0] as JSONSchema7
      ).properties

      // I check the value that trigger the condition.
      // EG: "Other" in some fields, trigger a text area where you specify other issues.
      const valueToCheck =
        (dependentProperties![key] as JSONSchema7).contains?.const ||
        (dependentProperties![key] as JSONSchema7).enum![0]

      // I check the fields that are activated when the condition is satisfied.
      const dependentKeys = Object.keys(dependentProperties!)
      const dependentKeyToNull = dependentKeys.filter((v) => v !== key)

      // In the end, I iterate in the conditionally-activated fields and,
      // if the original field does NOT contains the trigger value,
      // I remove the field from the submit object.
      dependentKeyToNull.forEach((keyToNull) => {
        if (
          !!formData[key] &&
          !(formData[key] as string[]).includes(valueToCheck as string)
        ) {
          localFormData[keyToNull!] = isString(localFormData[keyToNull!])
            ? ''
            : undefined
        }
      })
    })
  }

  return localFormData
}

const parsedError = (
  name: string,
  defaultMessage: string,
  t: TFunction
): string => {
  const errorKey = `anz.formErrorLabels.${name}`
  const errorLabel = t(errorKey)

  return errorKey === errorLabel ? defaultMessage : errorLabel
}

const medicareDvaNumberValidationFn = ({
  formData,
  errors,
  t,
  customFormats,
}: CustomValidateFunctionType) => {
  const medicareDvaNumber = formData.medicareDvaNumber?.toString()

  if (
    medicareDvaNumber &&
    !customFormats.medicareDvaNumber.test(medicareDvaNumber)
  ) {
    errors['medicareDvaNumber'].addError(t('anz.formErrorLabels.format'))
  }
}

const medicareCardNumberValidationFn = ({
  formData,
  errors,
  t,
}: CustomValidateFunctionType) => {
  const setMedicareCardNumberError = () =>
    errors['medicareCardNumber'].addError(t('anz.formErrorLabels.format'))

  const medicareCardNumber = formData.medicareCardNumber
    ?.toString()
    .replace(/\s/g, '')
  if (!medicareCardNumber) {
    return
  }

  try {
    const isOnlyNumbers = new RegExp(/^\d+$/).test(medicareCardNumber)
    const firstDigit = parseInt(medicareCardNumber[0])

    if (
      medicareCardNumber.length !== 10 ||
      !isOnlyNumbers ||
      firstDigit < 2 ||
      firstDigit > 6
    ) {
      setMedicareCardNumberError()
      return
    }

    const dChkSum =
      parseInt(medicareCardNumber[0]) +
      parseInt(medicareCardNumber[1]) * 3 +
      parseInt(medicareCardNumber[2]) * 7 +
      parseInt(medicareCardNumber[3]) * 9 +
      parseInt(medicareCardNumber[4]) +
      parseInt(medicareCardNumber[5]) * 3 +
      parseInt(medicareCardNumber[6]) * 7 +
      parseInt(medicareCardNumber[7]) * 9

    if (isNaN(dChkSum) || dChkSum % 10 !== parseInt(medicareCardNumber[8])) {
      setMedicareCardNumberError()
    }
  } catch (error) {
    setMedicareCardNumberError()
  }
}

export {
  parsedError,
  checkMutuallyExclusiveProperties,
  clearFieldIfNotUsed,
  medicareDvaNumberValidationFn,
  medicareCardNumberValidationFn,
}
