import React, { useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import { useHistory } from 'react-router'
import { useLocalStorage } from 'react-use'
import { useTranslation } from 'react-i18next'

import { Typography } from '@components/Typography'

import OTPInput from './OTPInput'
import ModalStyle from './OTPModalStyling'
import { OtpWithMode } from '@root/api/models/Otp'
import { OtpService } from '@root/api/services/OtpService'
import { useConfig } from '@root/Context'
import { anzIntro } from '@root/utils/routes'
import { AUTH_LOCAL_STORAGE_KEY } from '@root/api/core/request'

type Props = {
  otpOptions: OtpWithMode | undefined
  navigateToStepOne: () => void
}

const MAX_OTP_TESTS = 3

const StepTwo: React.FC<Props> = ({ otpOptions, navigateToStepOne }) => {
  const { t } = useTranslation()

  const {
    brand,
    appointmentId,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
  } = useConfig()

  const [, setJWT] = useLocalStorage(AUTH_LOCAL_STORAGE_KEY, '', { raw: true })
  const history = useHistory()

  const [isTimerExpired, setIsTimerExpired] = useState<boolean>(false)
  const { minutes, seconds, restart } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
    onExpire: () => setIsTimerExpired(true),
  })

  const [isOTPExpired, setIsOTPExpired] = useState<boolean>(false)
  const [isOTPError, setIsOTPError] = useState<number>(0)

  const [otpTests, setOtpTests] = useState<number>(0)

  const handleOTPInsertion = async (otp: string) => {
    const result = await OtpService.checkOtp({
      brand,
      appointmentId,
      ocpApimSubscriptionKey,
      caller,
      otp,
    })

    if (result.success) {
      const jwt = result.jwt

      setIsOTPError(-1)

      setJWT(jwt)
      history.push(anzIntro())
    } else {
      if (result.errorCode === 'otp.expired') {
        setIsOTPExpired(true)
        setIsOTPError(0)
        handleOTPTests(1)
      } else {
        setIsOTPError(1)
        setIsOTPExpired(false)
        handleOTPTests(otpTests + 1)
      }
    }
  }

  const handleOTPInvalidation = async () => {
    const result = await OtpService.invalidateOtp({
      brand,
      appointmentId,
      ocpApimSubscriptionKey,
      caller,
    })

    if (result === 'OTP invalidated') {
      navigateToStepOne()
    }
  }

  const handleOTPTests = (n: number) => setOtpTests(n)

  const handleTimer = (timeBeforeRecreate: number) => {
    const time = new Date()
    time.setSeconds(time.getSeconds() + timeBeforeRecreate)
    restart(time)
  }

  useEffect(() => {
    handleTimer(otpOptions?.timeBeforeRecreate || 60)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpOptions])

  useEffect(() => {
    if (otpTests === MAX_OTP_TESTS) {
      handleOTPInvalidation()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpTests])

  return (
    <ModalStyle.StyledContent>
      <ModalStyle.StyledTypographyWithMarginBottom
        $variant="h1"
        $weight={700}
        $align="left"
      >
        {t('anz.otp.stepTwo.heading')}
      </ModalStyle.StyledTypographyWithMarginBottom>
      <ModalStyle.StyledTypographyWithMarginBottom $align="left">
        {t('anz.otp.stepTwo.description', {
          contact:
            otpOptions?.type === 'mail' ? otpOptions?.email : otpOptions?.phone,
        })}

        {isOTPError === 1 ? (
          <Typography $color="error" $align="left">
            {t('anz.otp.stepTwo.error')}
          </Typography>
        ) : null}
        {isOTPExpired ? (
          <Typography $color="error" $align="left">
            {t('anz.otp.stepTwo.expired')}
          </Typography>
        ) : null}
      </ModalStyle.StyledTypographyWithMarginBottom>
      <OTPInput
        onOTPEnd={handleOTPInsertion}
        isError={isOTPError === 1 || isOTPExpired}
        isSuccess={isOTPError === -1}
      />
      <ModalStyle.StyledFooter>
        <Typography $noMargin $align="left">
          {t('anz.otp.stepTwo.didntReceive')}
        </Typography>
        {isTimerExpired ? (
          <ModalStyle.StyledUnderlinedButton onClick={navigateToStepOne}>
            {t('anz.otp.stepTwo.resendCta')}
          </ModalStyle.StyledUnderlinedButton>
        ) : (
          <Typography $noMargin $align="left">
            {t('anz.otp.stepTwo.resendCountdown', { minutes, seconds })}
          </Typography>
        )}

        <Typography $align="left" $margin="20px 0 0 0">
          {t('anz.otp.stepTwo.storeCTA', {
            name: otpOptions?.storeName,
            phone: otpOptions?.storePhone,
          })}
        </Typography>
      </ModalStyle.StyledFooter>
    </ModalStyle.StyledContent>
  )
}

export default StepTwo
