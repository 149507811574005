import useIsAnz from './useIsAnz'
import LogoAnzSrc from '../anz/assets/Logo.svg'

const useLogoProps = () => {
  const isAnz = useIsAnz()

  return isAnz
    ? {
        src: LogoAnzSrc,
      }
    : {}
}

export default useLogoProps
