import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { Icon } from '@components/Icon'
import CheckIcon from '@assets/icons/check.svg'
import CrossIcon from '@assets/icons/cross.svg'
import { useFormStatus, useStep } from '@utils/routes'
import { Button } from '@components/Button'
import { Typography } from '@components/Typography'
import { Loader } from '@components/Loader'

import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import { useTabApiAppointment } from '@services/TabApiProvider/ProviderAppointment'
import { Form } from '@api'

export interface InsuranceInfoRecapProps {}

const StyledIcon = styled(Icon)`
  width: 25px;
  height: 25px;
`

const StyledSection = styled.section`
  border-radius: 10px;
  background-color: white;
  padding: 35px;
  position: relative;
  width: 100%;
  overflow: hidden auto;

  & h1 {
    margin-top: 0;
  }

  &:nth-of-type(2) {
    margin-top: 55px;
  }
`

const TypographyWrapper = styled.div`
  display: flex;
  align-items: center;

  & p {
    &:nth-of-type(2) {
      margin-left: 35px;
    }
  }
`

const TypographyWithIcon = styled.div`
  display: flex;
  align-items: center;

  & p {
    margin-left: 15px;
  }
`

const PrimaryInsuranceHolderInfoWrapper = styled.div`
  display: flex;

  & > * + * {
    margin-left: 60px;
  }
`

const InsuranceProviderWrapper = styled.div`
  display: flex;

  & > * + * {
    margin-left: 60px;
  }
`

const EditButton = (props: React.ComponentPropsWithRef<typeof Button>) => (
  <Button $outlined {...props} />
)

const StyledEditButton = styled(EditButton)`
  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      position: absolute;
      top: 29px;
      right: 41px;
    }
  `}
`

export const InsuranceInfoRecap: React.FC<InsuranceInfoRecapProps> = () => {
  const { push } = useStep()
  const { t } = useTranslation()
  const { isFormExpired } = useFormStatus()
  const { data, isLoading: isLoadingForm } = useTabApiForm<Form>()
  const { data: appointmentData, isLoading: isLoadingAppointment } =
    useTabApiAppointment()

  if (isLoadingForm || isLoadingAppointment) return <Loader height={100} />

  if (data) {
    if (appointmentData) {
      const {
        clinic: { country },
      } = appointmentData

      return (
        <StyledSection id="insurance-info-recap">
          <Typography $variant="h1" $align="left">
            {t('reviewSubmission.insuranceInfo.title')}
          </Typography>
          <TypographyWithIcon>
            <StyledIcon
              src={data.hasInsurancePlan ? CheckIcon : CrossIcon}
              className={clsx({
                'intake__icon--check': data.hasInsurancePlan,
                'intake__icon--cross': !data.hasInsurancePlan,
              })}
            />

            <Typography $weight="bold">
              {t('reviewSubmission.insuranceInfo.hasInsurancePlan')}
            </Typography>
          </TypographyWithIcon>

          {data.hasInsurancePlan && (
            <>
              <InsuranceProviderWrapper>
                <TypographyWrapper>
                  <Typography $weight="bold">
                    {t('reviewSubmission.insuranceInfo.provider')}
                  </Typography>
                  :<Typography>{data.insuranceProvider}</Typography>
                </TypographyWrapper>

                <TypographyWrapper>
                  <Typography $weight="bold">
                    {t('reviewSubmission.insuranceInfo.memberId')}
                  </Typography>
                  :<Typography>{data.primaryMemberID}</Typography>
                </TypographyWrapper>

                <TypographyWrapper>
                  <Typography $weight="bold">
                    {t('reviewSubmission.insuranceInfo.lastFourSSN')}
                  </Typography>
                  :<Typography>{data.primaryMemberSSN?.slice(-4)}</Typography>
                </TypographyWrapper>
              </InsuranceProviderWrapper>

              {data.isPrimaryHolder === false && (
                <section>
                  <Typography $weight="bold" $align="left">
                    {t(
                      'reviewSubmission.insuranceInfo.primaryInsuranceHolderInfo'
                    )}
                  </Typography>

                  <PrimaryInsuranceHolderInfoWrapper>
                    <div>
                      <TypographyWrapper>
                        <Typography $weight="bold">
                          {t('core.firstName')}
                        </Typography>
                        :<Typography>{data.primaryHolderFirstName}</Typography>
                      </TypographyWrapper>

                      <TypographyWrapper>
                        <Typography $weight="bold">
                          {t('core.lastName')}
                        </Typography>
                        :<Typography>{data.primaryHolderLastName}</Typography>
                      </TypographyWrapper>
                    </div>

                    <div>
                      <TypographyWrapper>
                        <Typography $weight="bold">{t('core.dob')}</Typography>:
                        <Typography>{data.primaryHolderDOB}</Typography>
                      </TypographyWrapper>

                      <TypographyWrapper>
                        <Typography $weight="bold">
                          {t('reviewSubmission.insuranceInfo.lastFourSSN')}
                        </Typography>
                        :
                        <Typography>
                          {data.primaryHolderSSN?.slice(-4)}
                        </Typography>
                      </TypographyWrapper>
                    </div>
                  </PrimaryInsuranceHolderInfoWrapper>
                </section>
              )}
            </>
          )}

          <TypographyWithIcon>
            <StyledIcon
              className={clsx({
                'intake__icon--check': data.hasMedicalInsurancePlan,
                'intake__icon--cross': !data.hasMedicalInsurancePlan,
              })}
              src={data.hasMedicalInsurancePlan ? CheckIcon : CrossIcon}
            />

            <Typography $weight="bold">
              {t('reviewSubmission.insuranceInfo.hasMedicalInsurancePlan')}
            </Typography>
          </TypographyWithIcon>

          <section>
            <TypographyWrapper>
              <Typography $weight="bold">
                {t('reviewSubmission.insuranceInfo.isAAAMember')}
              </Typography>

              <Typography $weight="bold" $transform="uppercase">
                {data.isAAAMember ? t('core.yes') : t('core.no')}
              </Typography>
            </TypographyWrapper>

            <TypographyWrapper>
              <Typography $weight="bold">
                {t('reviewSubmission.insuranceInfo.isAARPMember')}
              </Typography>

              <Typography $weight="bold" $transform="uppercase">
                {data.isAARPMember ? t('core.yes') : t('core.no')}
              </Typography>
            </TypographyWrapper>

            {country === 'CA' && (
              <TypographyWrapper>
                <Typography $weight="bold">
                  {t('reviewSubmission.insuranceInfo.isHMOMember')}
                </Typography>

                <Typography $weight="bold" $transform="uppercase">
                  {data.isHMOMember ? t('core.yes') : t('core.no')}
                </Typography>
              </TypographyWrapper>
            )}
          </section>

          <StyledEditButton
            onClick={() => {
              push('2_INSURANCE_INFO')
            }}
            data-element-id="X_OEE_Review_Edit"
          >
            {t(isFormExpired ? 'core.view' : 'core.edit')}
          </StyledEditButton>
        </StyledSection>
      )
    }
  }

  return null
}
