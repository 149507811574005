import React from 'react'
import styled from 'styled-components'
import { Header } from './Header'
import { Content } from './Content'
import { Footer } from './Footer'
import { CompletionTracker } from './CompletionTracker'
import { normalize } from './normalize'

const Container = styled.div`
  background-color: #f2f2f2;
  min-height: 100%;
  ${normalize}
`

interface ContainerProps extends React.ComponentPropsWithRef<'div'> {}

export const Layout: React.FC<ContainerProps> & {
  Header: typeof Header
  CompletionTracker: typeof CompletionTracker
  Content: typeof Content
  Footer: typeof Footer
} = ({ children, ...props }) => <Container {...props}>{children}</Container>

Layout.Header = Header
Layout.CompletionTracker = CompletionTracker
Layout.Content = Content
Layout.Footer = Footer
