import React, { SyntheticEvent, useState } from 'react'
import styled, { css } from 'styled-components'
import clsx from 'clsx'
import { Typography } from '../Typography'
import { onDetectTouchDevice } from '../../utils/detectTouchDevice'

export interface TooltipProps {
  className?: string
  position?: 'top' | 'left' | 'right' | 'bottom'
  label?: React.ReactNode
}

const TooltipWrapper = styled.span`
  position: relative;
`

const TooltipHint = styled.span`
  color: ${({ theme: { colors } }) => colors.primary};
  width: 1.2em;
  height: 1.2em;
  margin: 0 0.2em;
  display: inline-flex;
  border: 2px solid ${({ theme: { colors } }) => colors.primary};
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  font: ${({ theme: { fonts } }) => fonts.paragraph};
  font-weight: bold;
  font-size: 0.9em;
  padding-bottom: 0.1em;
  box-sizing: border-box;
`

const TooltipContainer = styled.div<TooltipProps>`
  width: 280px;
  text-align: center;
  padding: 2em 1em 1.7em;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  position: absolute;
  z-index: 1;

  ${({ theme, position }) => css`
    left: 50%;
    top: 100%;
    transform: translate3d(-50%, 2em, 0);

    ${theme.breakpoints.media.medium} {
      ${() => {
        switch (position) {
          case 'left':
            return css`
              right: 100%;
              left: auto;
              top: 50%;
              transform: translate3d(-2em, -50%, 0);
            `
          case 'right':
            return css`
              left: 100%;
              top: 50%;
              transform: translate3d(2em, -50%, 0);
            `
          case 'bottom':
            return css`
              left: 50%;
              top: 100%;
              transform: translate3d(-50%, 2em, 0);
            `
          default:
            return css`
              left: 50%;
              top: 0;
              transform: translate3d(-50%, calc(-100% - 2em), 0);
            `
        }
      }}
    }
  `}

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 1em solid black;
    transform-origin: 50% 50%;

    border-color: transparent transparent white white;
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);

    ${({ theme, position }) => css`
      left: 50%;
      top: 1px;
      transform: translate3D(-50%, -50%, 0) rotate(135deg);

      ${theme.breakpoints.media.medium} {
        ${() => {
          switch (position) {
            case 'left':
              return css`
                left: auto;
                right: 1px;
                top: 50%;
                transform: translate3D(50%, -50%, 0) rotate(-135deg);
              `
            case 'right':
              return css`
                left: 1px;
                top: 50%;
                transform: translate3D(-50%, -50%, 0) rotate(45deg);
              `
            case 'bottom':
              return css`
                left: 50%;
                top: 1px;
                transform: translate3D(-50%, -50%, 0) rotate(135deg);
              `
            default:
              return css`
                left: 50%;
                top: auto;
                bottom: 1px;
                transform: translate3D(-50%, 50%, 0) rotate(-45deg);
              `
          }
        }}
      }
    `}
  }
`

const TooltipContent = styled(Typography)`
  display: inline-block;
  width: 100%;
`

export const Tooltip: React.FC<TooltipProps> = ({
  className,
  children,
  label = <TooltipHint>?</TooltipHint>,
  position = 'top',
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const onClick = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
    const isTouchDevice = onDetectTouchDevice()
    if (isTouchDevice) setIsVisible(!isVisible)
  }

  const onMouseEnter = () => {
    const isTouchDevice = onDetectTouchDevice()
    if (!isTouchDevice) setIsVisible(true)
  }

  const onMouseLeave = () => setIsVisible(false)

  return (
    <TooltipWrapper
      className={clsx(
        'intake__tooltip',
        `intake__tooltip--${position}`,
        className
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {label}
      {isVisible && (
        <TooltipContainer position={position}>
          <TooltipContent>{children}</TooltipContent>
        </TooltipContainer>
      )}
    </TooltipWrapper>
  )
}
