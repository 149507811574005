import React from 'react'
import styled from 'styled-components'
import { Typography, TypographyProps } from '../../Typography'

export interface TitleProps extends TypographyProps {}

const StyledTitle = styled(Typography)<TitleProps>`
  margin: 27px 0px;
  letter-spacing: 1.67px;
`

export const Title: React.FC<TitleProps> = ({ children, ...props }) => (
  <StyledTitle $variant="h1" $align="left" {...props}>
    {children}
  </StyledTitle>
)
