import { useMemo } from 'react'
import { WidgetProps } from '@rjsf/core'
import { StyledError, StyledLabel } from '@root/anz/components/AnzSelect'
import ReactSelect, { SingleValue } from 'react-select'
import clsx from 'clsx'
import { StyledWrapper, StyledSpan } from '@root/anz/components/AnzSelect/Select.style'
import { useConfig } from '@root/Context'

export interface Option {
  value: any
  label: string
  key?: string
  disabled?: boolean
}

const startYear = 1900
const currentYear = new Date().getFullYear()

const yearsArray = Array.from(
  { length: currentYear - startYear + 1 },
  (x, i) => startYear + i
)

const reversedYearsArray = Array.from(
  { length: currentYear - startYear + 1 },
  (x, i) => currentYear - i
)

const YearsWidget = ({
  required,
  label,
  value,
  onChange,
  disabled,
  id,
  rawErrors,
  options,
  style,
}: React.PropsWithChildren<WidgetProps>) => {
  const { brand } = useConfig()

  const hasErrors = !!rawErrors?.length
  const errorMessage = rawErrors?.join('\n')
  const reversedYears = options.reversedYears

  const yearOptions: Option[] = useMemo(
    () => (reversedYears ? reversedYearsArray : yearsArray).map(year => ({
      value: year.toString(),
      label: year.toString(),
      key: year.toString(),
    }))
    , [reversedYears])

  const selectedOptions = useMemo(() => yearOptions.find(year => year.value === value), [value, yearOptions])

  const handleChange = (option: SingleValue<Option>) => {
    onChange(option?.value.toString())
  }

  return (
    <StyledWrapper
      storeName={brand}
      className={clsx(
        'intake__select',
        {
          'intake__select--error': hasErrors,
        },
        options.classNames
      )}
      style={style}
    >
      {label && (
        <StyledLabel htmlFor={id}>
          <StyledSpan storeName={brand} $variant="span" required={required}>
            {label.trim()}
          </StyledSpan>
        </StyledLabel>
      )}
      <ReactSelect
        defaultValue={selectedOptions}
        onChange={handleChange}
        name={id}
        isDisabled={disabled}
        options={yearOptions}
        classNamePrefix="react-select"
        styles={{
          indicatorSeparator: () => ({ display: 'none' }),
          menu: (defaultStyles) => ({ ...defaultStyles, zIndex: 3 }),
          control: (defaultStyles) => ({
            ...defaultStyles,
            borderRadius: '8px',
            padding: '8px 6px',
          }),
        }}
      />
      {hasErrors && (
        <StyledError $variant="span" $color="error" role="alert">
          {errorMessage}
        </StyledError>
      )}
    </StyledWrapper>
  )
}

export default YearsWidget
