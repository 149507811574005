import { useConfig } from '@root/Context'
import React from 'react'

const mapWebsiteStoreLink = {
  AU: 'https://www.opsm.com.au/find-store',
  NZ: 'https://www.opsm.co.nz/find-store',
}

const useStoreLink = () => {
  const { brandWebsite, country } = useConfig()

  return brandWebsite 
    ? `${brandWebsite}find-store` 
    : mapWebsiteStoreLink[country ?? 'AU']
}

export default useStoreLink
