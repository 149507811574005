import { createContext, FC, useCallback, useState } from 'react'
import { useAsync } from 'react-async'
import { AnzForm, FormService, OptIn } from '../../api'
import { useConfig } from '../../Context'

// #region ::: PARTIALS
const neverEndingFormPromise = new Promise<AnzForm>(() => { })
// CONTEXT
export const TabApiAnzFormContext = createContext({} as ContextAnzFormValueType)
// TYPES
const useAsyncWithForm = () => useAsync<AnzForm>({})
export type ContextAnzFormValueType = ReturnType<typeof useAsyncWithForm>
// #endregion

export const ProviderAnzForm: FC = ({ children }) => {
  const {
    brand,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
    appointmentId,
  } = useConfig()
  const [formId, setFormId] = useState<string>('')

  const getForm = useCallback(
    () =>
      appointmentId
        ? FormService.getFormByAppointmentId({
          brand,
          appointmentId,
          caller,
          ocpApimSubscriptionKey,
        })
        : neverEndingFormPromise,
    [brand, caller, appointmentId, ocpApimSubscriptionKey]
  )

  const putForm = useCallback(
    ([requestBody, privacyPolicy = false]: [AnzForm | OptIn, boolean]) => {
      if (!appointmentId) return neverEndingFormPromise

      if (privacyPolicy) {
        return FormService.upsertPrivacyPolicyByAppId({
          brand,
          appointmentId,
          // caller,
          // ocpApimSubscriptionKey,
          requestBody: requestBody as OptIn,
        })
      }

      return FormService.upsertFormByAppointmentId<AnzForm>({
        brand,
        appointmentId,
        caller,
        ocpApimSubscriptionKey,
        requestBody: requestBody as AnzForm,
      })
    },
    [brand, caller, appointmentId, ocpApimSubscriptionKey, formId]
  )

  const tabApiFormContextValue = useAsync<AnzForm>({
    promiseFn: getForm,
    onResolve: ({ id }) => setFormId(id),
    deferFn: putForm as (...args: any[]) => Promise<AnzForm>,
  })

  return (
    <TabApiAnzFormContext.Provider value={tabApiFormContextValue}>
      {children}
    </TabApiAnzFormContext.Provider>
  )
}
