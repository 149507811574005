import { Typography } from '@components/Typography'
import styled from 'styled-components'

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 4.25rem;
`

const UnStyledButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;

  cursor: pointer;
`

const StyledContent = styled.div``

const StyledTypographyWithMarginBottom = styled(Typography)`
  margin-bottom: 2rem;
`

const StyledUnderlinedButton = styled(UnStyledButton)`
  text-decoration: underline;
`

const StyledFooter = styled.div`
  margin-top: 2rem;

  font-size: 1rem;
  font-weight: 400;
  line-height: 130%;
  color: #2d2a26;
`

const styling = {
  StyledHeader,
  StyledContent,
  StyledTypographyWithMarginBottom,
  StyledFooter,
  UnStyledButton,
  StyledUnderlinedButton,
}

export default styling
