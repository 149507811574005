import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppointmentRecap } from '../../components/AppointmentRecap'
import { Button } from '../../components/Button'
import { Layout } from '../../components/Layout'
import { Typography } from '../../components/Typography'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'

import styled from 'styled-components'

import DocumentSection from './DocumentSection'

import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import { useTabApiAppointment } from '@services/TabApiProvider/ProviderAppointment'
import { Loader } from '@components/Loader'
import usePageClassname from '@hooks/usePageClassname'
import { step, steps, useBypassIntro } from '@utils/routes'
import { DocumentType } from '@api'
import { useVisibleDocuments } from '@hooks/useVisibleDocuments'
import useIsAnz from '@hooks/useIsAnz'
import { useConfig } from '@root/Context'
import { ErrorModal } from '@components/ErrorModal'
import useStoreLink from '@hooks/useStoreLink'

const CompleteExamSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 40px;
`

const TwoColumns = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden auto;

  & > * + * {
    margin-bottom: 40px;
  }
`

const IntroSection = styled.div`
  @media screen and (max-width: 425px) {
    padding: 0 1rem;
    margin-bottom: 3.5rem;
  }
`

export const Intro: React.FC = () => {
  const isAnz = useIsAnz()
  const { brand } = useConfig()
  const { t } = useTranslation()
  const history = useHistory()
  const storeLink = useStoreLink()

  useBypassIntro()

  const {
    data,
    isLoading: isLoadingForm,
    error: errorTabForm,
  } = useTabApiForm()

  const {
    data: appointmentData,
    isLoading: isLoadingAppointment,
    error: errorTabAppointment,
  } = useTabApiAppointment()

  const [isLegalSignModalVisible, toggleLegalSignModalVisibility] = useToggle(false)
  const visibleDocuments = useVisibleDocuments()

  const isPrivacyDocumentVisible =
    visibleDocuments?.[DocumentType.PRIVACY]?.availableForClinicTypes?.includes(data?.clinicType || '') || false

  const isHealthDocumentVisible = !isAnz
    ? (visibleDocuments?.[DocumentType.HEALTH]?.availableForClinicTypes?.includes(data?.clinicType || '') || false)
    : false

  const isDisabled =
    isLoadingForm ||
    !!errorTabForm ||
    isLoadingAppointment ||
    !!errorTabAppointment

  const isPrivacyPracticeSigned = data?.optPrivacyPractice?.signature
  const isHealthInformationSigned = data?.optHealthInformation?.signature

  const completeExamHandler = () => {
    if (
      (isPrivacyDocumentVisible && !isPrivacyPracticeSigned) ||
      (isHealthDocumentVisible && !isHealthInformationSigned)
    ) {
      toggleLegalSignModalVisibility(true)
    } else {
      history.push(step(steps[0]))
    }
  }

  usePageClassname('intro-page')

  return (
    <Layout>
      <Layout.Header />
      <Layout.Content>
        <TwoColumns>
          {!appointmentData && isLoadingAppointment && <Loader height={150} />}
          {appointmentData && (
            <IntroSection>
              <Typography $variant="h1">
                {t('intro.title', { name: appointmentData.patient.firstName })}
              </Typography>

              <Typography>{t(`intro.description.${brand}`)}</Typography>
            </IntroSection>
          )}

          <DocumentSection
            isLegalSignModalVisible={isLegalSignModalVisible}
            toggleLegalSignModalVisibility={toggleLegalSignModalVisibility}
            isPrivacyDocumentVisible={isPrivacyDocumentVisible}
            isHealthDocumentVisible={isHealthDocumentVisible}
          />

          <CompleteExamSection>
            {!isAnz && (<Button
              onClick={completeExamHandler}
              data-element-id="X_OEE_Intro_Legal-ConfirmMyForm"
              disabled={isDisabled}
            >
              {t('intro.completeExamForms')}
            </Button>)}
            <Typography>{t('intro.comeBackLaterToFinish')}</Typography>
          </CompleteExamSection>
        </TwoColumns>

        <AppointmentRecap />
      </Layout.Content>

      {data && 'statusAppointment' in data && data.statusAppointment === 'DELETED' &&
        (<ErrorModal 
          isOpen 
          errorMessage={t('core.appointmentNoLongerAvailable')}
          showBackToTheFormBtn={false}
          onRequestClose={() => window.location.assign(storeLink)}
        />)
      }
    </Layout>
  )
}
