import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteProps } from 'react-router'
import styled from 'styled-components'
import { AppointmentRecap } from '../../components/AppointmentRecap'
import { Button } from '../../components/Button'
import { Layout } from '../../components/Layout'
import { StepLayout } from '../../components/StepLayout'
import { useConfig } from '../../Context'
import { useFormComplete } from '../../hooks/useFormUpdate'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import { useFormStatus, useStep } from '../../utils/routes'
import { InsuranceInfoRecap } from './InsuranceInfoRecap'
import { OtherRecap } from './OtherRecap'
import { PersonalInfoRecap } from './PersonalInfoRecap'
import { Form } from '@api'

type ReviewSubmissionProps = RouteProps

const StyledStepLayout = styled(StepLayout)`
  background-color: transparent;
  padding: 0;
`

export const ReviewSubmission: React.FC<ReviewSubmissionProps> = () => {
  const { previous } = useStep()
  const { isFormExpired } = useFormStatus()
  const { t } = useTranslation()
  const { data, run, isLoading } = useTabApiForm<Form>()
  const handleFormComplete = useFormComplete(data, 'COMPLETED', run)
  const { onSaveLastStep } = useConfig()
  const { isBackOffice } = useConfig()
  const onClickContinue = () => {
    handleFormComplete()
    onSaveLastStep(data)
  }
  useEffect(() => {
    document.body.classList.add('review-submission-page')

    return () => {
      document.body.classList.remove('review-submission-page')
    }
  }, [])

  return (
    <Layout>
      <Layout.Header
        showContinueLaterButton
        continueLaterDataElementId="X_OEE_Review_Continue-Later"
        showCompletitionTracker
        completitionTracketValue={10}
      />

      <Layout.Content>
        <StyledStepLayout hideRequiredHint>
          <StepLayout.Title>{t('reviewSubmission.title')}</StepLayout.Title>
          <StepLayout.Description>
            {t('reviewSubmission.description')}
          </StepLayout.Description>

          <PersonalInfoRecap />

          <InsuranceInfoRecap />

          <OtherRecap />
        </StyledStepLayout>

        <AppointmentRecap />
      </Layout.Content>

      {!isFormExpired && !isBackOffice && (
        <Layout.Footer>
          <Button
            $outlined
            onClick={
              () =>
                previous({
                  from: null,
                }) /* override default behavior for getting back to the review page after modification */
            }
            data-element-id="X_OEE_ReviewSubmissions_Back"
            $compact
          >
            {t('core.back')}
          </Button>
          {!isFormExpired && (
            <Button
              onClick={onClickContinue}
              disabled={isLoading}
              data-element-id="X_OEE_Review_Continue"
              $compact
            >
              {t('core.continue')}
            </Button>
          )}
        </Layout.Footer>
      )}
    </Layout>
  )
}
