import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AppointmentRecap } from '../components/AppointmentRecap'
import { Button } from '../components/Button'
import { Layout } from '../components/Layout'
import { StepLayout } from '../components/StepLayout'
import { Typography } from '../components/Typography'
import { useConfig } from '../Context'
import {
  flatFormObjectIfAnz,
  useTabApiForm,
} from '@services/TabApiProvider/hooks/useTabApiForm'
import usePageClassname from '../hooks/usePageClassname'

const StyledCtaButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;

  & > * + * {
    width: 50%;

    & + * {
      margin-top: 30px;
    }
  }

  ${({ theme }) => theme.breakpoints.media.large} {
    flex-direction: row;

    & > * {
      width: auto;

      & + * {
        margin-top: 0;
        margin-left: 30px;
      }
    }
  }
`

const CtaSection = () => {
  const { t } = useTranslation()
  const { thankYouPageCta } = useConfig()

  usePageClassname('continue-later-page')

  const onClick = (url: string) => window.open(url, '_blank')

  if (thankYouPageCta) {
    return (
      <section>
        <Typography>{t('core.additionalCta')}</Typography>

        <StyledCtaButtonWrapper>
          {thankYouPageCta.map(({ description, link, analyticsTag }, index) => (
            <Button
              $outlined
              key={`thankYouPageCta-link-${index}`}
              data-element-id={analyticsTag}
              onClick={() => onClick(link)}
            >
              {description}
            </Button>
          ))}
        </StyledCtaButtonWrapper>
      </section>
    )
  }

  return null
}

export const ContinueLater = () => {
  const { t } = useTranslation()
  const { data: formData, isLoading } = useTabApiForm()
  const data = flatFormObjectIfAnz(formData)

  if (data && !isLoading) {
    const { email } = data

    return (
      <Layout>
        <Layout.Header />

        <Layout.Content>
          <StepLayout hideRequiredHint>
            <StepLayout.Title $align="center">
              {t('continueLater.title')}
            </StepLayout.Title>
            
            <CtaSection />
          </StepLayout>

          <AppointmentRecap expanded />
        </Layout.Content>
      </Layout>
    )
  }

  return null
}
