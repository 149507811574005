import React from 'react'
import clsx from 'clsx'
import styled, { css } from 'styled-components'

import { Typography } from '../Typography'
import { StyledSpan } from './TextArea.styling'
import { useConfig } from '@root/Context'

export interface TextAreaProps
  extends Omit<React.ComponentPropsWithRef<'textarea'>, 'type'> {
  label?: string
  error?: boolean
  errorMessage?: string
  resizable?: boolean
  helperText?: string
}

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

const StyleFooting = styled.span`
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  ${({ theme }) => css`
    ${theme.breakpoints.media.medium} {
      flex-wrap: no-wrap;
    }
  `}
`

const StyledTextArea = styled.textarea<TextAreaProps>`
  font: ${({ theme: { fonts } }) => fonts.paragraph};
  vertical-align: top;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ error, theme: { colors } }) =>
    error ? colors.error : colors.darkShade};
  width: 100%;
  resize: ${({ resizable }) => (resizable ? 'vertical' : 'none')};
  margin-top: 9px;
  overflow: auto;
`

const StyledLabel = styled.label``

const StyledError = styled(Typography)`
  display: inline-block;
  flex-grow: 1;
  height: 0;
  width: 0;
  min-width: 70%;
  margin-top: 9px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
`

const StyledhelperText = styled(Typography)`
  font-size: 13px;
  letter-spacing: 0.41px;
  text-align: right;
  padding-left: 1em;
  flex-shrink: 0;
  margin-left: auto;

  &::first-letter {
    text-transform: uppercase;
  }
`

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      label,
      error,
      errorMessage,
      resizable = false,
      rows = 4,
      helperText,
      name,
      required = false,
      ...props
    },
    ref
  ) => {
    const { brand } = useConfig()
    return (
      <StyledWrapper
        className={clsx(
          'intake__textarea',
          {
            'intake__textarea--error': error,
          },
          className
        )}
      >
        {label && (
          <StyledLabel htmlFor={name}>
            <StyledSpan
              storeName={brand}
              required={required}
              $variant="span"
            >
              {label.trim()}
            </StyledSpan>
          </StyledLabel>
        )}

        <StyledTextArea
          resizable={resizable}
          error={error}
          name={name}
          {...props}
          ref={ref}
          aria-invalid={error ? 'true' : 'false'}
          rows={rows}
        />
        <StyleFooting>
          {helperText && (
            <StyledhelperText $variant="span">{helperText}</StyledhelperText>
          )}
          {error && errorMessage && (
            <StyledError $variant="span" $color="error" role="alert">
              {errorMessage}
            </StyledError>
          )}
        </StyleFooting>
      </StyledWrapper>
    )
  }
)
