import { differenceInYears, isFuture } from 'date-fns'
import * as yup from 'yup'

//#region - Single Rules
export const lastEyeExam = yup
  .string()
  .matches(/\d{4}|d{0}/, 'visionHistory.lastEyeExamRegexMessage')
  .test({
    name: 'isMartyMcFly',
    message: 'visionHistory.lastEyeExamYearInFuture',
    test: (lastEyeExamDate) =>
      lastEyeExamDate && lastEyeExamDate !== ''
        ? !isFuture(new Date(lastEyeExamDate))
        : true,
  })
  .test({
    name: 'isCentenarian',
    message: 'visionHistory.lastEyeExamMoreThan100Years',
    test: (lastEyeExamDate) =>
      lastEyeExamDate && lastEyeExamDate !== ''
        ? differenceInYears(new Date(), new Date(lastEyeExamDate)) <= 100
        : true,
  })

export const yearEyeglassPrescription = yup
  .string()
  .matches(/\d{4}/, 'visionHistory.yearEyeglassPrescriptionRegexMessage')
  .test({
    name: 'isMartyMcFly',
    message: 'visionHistory.yearEyeglassPrescriptionYearInFuture',
    test: (lastEyeExamDate) =>
      lastEyeExamDate ? !isFuture(new Date(lastEyeExamDate)) : false,
  })
  .test({
    name: 'isCentenarian',
    message: 'visionHistory.yearEyeglassPrescriptionMoreThan100Years',
    test: (yearEyeglassPrescription) =>
      yearEyeglassPrescription
        ? differenceInYears(new Date(), new Date(yearEyeglassPrescription)) <=
          100
        : false,
  })
  .required('visionHistory.yearEyeglassPrescriptionRequiredMessage')

export const yearEyeglassPrescriptionNotRequired = yup.string()

export const contactLensesType = yup.string().notRequired()

export const isInterestedInContactLens = yup
  .string()
  .oneOf(['true', 'false'])
  .required('visionHistory.isInterestedInContactLensRequiredMessage')

export const isInterestedInContactLensNotRequired = yup
  .string()
  .oneOf(['true', 'false'])

export const eyewear = yup
  .array()
  .of(yup.string().required())
  .min(1, 'visionHistory.eyewearRequiredMessage')
  .required('visionHistory.eyewearRequiredMessage')

export const experiencingVision = yup
  .array()
  .of(yup.string().required())
  .min(1, 'visionHistory.experiencingVisionRequiredMessage')
  .required('visionHistory.experiencingVisionRequiredMessage')

export const shareExtraVisionInfo = yup.string().max(300).notRequired()
//#endregion

interface VisionHystoryRules {
  lastEyeExam: typeof lastEyeExam
  yearEyeglassPrescription:
    | typeof yearEyeglassPrescription
    | typeof yearEyeglassPrescriptionNotRequired
  contactLensesType: typeof contactLensesType
  isInterestedInContactLens:
    | typeof isInterestedInContactLens
    | typeof isInterestedInContactLensNotRequired
  eyewear: typeof eyewear
  experiencingVision: typeof experiencingVision
  shareExtraVisionInfo: typeof shareExtraVisionInfo
}

const visionHystoryDefaultRules: VisionHystoryRules = {
  lastEyeExam,
  yearEyeglassPrescription,
  contactLensesType,
  isInterestedInContactLens,
  eyewear,
  experiencingVision,
  shareExtraVisionInfo,
}

export const getVisionHystoryRules = ({
  hasEyewear,
  hasContactLenses,
}: {
  hasEyewear: boolean
  hasContactLenses: boolean
}) => {
  let rules = { ...visionHystoryDefaultRules }

  if (!hasEyewear) {
    rules = {
      ...rules,
      yearEyeglassPrescription: yearEyeglassPrescriptionNotRequired,
    }
  }

  if (hasContactLenses) {
    rules = {
      ...rules,
      isInterestedInContactLens: isInterestedInContactLensNotRequired,
    }
  }
  
  return yup.object(rules).defined()
}

export const visionHistorySchema = yup
  .object({
    lastEyeExam,
    yearEyeglassPrescription,
    contactLensesType,
    isInterestedInContactLens,
    eyewear,
    experiencingVision,
    shareExtraVisionInfo,
  })
  .defined()

export type VisionHistoryType = yup.InferType<typeof visionHistorySchema>
