import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useFormComplete } from '../../../../hooks/useFormUpdate'
import {
  flatFormObjectIfAnz,
  useTabApiForm,
} from '@services/TabApiProvider/hooks/useTabApiForm'
import { useStepId } from '../../../../utils/routes'
import { Button } from '../../../Button'
import { Modal, ModalProps } from '../../../Modal'
import { Typography } from '../../../Typography'

const StyledModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: center;

  & > * + * {
    margin-left: 30px;
  }
`

export interface ContinueLaterModalProps extends ModalProps { }

export const ContinueLaterModal: React.FC<ContinueLaterModalProps> = ({
  ...props
}) => {
  const id = useStepId()
  const { t } = useTranslation()
  const { data: formData, run, isLoading: isLoadingForm } = useTabApiForm()
  const data = flatFormObjectIfAnz(formData)

  const handleConfirmButton = useFormComplete(data, id, run, true)

  if (data) {
    const { email } = data

    return (
      <Modal
        {...props}
        id="continue-later-modal"
        data-element-id="X_OEE_Alert_OverlayExit"
      >
        <Modal.Title>{t('continueLaterModal.title')}</Modal.Title>

        <Modal.Content>
          <Typography>
            {t('continueLaterModal.description')}
          </Typography>
        </Modal.Content>

        <StyledModalActions>
          <Button $outlined onClick={props.onRequestClose}>
            {t('core.close')}
          </Button>
          <Button onClick={handleConfirmButton} disabled={isLoadingForm}>
            {t('core.confirm')}
          </Button>
        </StyledModalActions>
      </Modal>
    )
  }

  return null
}
