import { lighten } from 'polished'
import styled, { css } from 'styled-components'

import { StoreNameProps, stylesBasedOnThemes } from '@root/theme'
import { LabelProps } from './Label'

const LCSpan = css`
  &::after {
    color: ${({ theme: { colors } }) => lighten(0.15, colors.primary)};
  }
`

const OPSMSpan = css`
  &::after {
    color: ${({ theme: { colors } }) => colors.offer};
  }
`

type StyledLegendProps = LabelProps & StoreNameProps
const Legend = styled.legend<StyledLegendProps>`
  font: ${({ theme: { fonts } }) => fonts.paragraph};
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${({ theme: { colors } }) => colors.darkShade};

  &::first-letter {
    text-transform: uppercase;
  }

  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCSpan,
        PV: LCSpan,
        TO: LCSpan,
        FE: LCSpan,
        VT: LCSpan,
        OPSM: OPSMSpan,
      },
    })};

  &::after {
    content: ${({ required }) => (required ? "'*'" : '')};
  }
`

export { Legend }
