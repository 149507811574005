import React from 'react'
import styled from 'styled-components'

export interface ColumnProps extends React.ComponentPropsWithRef<'div'> {
  $fullWidth?: boolean
}

const StyledColumn = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '50%')};

  & > * {
    margin-bottom: 39px;
  }
`

export const Column: React.FC<ColumnProps> = ({
  children,
  $fullWidth = false,
  ...props
}) => {
  return (
    <StyledColumn $fullWidth={$fullWidth} {...props}>
      {children}
    </StyledColumn>
  )
}
