export type SpacerUnit = number | string

export interface SpacerProps {
  $margin: SpacerUnit
  $mt: SpacerUnit
  $mr: SpacerUnit
  $mb: SpacerUnit
  $ml: SpacerUnit
  $padding: SpacerUnit
  $pt: SpacerUnit
  $pr: SpacerUnit
  $pb: SpacerUnit
  $pl: SpacerUnit
}

const getSpacerValue = (unit: SpacerUnit) => 
   typeof unit === 'number' ? `${unit}px` : unit

const spacer = (props: Partial<SpacerProps>) => ({
  ...(typeof props.$margin !== 'undefined' && { margin: getSpacerValue(props.$margin) }),
  ...(typeof props.$mt !== 'undefined' && { 'margin-top': getSpacerValue(props.$mt) }),
  ...(typeof props.$mr !== 'undefined' && { 'margin-right': getSpacerValue(props.$mr) }),
  ...(typeof props.$mb !== 'undefined' && { 'margin-bottom': getSpacerValue(props.$mb) }),
  ...(typeof props.$ml !== 'undefined' && { 'margin-left': getSpacerValue(props.$ml) }),
  ...(typeof props.$padding !== 'undefined' && { padding: getSpacerValue(props.$padding) }),
  ...(typeof props.$pt !== 'undefined' && { 'padding-top': getSpacerValue(props.$pt) }),
  ...(typeof props.$pr !== 'undefined' && { 'padding-right': getSpacerValue(props.$pr) }),
  ...(typeof props.$pb !== 'undefined' && { 'padding-bottom': getSpacerValue(props.$pb) }),
  ...(typeof props.$pl !== 'undefined' && { 'padding-left': getSpacerValue(props.$pl) }),
})

export default spacer
