import React from 'react'
import styled from 'styled-components'
import { Label } from './Label'
import { HelperText } from './HelperText'
import { Inputs } from './Inputs'
import { Errors } from './Errors'
import clsx from 'clsx'
import spacer, { SpacerProps } from '@root/utils/spacer'

export type InputGroupProps = React.ComponentPropsWithRef<'fieldset'> & Partial<SpacerProps>

const Fieldset = styled.fieldset<Partial<SpacerProps>>`
  border: none;
  padding: 16px 0px;
  margin: 0;
  margin-bottom: 30px;
  position: relative;
  ${spacer}

  & legend {
    padding: 0;
  }

  > div + div {
    margin-top: 30px;
  }
`

export const InputGroup: React.FC<InputGroupProps> & {
  Label: typeof Label
  HelperText: typeof HelperText
  Inputs: typeof Inputs
  Errors: typeof Errors
} = ({ children, className, ...props }) => (
  <Fieldset className={clsx('intake__inputgroup', className)} {...props}>
    {children}
  </Fieldset>
)

InputGroup.Label = Label
InputGroup.HelperText = HelperText
InputGroup.Inputs = Inputs
InputGroup.Errors = Errors
