import { DatePicker } from '@components/DatePicker'
import { TwoColumns } from '@components/Layout/TwoColumns'
import { Select } from '@components/Select'
import { TextField } from '@components/TextField'
import { useCommonConfigs } from '@hooks/useCommonConfigs'
import { useInsuranceFieldVisible } from '@hooks/useInsuranceFieldVisible'
import { useFormStatus } from '@utils/routes'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { genderOptions, relationshipOptions } from './constants'

// const ModalEligibilityContainerNoPrimaryHolder = styled.div`
//   display: flex;
//   align-items: flex-end;
//   margin-bottom: 30px;
// `;

type InsuranceDataProps = {
  isNewInsuranceDataSectionVisible: boolean
  insuranceProvider: any
  relationshipFieldName: string
  firstNameFieldName: string
  lastNameFieldName: string
  genderFieldName: string
  dobFieldName: string
  addressFieldName: string
  cityFieldName: string
  stateFieldName: string
  zipCodeFieldName: string
  ssnFieldName: string
  countryFieldName: string
}

const InsuranceData = ({
  isNewInsuranceDataSectionVisible,
  insuranceProvider,
  relationshipFieldName,
  firstNameFieldName,
  lastNameFieldName,
  genderFieldName,
  dobFieldName,
  addressFieldName,
  cityFieldName,
  stateFieldName,
  zipCodeFieldName,
  ssnFieldName,
  countryFieldName,
}: InsuranceDataProps) => {
  const { t } = useTranslation()
  const { isFormExpired } = useFormStatus()
  const { register, errors, control, watch } = useFormContext()

  const commonConfigs = useCommonConfigs()
  const isFieldVisible = useInsuranceFieldVisible()
  // const isEligibilityCheckEnabled = useEligibilityCheckEnabled();

  const country = watch(countryFieldName);
  // const primaryMemberID = watch('primaryMemberID');
  // const primaryMemberSSN = watch('primaryMemberSSN');
  // const primaryHolderFirstName = watch('primaryHolderFirstName');
  // const primaryHolderLastName = watch('primaryHolderLastName');
  // const primaryHolderDOB = watch('primaryHolderDOB');
  // const primaryHolderSSN = watch('primaryHolderSSN');

  return (
    <>
      <TwoColumns>
        {isNewInsuranceDataSectionVisible && (!insuranceProvider ||
          isFieldVisible(relationshipFieldName, insuranceProvider)) && (
            <Select
              label={t('insurance.relationship')}
              name={relationshipFieldName}
              ref={register}
              disabled={isFormExpired}
              error={!!errors[relationshipFieldName]}
              errorMessage={t(errors[relationshipFieldName]?.message || '', {
                defaultValue: t('core.requiredSelection'),
              })}
              defaultValue=""
              required
            >
              <Select.Option value="" disabled>
                --
              </Select.Option>
              {relationshipOptions.map(({ label, value }) => (
                <Select.Option
                  value={value}
                  key={`${relationshipFieldName}-${label}`}
                >
                  {t(label)}
                </Select.Option>
              ))}
            </Select>
          )}
        {(!insuranceProvider ||
          isFieldVisible(firstNameFieldName, insuranceProvider)) && (
            <TextField
              name={firstNameFieldName}
              label={t('insurance.primaryHolderFirstName')}
              ref={register}
              disabled={isFormExpired}
              error={!!errors[firstNameFieldName]?.message}
              errorMessage={t(errors[firstNameFieldName]?.message || '', {
                defaultValue: t('core.requiredMessage'),
              })}
              $fullWidth
              required
            />
          )}
        {(!insuranceProvider ||
          isFieldVisible(lastNameFieldName, insuranceProvider)) && (
            <TextField
              name={lastNameFieldName}
              label={t('insurance.primaryHolderLastName')}
              ref={register}
              disabled={isFormExpired}
              error={!!errors[lastNameFieldName]?.message}
              errorMessage={t(errors[lastNameFieldName]?.message || '', {
                defaultValue: t('core.requiredMessage'),
              })}
              $fullWidth
              required
            />
          )}
        {isNewInsuranceDataSectionVisible && (!insuranceProvider ||
          isFieldVisible(genderFieldName, insuranceProvider)) && (
            <Select
              label={t('insurance.primaryHolderSex')}
              name={genderFieldName}
              ref={register}
              disabled={isFormExpired}
              error={!!errors[genderFieldName]}
              errorMessage={t(errors[genderFieldName]?.message || '', {
                defaultValue: t('core.requiredSelection'),
              })}
              defaultValue=""
              required
            >
              <Select.Option value="" disabled>
                --
              </Select.Option>
              {genderOptions.map(({ label, value }) => (
                <Select.Option value={value} key={`${genderFieldName}-${label}`}>
                  {t(label)}
                </Select.Option>
              ))}
            </Select>
          )}
        {(!insuranceProvider ||
          isFieldVisible(dobFieldName, insuranceProvider)) && (
            <Controller
              as={DatePicker}
              control={control}
              name={dobFieldName}
              label={t('insurance.primaryHolderDOB')}
              disabled={isFormExpired}
              error={!!errors[dobFieldName]?.message}
              errorMessage={t(errors[dobFieldName]?.message || '', {
                defaultValue: t('core.requiredMessage'),
              })}
              required
            />
          )}
        {isNewInsuranceDataSectionVisible && (!insuranceProvider ||
          isFieldVisible(addressFieldName, insuranceProvider)) && (
            <TextField
              name={addressFieldName}
              label={t('insurance.primaryHolderAddress')}
              ref={register}
              disabled={isFormExpired}
              error={!!errors[addressFieldName]?.message}
              errorMessage={t(errors[addressFieldName]?.message || '', {
                defaultValue: t('core.requiredMessage'),
              })}
              $fullWidth
              required
            />
          )}
        {isNewInsuranceDataSectionVisible && (!insuranceProvider ||
          isFieldVisible(cityFieldName, insuranceProvider)) && (
            <TextField
              name={cityFieldName}
              label={t('insurance.primaryHolderCity')}
              ref={register}
              disabled={isFormExpired}
              error={!!errors[cityFieldName]?.message}
              errorMessage={t(errors[cityFieldName]?.message || '', {
                defaultValue: t('core.requiredMessage'),
              })}
              $fullWidth
              required
            />
          )
        }
        {(true || !insuranceProvider ||
          isFieldVisible(countryFieldName, insuranceProvider)) && (
            <Select
              label={t('core.country')}
              name={countryFieldName}
              ref={register}
              disabled={isFormExpired}
              error={!!errors[countryFieldName]?.message}
              errorMessage={t(errors[countryFieldName]?.message || '', {
                defaultValue: t('core.requiredSelection'),
              })}
              defaultValue=""
              required
            >
              <Select.Option value="" disabled>
                --
              </Select.Option>
              {commonConfigs.country?.map(({ label, value }, index) => (
                <Select.Option
                  value={value}
                  key={`country-${label}-${index}`}
                >
                  {t(`country.${label}`)}
                </Select.Option>
              ))}
            </Select>
          )
        }
        {isNewInsuranceDataSectionVisible && (!insuranceProvider ||
          isFieldVisible(zipCodeFieldName, insuranceProvider)) && (
            <TextField
              name={zipCodeFieldName}
              label={t('insurance.primaryHolderZip')}
              ref={register}
              disabled={isFormExpired}
              error={!!errors[zipCodeFieldName]?.message}
              errorMessage={t(errors[zipCodeFieldName]?.message || '', {
                defaultValue: t('core.requiredMessage'),
              })}
              $fullWidth
              required
            />
          )
        }
        {isNewInsuranceDataSectionVisible && (!insuranceProvider ||
          isFieldVisible(stateFieldName, insuranceProvider)) && (
            <Select
              label={t('core.state')}
              name={stateFieldName}
              ref={register}
              disabled={isFormExpired}
              error={!!errors[stateFieldName]}
              errorMessage={t(errors[stateFieldName]?.message || '', {
                defaultValue: t('core.requiredSelection'),
              })}
              defaultValue=""
              required
            >
              <Select.Option value="" disabled>
                --
              </Select.Option>
              {commonConfigs[country ? `state${country}` : 'state']?.map(({ label, value }, index) => (
                <Select.Option value={value} key={`${stateFieldName}-${label}`}>
                  {t(`state.${label}`, {
                    defaultValue: value,
                  })}
                </Select.Option>
              ))}
            </Select>
          )}

        {(!insuranceProvider ||
          isFieldVisible(ssnFieldName, insuranceProvider)) && (
            <TextField
              name={ssnFieldName}
              label={t('insurance.primaryHolderSSN')}
              ref={register}
              disabled={isFormExpired}
              error={!!errors[ssnFieldName]?.message}
              errorMessage={t(errors[ssnFieldName]?.message || '', {
                defaultValue: t('core.requiredMessage'),
              })}
              required
              styleLabel={{ whiteSpace: 'nowrap' }}
            />
          )
        }

        {/* 
         This code is unreachable because this component is mounted only if the insurancePlan is in group "AP", 
         but the isEligibilityCheckEnabled is true only if group is "E".
        */}
        {/* 
          {isEligibilityCheckEnabled(insuranceProvider) ? (
            <ModalEligibilityContainerNoPrimaryHolder>
              <ModalEligibility
                insuranceProvider={insuranceProvider}
                primaryHolderDOB={primaryHolderDOB}
                primaryHolderFirstName={primaryHolderFirstName}
                primaryHolderLastName={primaryHolderLastName}
                primaryHolderSSN={primaryHolderSSN}
                primaryMemberID={primaryMemberID}
                primaryMemberSSN={primaryMemberSSN}
              />
            </ModalEligibilityContainerNoPrimaryHolder>
          ) : (
            <div></div>
          )}
        */}
      </TwoColumns>
    </>
  )
}

export default InsuranceData