import styled, { css } from 'styled-components'

import { Typography } from '@components/Typography'
import { StoreNameProps, stylesBasedOnThemes } from '@root/theme'

const LCError = css`
  position: absolute;
  left: 0;
`

const OPSMError = css``

const StyledError = styled(Typography)<StoreNameProps>`
  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCError,
        PV: LCError,
        TO: LCError,
        FE: LCError,
        VT: LCError,
        OPSM: OPSMError,
      },
    })};

  margin-top: 9px;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: left;
`

export { StyledError }
