import {
  BaseInput,
  CheckboxesWidget,
  DateWidget,
  DigitsWidget,
  RadioWidget,
  SelectWidget,
  MultiSelectWidget,
  CheckboxWidget,
  TextareaWidget,
  SelfRelativesWidget,
  YearsWidget,
} from './widgets'

import { DescriptionField, TitleField } from './fields'

export const widgets = {
  BaseInput,
  CheckboxesWidget,
  SelectWidget,
  MultiSelectWidget,
  DateWidget,
  DigitsWidget,
  RadioWidget,
  CheckboxWidget,
  TextareaWidget,
  SelfRelativesWidget,
  YearsWidget,
}

export const fields = {
  DescriptionField,
  TitleField,
}
