/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnzForm, Form } from '../models/Form'
import type { FormAppointmentStatus } from '../models/FormAppointmentStatus'
import type { FormId } from '../models/FormId'
import type { FormInfo } from '../models/FormInfo'
import type { FormPatientVersion } from '../models/FormPatientVersion'
import { request as __request } from '../core/request'
import { transformValueInArray } from '@components/RJSF/widgets/MultiSelectWidget'
import { OptIn } from '../models/OptIn'

export class FormService {
  /**
   * Get last form
   * This method get a form for an appointment already scheduled.
   * @returns FormId OK
   * @throws ApiError
   */
  public static async getFormByApptId({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<FormId> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/appointment/${appointmentId}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Get a specific form
   * This method get a form by ID
   * @returns Form OK
   * @throws ApiError
   */
  public static async getFormById({
    brand,
    id,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Form ID used to retrieve data. **/
    id: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<Form> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/${id}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })

    return result.body
  }

  /**
   * Get a specific form
   * This method get a form by ID
   * @returns Form OK
   * @throws ApiError
   */
  public static async getFormByAppointmentId({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<AnzForm> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/retrieveform/${appointmentId}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })

    const cleanedData = FormService.removePrivacyPolicyFields(result.body)

    return FormService.formDataToState(cleanedData)
  }

  /**
   * Update form data and continue to next step
   * This method update a form for an appointment already scheduled. After the form is saved, user continues to complete other step of form. [Continue form buttom]
   * @returns Form Updated
   * @throws ApiError
   */
  public static async upsertFormByAppointmentId<T = Form | AnzForm>({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
    requestBody,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Form ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    requestBody?: T
  }): Promise<T> {
    const result = await __request({
      method: 'PUT',
      path: `/${brand}/form/appointment/${appointmentId}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Update form data and continue to next step
   * This method update a form for an appointment already scheduled. After the form is saved, user continues to complete other step of form. [Continue form buttom]
   * @returns Form Updated
   * @throws ApiError
   */
  public static async upsertFormById({
    brand,
    id,
    caller,
    ocpApimSubscriptionKey,
    requestBody,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Form ID used to retrieve data. **/
    id: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    requestBody?: Form
  }): Promise<Form> {
    const result = await __request({
      method: 'PUT',
      path: `/${brand}/form/${id}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Update form data and send Email
   * This method update a form for an appointment already scheduled. After saved form, send an Email. [Complete form button or Save later buttom]
   * @returns Form Updated
   * @throws ApiError
   */
  public static async upsertFormByIdAndSendEmail<T = Form | AnzForm>({
    brand,
    id,
    caller,
    ocpApimSubscriptionKey,
    requestBody,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Form ID used to retrieve data. **/
    id: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    requestBody?: T
  }): Promise<T> {
    const result = await __request({
      method: 'POST',
      path: `/${brand}/form/${id}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Get forms ordered by last modified date desc
   * This method get a list of form by Patient ID
   * @returns FormPatientVersion OK
   * @throws ApiError
   */
  public static async getFormsByPatientId({
    brand,
    patientId,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Patient ID used to retrieve data. **/
    patientId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<FormPatientVersion> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/patient/${patientId}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Get status forms
   * This method get a form status for appointment ids.
   * @returns FormAppointmentStatus OK
   * @throws ApiError
   */
  public static async getFormStatusByApptIds({
    brand,
    appointmentIds,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** List of appointment ID **/
    appointmentIds: any[]
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<FormAppointmentStatus> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/status`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      query: {
        appointmentIds: appointmentIds,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Print PDF Form by ID
   * This method post formID to print PDF
   * @returns any OK
   * @throws ApiError
   */
  public static async printFormById({
    brand,
    formId,
    caller,
    ocpApimSubscriptionKey,
    acceptedLanguage,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** The FormID required to print PDF **/
    formId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** Language in use */
    acceptedLanguage?: 'en-NZ' | 'en-AU'
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/print/${formId}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Accept-Language': acceptedLanguage,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Get form information by ApptId
   * This method get a form for an appointment already scheduled.
   * @returns FormInfo OK
   * @throws ApiError
   */
  public static async getFormInfoByApptId({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<FormInfo> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/patient/appointment/${appointmentId}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Print PDF for document type signed by ID
   * This method get PDF of the signed document type
   * @returns any OK
   * @throws ApiError
   */
  public static async printDocumentTypeByFormId({
    brand,
    documentType,
    lang,
    formId,
    caller,
    ocpApimSubscriptionKey,
    clinicName,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Type of document to retrieve for PDF **/
    documentType: string
    /** Lang to retrieve document **/
    lang: string
    /** The FormID required to print signed document type **/
    formId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** The clinic name **/
    clinicName?: string
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/print/${documentType}/${lang}/${formId}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      query: {
        clinicName: clinicName,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Update privacy policy fields
   * This method update privacy policy fields in a form for an appointment already scheduled. After the fields are saved, user grants access to the form.
   * @returns Privacy policy fields Updated
   * @throws ApiError
   */
   public static async upsertPrivacyPolicyByAppId({
    brand,
    appointmentId,
    requestBody,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    requestBody?: OptIn
  }): Promise<AnzForm> {
    const result = await __request({
      method: 'PUT',
      path: `/${brand}/form/upsertPrivacyPracticeByAppId/${appointmentId}`,
      headers: {
        // caller: caller,
        // 'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return FormService.formDataToState(result.body)
  }

  private static removePrivacyPolicyFields(formData: AnzForm): AnzForm {
    formData.optPrivacyPractice = {
      date: undefined,
      hasAccepted: undefined,
      signature: undefined,
      signatureType: undefined,
    }

    return formData
  }

  private static formDataToState(formData: AnzForm): AnzForm {
    if ('reasonForVisit' in formData.questionAnswer) {
      formData.questionAnswer.reasonForVisit = transformValueInArray(
        formData.questionAnswer.reasonForVisit as (string | string[] | undefined)
      )
    }

    return formData
  }
}
