import { Context, useContext } from 'react'
import { AbstractState, AsyncState } from 'react-async'

import { TabApiFormContext } from '../ProviderForm'
import { TabApiAnzFormContext } from '../ProviderAnzForm'
import useIsAnz from '@hooks/useIsAnz'
import { Form } from '@api'
import { AnzForm, QuestionAnswer } from '@root/api/models/Form'

type AsyncType<T> = AsyncState<T, AbstractState<T>>
type ContextType<T> = Context<AsyncType<T>>

export function isAnzForm(
  formData: Form | AnzForm | undefined,
  isAnz?: boolean
): formData is AnzForm {
  if (formData) {
    return isAnz || formData.hasOwnProperty('statusForm')
  }
  return false
}

export const flatFormObjectIfAnz = (data: AnzForm | Form | undefined): (Form & QuestionAnswer) | Form | undefined => {
  if (data) {
    return isAnzForm(data)
      ? { ...(data as AnzForm).questionAnswer, ...(data as Form) }
      : (data as Form)
  }
}

export function isAnzContext(
  isAnz: boolean,
  context: ContextType<Form> | ContextType<AnzForm>
): context is ContextType<AnzForm> {
  return isAnz
}

export function useTabApiForm<T = AnzForm | Form>() {
  const isAnz = useIsAnz()
  const context = isAnzContext(isAnz, TabApiAnzFormContext)
    ? (TabApiAnzFormContext as Context<
      AsyncState<AnzForm, AbstractState<AnzForm>>
    >)
    : (TabApiFormContext as Context<AsyncState<Form, AbstractState<Form>>>)

  // @ts-ignore
  return useContext<AsyncType<T>>(context)
}
