import {
  isoStringToLocaleDateString,
  localeDateStringToIsoString,
} from './date'

import { Form } from '../api'
import { IntakeFormUpdate } from '../types/steps'
import pickBy from 'lodash/pickBy'
import { capitalize } from './stringUtils'

const stringToBoolean = (value: string | undefined): boolean | undefined => {
  if (value === 'true') return true
  if (value === 'false') return false
  return undefined
}

const booleanToString = (
  value: boolean | undefined
): 'true' | 'false' | undefined => {
  if (value === true) return 'true'
  if (value === false) return 'false'
  return undefined
}

export const formStateToFormApi = (
  formState: IntakeFormUpdate,
  locale: string,
  isFieldVisible?: (fieldName: string, provider: string | undefined) => boolean,
): Partial<Form> => {
  const formApi = {
    ...formState,
    hasAllergies: stringToBoolean(formState.hasAllergies),
    hasEyeInjuriesSurgeries: stringToBoolean(formState.hasEyeInjuriesSurgeries),
    hasCurrentlyMedications: stringToBoolean(formState.hasCurrentlyMedications),
    hasTreatedMedicalConditions: stringToBoolean(
      formState.hasTreatedMedicalConditions
    ),
    isPrimaryHolder: stringToBoolean(formState.isPrimaryHolder),
    isAAAMember: stringToBoolean(formState.isAAAMember),
    isAARPMember: stringToBoolean(formState.isAARPMember),
    isAssistanceRequired: stringToBoolean(formState.isAssistanceRequired),
    isCurrentlyNursing: stringToBoolean(formState.isCurrentlyNursing),
    isDrinkAlcohol: stringToBoolean(formState.isDrinkAlcohol),
    isHMOMember: stringToBoolean(formState.isHMOMember),
    isInterpreterRequired: stringToBoolean(formState.isInterpreterRequired),
    isInterestedInContactLens: stringToBoolean(
      formState.isInterestedInContactLens
    ),
    isPregnant: stringToBoolean(formState.isPregnant),
    isSmoker: stringToBoolean(formState.isSmoker),
    dob: localeDateStringToIsoString(formState.dob, locale),
    primaryHolderDOB: localeDateStringToIsoString(
      formState.primaryHolderDOB,
      locale
    ),
    assistance: stringToBoolean(formState.isAssistanceRequired)
      ? formState.assistance
      : undefined,
    treatedMedicalConditionsDescription: stringToBoolean(
      formState.hasTreatedMedicalConditions
    )
      ? formState.treatedMedicalConditionsDescription
      : undefined,
    eyeInjuriesSurgeriesDescription: stringToBoolean(
      formState.hasEyeInjuriesSurgeries
    )
      ? formState.eyeInjuriesSurgeriesDescription
      : undefined,
    currentlyMedicationsDescription: stringToBoolean(
      formState.hasCurrentlyMedications
    )
      ? formState.currentlyMedicationsDescription
      : undefined,
    allergiesDescription: stringToBoolean(formState.hasAllergies)
      ? formState.allergiesDescription
      : undefined,
    languageAssistance:
      stringToBoolean(formState.isInterpreterRequired) &&
      formState.languageAssistance != null
        ? [formState.languageAssistance]
        : undefined,
    gender: capitalize(formState.gender),
    isMedicalInsurancePrimaryHolder: stringToBoolean(
      formState.isMedicalInsurancePrimaryHolder
    ),
    medicalInsuranceHolderDOB: localeDateStringToIsoString(
      formState.medicalInsuranceHolderDOB,
      locale
    ),
  }

  const picked = pickBy(formApi, (v) => v != null) // remove null/undefined to avoid previous form override

  if (formApi.environmentWorkType) {
    picked.environmentWorkType = formState.environmentWorkType?.values?.length
      ? {
          ...formState.environmentWorkType,
          other: formState.environmentWorkType?.values
            .map((o) => o.toLowerCase())
            .includes('other')
            ? formState.environmentWorkType?.other
            : undefined,
        }
      : undefined
  }

  if (formApi.likeToDo) {
    picked.likeToDo = formState.likeToDo?.values?.length
      ? {
          ...formState.likeToDo,
          other: formState.likeToDo?.values
            .map((o) => o.toLowerCase())
            .includes('other')
            ? formState.likeToDo?.other
            : undefined,
        }
      : undefined
  }

  
  if (isFieldVisible && !isFieldVisible('isPrimaryHolder', formState.insuranceProvider)) {
    picked.primaryMemberID = undefined
    picked.primaryHolderSSN = undefined
    picked.isPrimaryHolder = undefined
    picked.relationshipWithHolder = undefined
    picked.primaryHolderFirstName = undefined
    picked.primaryHolderLastName = undefined
    picked.primaryHolderGender = undefined
    picked.primaryHolderDOB = undefined
    picked.primaryHolderAddress = undefined
    picked.primaryHolderCity = undefined
    picked.primaryHolderState = undefined
    picked.primaryHolderZipCode = undefined
    picked.primaryMemberSSN = undefined
  }

  return picked
}

export const formApiToFormState = (
  formApi: Form,
  locale: string
): IntakeFormUpdate => {
  return {
    ...formApi,
    hasAllergies: booleanToString(formApi.hasAllergies),
    hasEyeInjuriesSurgeries: booleanToString(formApi.hasEyeInjuriesSurgeries),
    hasCurrentlyMedications: booleanToString(formApi.hasCurrentlyMedications),
    hasTreatedMedicalConditions: booleanToString(
      formApi.hasTreatedMedicalConditions
    ),
    isPrimaryHolder: booleanToString(formApi.isPrimaryHolder),
    isAAAMember: booleanToString(formApi.isAAAMember),
    isAARPMember: booleanToString(formApi.isAARPMember),
    isAssistanceRequired: booleanToString(formApi.isAssistanceRequired),
    isCurrentlyNursing: booleanToString(formApi.isCurrentlyNursing),
    isDrinkAlcohol: booleanToString(formApi.isDrinkAlcohol),
    isHMOMember: booleanToString(formApi.isHMOMember),
    isInterpreterRequired: booleanToString(formApi.isInterpreterRequired),
    isInterestedInContactLens: booleanToString(
      formApi.isInterestedInContactLens
    ),
    isPregnant: booleanToString(formApi.isPregnant),
    isSmoker: booleanToString(formApi.isSmoker),
    dob: isoStringToLocaleDateString({ isoString: formApi.dob, locale }),
    primaryHolderDOB: isoStringToLocaleDateString({
      isoString: formApi.primaryHolderDOB,
      locale,
    }),
    languageAssistance: formApi.languageAssistance?.[0],
    primaryHolderGender: capitalize(formApi.primaryHolderGender),
    medicalInsuranceHolderGender: capitalize(
      formApi.medicalInsuranceHolderGender
    ),
    isMedicalInsurancePrimaryHolder: booleanToString(
      formApi.isMedicalInsurancePrimaryHolder
    ),
    medicalInsuranceHolderDOB: isoStringToLocaleDateString({
      isoString: formApi.medicalInsuranceHolderDOB,
      locale,
    }),
  }
}
