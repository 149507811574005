import { createGlobalStyle, css, DefaultTheme } from 'styled-components'
import { transparentize, rgba } from 'polished'
import { BrandName, StoreNameProps } from '@root/theme'

const OPSM = (theme: DefaultTheme) => css`
  .intake__modal-body-open {
    overflow: hidden;
  }
  .intake__modal-overlay {
    z-index: 2000;
  }
  .intake__modal {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${rgba(theme.colors.softGrey!, 0.35)};
    }

    &-content {
      position: absolute;
      max-height: 92vh;
      max-width: 90vw;
      width: 605px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      overflow: auto;
      outline: none;
      border: 1px solid ${theme.colors.darkShade};

      padding: 19px 30px;
    }
  }
`

const LC = (theme: DefaultTheme) => css`
  .intake__modal-body-open {
    overflow: hidden;
  }
  .intake__modal-overlay {
    z-index: 2000;
  }
  .intake__modal {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${transparentize(0.25, theme.colors.darkShade)};
    }

    &-content {
      position: absolute;
      max-height: 92vh;
      max-width: 90vw;
      width: 492px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      overflow: auto;
      border-radius: 10px;
      outline: none;
      padding: 10px;

      ${theme.breakpoints.media.large} {
        padding: 40px;
      }
    }
  }
`

function modalStylesBasedOnThemes({
  storeName,
  theme,
}: {
  storeName: BrandName
  theme: DefaultTheme
}) {
  switch (storeName) {
    case 'LC':
      return LC(theme)
    case 'PV':
      return LC(theme)
    case 'TO':
      return LC(theme)
    case 'FE':
      return LC(theme)
    case 'VT':
      return LC(theme)
    case 'OPSM':
      return OPSM(theme)
  }
}

const ModalStyles = createGlobalStyle<StoreNameProps>`
${modalStylesBasedOnThemes}
`

export default ModalStyles
