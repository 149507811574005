import { Form } from '@api'
import { yupResolver } from '@hookform/resolvers/yup'
import usePageClassname from '@hooks/usePageClassname'
import { capitalize } from '@root/utils/stringUtils'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import React, { ChangeEvent, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RouteProps } from 'react-router'
import { AppointmentRecap } from '../components/AppointmentRecap'
import { Button, LinkButton } from '../components/Button'
import { Checkbox } from '../components/Checkbox'
import { DatePicker } from '../components/DatePicker'
import { InputGroup } from '../components/InputGroup'
import { Layout } from '../components/Layout'
import { TwoVerticalColumns } from '../components/Layout/TwoColumns'
import { Loader } from '../components/Loader'
import { Radio } from '../components/Radio'
import { Select } from '../components/Select'
import { StepLayout } from '../components/StepLayout'
import { TextArea } from '../components/TextArea'
import { TextField } from '../components/TextField'
import { Typography } from '../components/Typography'
import { useCommonConfigs } from '../hooks/useCommonConfigs'
import { useErrorsDebug } from '../hooks/useErrorsDebug'
import { useFormUpdate } from '../hooks/useFormUpdate'
import { useTabApiAppointment } from '../services/TabApiProvider/ProviderAppointment'
import { getBasicsSchema } from '../types/steps/basics'
import { onFormatPhoneNumber } from '../utils/formatPhoneNumber'
import { formApiToFormState } from '../utils/formStateToFromApi'
import { intro, useFormStatus, useStep } from '../utils/routes'

type BasicsProps = RouteProps

export const Basics: React.FC<BasicsProps> = () => {
  const { id, isFromReview } = useStep()
  const { isFormExpired } = useFormStatus()
  const { t, i18n } = useTranslation()
  const { data, run, isLoading, promise } = useTabApiForm<Form>()

  // const { isBackOffice } = useFormMode()
  // useLastCompletedStep(data?.step, isBackOffice)

  const { data: appointmentData, isLoading: isAppointmentLoading } =
    useTabApiAppointment()
  const commonConfigs = useCommonConfigs()
  const basicsSchema = useMemo(
    () => getBasicsSchema(i18n.language),
    [i18n.language]
  )
  const formMethods = useForm({
    resolver: yupResolver(basicsSchema),
    mode: 'onBlur',
    defaultValues: data ? formApiToFormState(data, i18n.language) : {},
  })
  const { register, handleSubmit, errors, control, reset, watch, setValue } =
    formMethods
  useErrorsDebug(errors)

  const country = watch('country')
  const isAssistanceRequired = watch('isAssistanceRequired')
  const isInterpreterRequired = watch('isInterpreterRequired')
  const gender = watch('gender')

  const onSubmit = useFormUpdate(data, reset, run, promise)

  const onChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    const formattedPhoneNumber = onFormatPhoneNumber(event.target.value)
    if (formattedPhoneNumber) setValue('phone', formattedPhoneNumber)
  }

  usePageClassname('basics-page')

  if (appointmentData && commonConfigs) {
    const {
      clinic: { state: clinicState, country: clinicCountry },
    } = appointmentData
    const isCalifornia = clinicCountry === 'US' && clinicState === 'CA'

    return (
      <Layout>
        <Layout.Header showCompletitionTracker completitionTracketValue={1.6} />

        <Layout.Content>
          <StepLayout>
            <StepLayout.Title>{t('basics.title')}</StepLayout.Title>

            <StepLayout.Description>
              {t('basics.description')}
            </StepLayout.Description>

            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)} id={id}>
                <TwoVerticalColumns>
                  <TextField
                    name="firstName"
                    label={t('core.firstName')}
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.firstName?.message}
                    errorMessage={t(errors.firstName?.message || '', {
                      defaultValue: t('core.requiredMessage'),
                    })}
                    $fullWidth
                    required
                  />

                  <TextField
                    name="lastName"
                    label={t('core.lastName')}
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.lastName?.message}
                    errorMessage={t(errors.lastName?.message || '', {
                      defaultValue: t('core.requiredMessage'),
                    })}
                    $fullWidth
                    required
                  />

                  <Controller
                    as={DatePicker}
                    control={control}
                    name="dob"
                    label={t('core.dob')}
                    error={!!errors.dob?.message}
                    errorMessage={t(errors.dob?.message || '', {
                      defaultValue: t('core.requiredMessage'),
                    })}
                    required
                    disabled={isFormExpired}
                  />

                  <TextField
                    name="phone"
                    label={t('core.phone')}
                    ref={register}
                    onChange={onChangePhone}
                    disabled={isFormExpired}
                    error={!!errors.phone?.message}
                    errorMessage={t(errors.phone?.message || '', {
                      defaultValue: t('core.requiredMessage'),
                    })}
                    required
                  />

                  <TextField
                    name="email"
                    label={t('core.email')}
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.email?.message}
                    errorMessage={t(errors.email?.message || '', {
                      defaultValue: t('core.requiredMessage'),
                    })}
                    $fullWidth
                    required
                  />

                  <TextField
                    name="occupation"
                    label={t('core.occupation')}
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.occupation?.message}
                    errorMessage={t(errors.occupation?.message || '', {
                      defaultValue: t('core.requiredMessage'),
                    })}
                    $fullWidth
                    required
                  />

                  <TextField
                    name="address"
                    label={t('core.address')}
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.address?.message}
                    errorMessage={t(errors.address?.message || '', {
                      defaultValue: t('core.requiredMessage'),
                    })}
                    $fullWidth
                    required
                  />

                  <TextField
                    name="city"
                    label={t('core.city')}
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.city?.message}
                    errorMessage={t(errors.city?.message || '', {
                      defaultValue: t('core.requiredMessage'),
                    })}
                    $fullWidth
                    required
                  />

                  <Select
                    label={t('core.country')}
                    name="country"
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.country}
                    errorMessage={t(errors.country?.message || '', {
                      defaultValue: t('core.requiredSelection'),
                    })}
                    defaultValue=""
                    required
                  >
                    <Select.Option value="" disabled>
                      --
                    </Select.Option>
                    {commonConfigs.country?.map(({ label, value }, index) => (
                      <Select.Option
                        value={value}
                        key={`country-${label}-${index}`}
                      >
                        {t(`country.${label}`)}
                      </Select.Option>
                    ))}
                  </Select>

                  <Select
                    label={t('core.state')}
                    name="state"
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.state}
                    errorMessage={t(errors.state?.message || '', {
                      defaultValue: t('core.requiredSelection'),
                    })}
                    defaultValue=""
                    required
                  >
                    <Select.Option value="" disabled>
                      --
                    </Select.Option>
                    {commonConfigs[country ? `state${country}` : 'state']?.map(
                      ({ label, value }, index) => (
                        <Select.Option
                          value={value}
                          key={`state-${label}-${index}`}
                        >
                          {t(`state.${label}`, {
                            defaultValue: value,
                          })}
                        </Select.Option>
                      )
                    )}
                  </Select>

                  <TextField
                    name="zipCode"
                    label={t(
                      country === 'CA' ? 'core.postCode' : 'core.zipCode'
                    )}
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.zipCode?.message}
                    errorMessage={t(errors.zipCode?.message || '', {
                      defaultValue: t('core.requiredMessage'),
                    })}
                    required
                    maxLength={country === 'CA' ? 6 : 5}
                  />

                  <TextField
                    name="employer"
                    label={t('core.employer')}
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.employer?.message}
                    $fullWidth
                  />
                </TwoVerticalColumns>

                <InputGroup $mb={0}>
                  <InputGroup.Label required>
                    {t('core.gender')}
                  </InputGroup.Label>

                  <InputGroup.Inputs>
                    {commonConfigs.gender
                      ?.map((gender) => ({
                        ...gender,
                        value: capitalize(gender.value),
                      }))
                      .map(({ label, value }, index) => (
                        <Radio
                          key={`gender-${label}-${index}`}
                          name="gender"
                          value={value}
                          label={t(`gender.${label}`)}
                          ref={register}
                          disabled={isFormExpired}
                          error={!!errors.gender}
                        />
                      ))}

                    <TextField
                      name="preferredPronoun"
                      label={t('basics.preferredPronoun')}
                      ref={register}
                      disabled={isFormExpired}
                      required={gender === 'OTHER'}
                      error={!!errors.preferredPronoun}
                    />
                  </InputGroup.Inputs>
                  <InputGroup.Errors
                    error={!!errors.gender}
                    errorMessage={t(
                      errors.gender?.message ||
                        errors.preferredPronoun?.message ||
                        '',
                      {
                        defaultValue: t('core.requiredSelection'),
                      }
                    )}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Label required>
                    {t('basics.preferredContactMethods')}
                  </InputGroup.Label>

                  <InputGroup.Inputs>
                    {commonConfigs.preferredContactMethods?.map(
                      ({ label, value }, index) => (
                        <Checkbox
                          key={`preferredContactMethods-${label}-${index}`}
                          name="preferredContactMethods"
                          value={value}
                          label={t(`preferredContactMethods.${label}`)}
                          tooltip={t(
                            `preferredContactMethods.${label}Tooltip`,
                            {
                              defaultValue: '',
                            }
                          )}
                          ref={register}
                          disabled={isFormExpired}
                          defaultChecked
                          error={!!errors.preferredContactMethods}
                        />
                      )
                    )}
                  </InputGroup.Inputs>

                  <InputGroup.HelperText>
                    {t('basics.preferredContactMethodsHelpText')}
                  </InputGroup.HelperText>
                  <InputGroup.Errors
                    error={!!errors.preferredContactMethods}
                    errorMessage={t(
                      errors.preferredContactMethods?.[0]?.message || '',
                      {
                        defaultValue: t('core.requiredSelection'),
                      }
                    )}
                  />
                </InputGroup>
                {isCalifornia && (
                  <>
                    <InputGroup>
                      <InputGroup.Label>
                        {t('basics.isInterpreterRequired')}
                      </InputGroup.Label>

                      <InputGroup.Inputs>
                        <Radio
                          name="isInterpreterRequired"
                          label={t('core.yes')}
                          value="true"
                          ref={register}
                          disabled={isFormExpired}
                        />

                        <Radio
                          name="isInterpreterRequired"
                          label={t('core.no')}
                          value="false"
                          ref={register}
                          disabled={isFormExpired}
                          defaultChecked
                        />
                      </InputGroup.Inputs>
                      <InputGroup.Errors
                        error={!!errors.isInterpreterRequired}
                        errorMessage={t(
                          errors.isInterpreterRequired?.message || '',
                          {
                            defaultValue: t('core.requiredSelection'),
                          }
                        )}
                      />
                    </InputGroup>

                    {isInterpreterRequired === 'true' && (
                      <>
                        <InputGroup>
                          <InputGroup.Label>
                            {t('basics.languageAssistance')}
                          </InputGroup.Label>

                          <InputGroup.Inputs $twoColumns>
                            {commonConfigs.languageAssistance?.map(
                              ({ label, value }, index) => (
                                <Radio
                                  name="languageAssistance"
                                  key={`${label}-${index}`}
                                  label={t(`languageAssistance.${label}`)}
                                  value={value}
                                  ref={register}
                                  disabled={isFormExpired}
                                />
                              )
                            )}
                          </InputGroup.Inputs>
                          <InputGroup.Errors
                            error={!!errors.languageAssistance}
                            errorMessage={t(
                              errors.languageAssistance?.message || '',
                              {
                                defaultValue: t('core.requiredSelection'),
                              }
                            )}
                          />
                        </InputGroup>
                      </>
                    )}

                    <InputGroup>
                      <InputGroup.Label>
                        {t('basics.isAssistanceRequired')}
                      </InputGroup.Label>

                      <InputGroup.Inputs>
                        <Radio
                          name="isAssistanceRequired"
                          label={t('core.yes')}
                          value="true"
                          ref={register}
                          disabled={isFormExpired}
                        />
                        <Radio
                          name="isAssistanceRequired"
                          label={t('core.no')}
                          value="false"
                          ref={register}
                          disabled={isFormExpired}
                        />
                      </InputGroup.Inputs>
                      <InputGroup.Errors
                        error={!!errors.isAssistanceRequired}
                        errorMessage={t(
                          errors.isAssistanceRequired?.message || '',
                          {
                            defaultValue: t('core.requiredSelection'),
                          }
                        )}
                      />
                    </InputGroup>

                    {isAssistanceRequired === 'true' && (
                      <TextArea
                        label={t('basics.assistance')}
                        error={!!errors.assistance}
                        errorMessage={t(errors.assistance?.message || '', {
                          defaultValue: t('core.requiredSelection'),
                        })}
                        helperText={t('core.maxCharacters', {
                          numberOfCharacter: 300,
                        })}
                        name="assistance"
                        ref={register}
                        disabled={isFormExpired}
                        required={isAssistanceRequired === 'true'}
                      />
                    )}

                    <InputGroup>
                      <InputGroup.Label>
                        {t('basics.ethnicity')}{' '}
                        <Typography $variant="span" $weight={200}>
                          ({t('core.selectAllThatApply')})
                        </Typography>
                      </InputGroup.Label>

                      <InputGroup.Inputs $twoColumns>
                        {commonConfigs.ethnicity?.map(
                          ({ label, value }, index) => (
                            <Checkbox
                              name="ethnicity"
                              key={`ethnicity-${label}-${index}`}
                              value={value}
                              label={t(`ethnicity.${label}`)}
                              tooltip={t(`ethnicity.${label}Tooltip`, {
                                defaultValue: '',
                              })}
                              ref={register}
                              disabled={isFormExpired}
                            />
                          )
                        )}
                      </InputGroup.Inputs>
                      <InputGroup.Errors
                        error={!!errors.ethnicity}
                        errorMessage={t(errors.ethnicity?.[0]?.message || '', {
                          defaultValue: t('core.requiredSelection'),
                        })}
                      />
                    </InputGroup>
                  </>
                )}
                {(isLoading || isAppointmentLoading) && <Loader />}
              </form>
            </FormProvider>
          </StepLayout>

          <AppointmentRecap />
        </Layout.Content>

        <Layout.Footer>
          <LinkButton
            data-element-id={
              isFromReview ? 'X_OEE_View&Edit_Back' : 'X_OEE_Basic_Back'
            }
            to={intro()}
            $outlined
            $compact
          >
            {t('core.back')}
          </LinkButton>

          {!isFormExpired && (
            <Button
              type="submit"
              form={id}
              data-element-id={
                isFromReview
                  ? 'X_OEE_View&Edit_Continue'
                  : isCalifornia
                  ? 'X_OEE_BasicCa_Continue'
                  : 'X_OEE_Basic_Continue'
              }
              disabled={isLoading}
              $compact
            >
              {t(isFromReview ? 'core.saveChanges' : 'core.continue')}
            </Button>
          )}
        </Layout.Footer>
      </Layout>
    )
  }

  return null
}
