import React from 'react'
import styled from 'styled-components'
import { Title } from './Title'
import { Description } from './Description'
import { lighten } from 'polished'
import { Typography } from '../Typography'

export interface StepLayoutProps extends React.ComponentProps<'div'> {
  hideRequiredHint?: boolean
}

type StepLayoutSubComponents = {
  Title: typeof Title
  Description: typeof Description
}

const StyledStepLayout = styled.div<StepLayoutProps>`
  border-radius: 10px;
  background-color: white;
  padding: 35px;
  position: relative;
  width: 100%;

  & h1 {
    margin-top: 0;
  }

  & fieldset {
    padding: 0;
    border: none;
  }

  & .form-group > .control-label {
    display: none;
  }
`

const StyledRequiredHint = styled(Typography)`
  position: absolute;
  top: 0px;
  right: 25px;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.41px;

  &::before {
    content: '*';
    position: absolute;
    left: -10px;
    color: ${({ theme: { colors } }) => lighten(0.125, colors.primary)};
  }
`

const StepLayoutComponent =
  React.forwardRef<HTMLDivElement, StepLayoutProps>(({ children, hideRequiredHint, ...props }, ref) => (
    <StyledStepLayout {...props} ref={ref}>
      {!hideRequiredHint && <StyledRequiredHint>Required</StyledRequiredHint>}
      {children}
    </StyledStepLayout>
  ))

export const StepLayout = Object.assign(StepLayoutComponent, {
  Title: Title,
  Description: Description,
})
