import { useCallback, useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { useConfig } from '../Context'
import { useFormMode } from '../hooks/useFormMode'
import useIsAnz from '@hooks/useIsAnz'
import {
  flatFormObjectIfAnz,
  isAnzForm,
  useTabApiForm,
} from '../services/TabApiProvider/hooks/useTabApiForm'
import { useTabApiAppointment } from '@root/services/TabApiProvider/ProviderAppointment'

// #region ::: TYPES
function stringLiterals<T extends string>(...args: T[]): T[] {
  return args
}
const statusStep = stringLiterals(
  'CREATED',
  '1_CONTACT_INFO',
  '2_INSURANCE_INFO',
  '3_HEALTH_HISTORY',
  '4_VISION_HISTORY',
  '5_PATIENT_BACKGROUND',
  '6_CONFIRMATION',
  'COMPLETED',
  'CANCELLED'
)

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never

export type TStatusStep = ElementType<typeof statusStep>
// #endregion

export const confirmation = () => '/confirmation'
export const continueLater = () => '/continue'
export const anzIntro = () => '/anz-intro'
export const anzForm = () => '/anz-form'
export const intro = () => '/'
export const legal = (docType = ':docType') => `/legal/${docType}`
export const step = (stepId = ':id') => `/intake-form/${stepId}`

export const steps = [
  '1_CONTACT_INFO',
  '2_INSURANCE_INFO',
  '3_HEALTH_HISTORY',
  '4_VISION_HISTORY',
  '5_PATIENT_BACKGROUND',
  '6_CONFIRMATION',
] as const

export const getLatestStep = (
  currentStep: TStatusStep,
  stepToValidate: TStatusStep
) => {
  const step1Index = steps.findIndex((s) => s === currentStep)
  const step2Index = steps.findIndex((s) => s === stepToValidate)
  return step1Index > step2Index ? currentStep : stepToValidate
}

export const isFormStep = (step: string): step is IntakeFormStep =>
  steps.includes(step as any)

export const isStepCompleted = (
  stepToValidate: TStatusStep,
  currentStep?: TStatusStep
): boolean => {
  if (!currentStep) return false
  if (currentStep === 'COMPLETED') return true
  if (currentStep === 'CANCELLED') return true
  const isValid = isFormStep(currentStep)
  const isDifferent = currentStep !== stepToValidate
  const isLast = getLatestStep(currentStep, stepToValidate) === currentStep
  if (isValid && isDifferent && isLast) return true
  return false
}

export const isStepDisabled = (
  stepToValidate: TStatusStep,
  currentStep?: TStatusStep
): boolean =>
  !(
    stepToValidate === currentStep ||
    isStepCompleted(stepToValidate, currentStep)
  )

let hasAlredyRedirected = false

export const useBypassIntro = () => {
  const isAnz = useIsAnz()

  const history = useHistory()
  const { startFromReview } = useFormMode()

  const { data: formData } = useTabApiForm()

  const latestStep = formData?.step
  const isPrivacyPracticeSigned = formData?.optPrivacyPractice?.signature
  const appointmentStatus = (formData && 'statusAppointment' in formData) 
    ? formData?.statusAppointment
    : undefined

  useEffect(() => {
    if (hasAlredyRedirected) {
      return
    }

    if (isAnz) {
      if (isPrivacyPracticeSigned && appointmentStatus !== 'DELETED') {
        history.push(anzForm())
      }
      return
    }

    if (startFromReview) {
      hasAlredyRedirected = true
      history.replace(step('6_CONFIRMATION'))
      return
    }

    if (!latestStep) {
      return
    }

    if (isFormStep(latestStep)) {
      hasAlredyRedirected = true
      history.replace(step(latestStep))
    }
  }, [startFromReview, history, latestStep, isAnz, isPrivacyPracticeSigned, appointmentStatus])
}

export const useStepId = () =>
  useRouteMatch<{ id: IntakeFormStep }>(step())?.params.id || 'CREATED'

export type LocationState = Record<string, unknown>

export const useStep = () => {
  const history = useHistory()
  const location = useLocation<LocationState>()
  const from = location.state?.from
  const isFromReview = from === '6_CONFIRMATION'
  const id = useStepId()
  const idIndex = steps.findIndex((s) => s === id)
  const nextStep = steps[idIndex + 1] || null
  const previousStep = steps[idIndex - 1] || null
  const { data: formDataHook } = useTabApiForm()
  const formData = flatFormObjectIfAnz(formDataHook)

  const next = useCallback(
    (state: LocationState = {}) => {
      if (!nextStep) return

      let targetStep: TStatusStep = nextStep
      if (isFromReview) {
        targetStep = '6_CONFIRMATION'
      }

      history.push(step(targetStep), { from: id, ...state })
    },
    [history, nextStep, id, formData?.step]
  )

  const previous = useCallback(
    (state: LocationState = {}) => {
      if (!previousStep) return

      let targetStep: TStatusStep = previousStep
      if (isFromReview) {
        targetStep = '6_CONFIRMATION'
      }

      history.push(step(targetStep), { from: id, ...state })
    },
    [history, previousStep, id, isFromReview]
  )

  const push = useCallback(
    (targetStep: IntakeFormStep, state: LocationState = {}) => {
      history.push(step(targetStep), { from: id, ...state })
    },
    [history, id]
  )

  return {
    id,
    isFromReview,
    next,
    nextStep,
    previous,
    previousStep,
    push,
  }
}

export const useFormStatus = (): {
  isFormExpired: boolean
  isBackOffice: boolean
  isFormBilled: boolean
  isFormArrived: boolean
} => {
  const isAnz = useIsAnz()
  const { data: appointmentData } = useTabApiAppointment()
  const { data: formData } = useTabApiForm()

  const isFormExpired = isAnz
    ? // @ts-ignore
      formData.statusForm === 'EXPIRED'
    : // @ts-ignore
      formData?.status === 'EXPIRED'
  const { isBackOffice } = useConfig()

  const isFormBilled = isAnzForm(formData)
    ? appointmentData?.status === 'BILLED'
    : false

  const isFormArrived = isAnzForm(formData)
    ? appointmentData?.status === 'ARRIVED'
    : false

  return { isFormExpired, isBackOffice, isFormBilled, isFormArrived }
}

export type IntakeFormStep = typeof steps[number]
