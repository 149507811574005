import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { RelativesModal } from '../RelativesModal'
import { useFormContext, useWatch } from 'react-hook-form'
import { Checkbox } from '../../../components/Checkbox'
import { Typography } from '../../../components/Typography'
import { useFormStatus } from '../../../utils/routes'

interface HealthConditionProps {
  index: number
  name: string
}

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
`

const StyledRow = styled.div`
  display: flex;
  width: 100%;

  @media screen and (min-width: 675px) {
    width: calc((100% - 2rem) / 2);
  }
`

const StyledCell = styled.div`
  display: inline-flex;
  padding: 10px;
  text-align: left;
  align-items: center;
`

const ConditionWrapper = styled(StyledCell)`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`

const CheckboxWrapper = styled(StyledCell)`
  width: 4em;
  flex-shrink: 0;

  label {
    margin: 0;
  }
`

const StyledModalTrigger = styled(Typography)`
  cursor: pointer;
`

export const HealthCondition: React.FC<HealthConditionProps> = ({
  index,
  name,
}) => {
  const { t } = useTranslation()
  const { isFormExpired } = useFormStatus()
  const baseName = `healthConditions[${index}]`
  const [isRelativesModalVisible, setIsRelativesModalVisible] = useState(false)
  const { register, control } = useFormContext()
  const isRelatives: boolean =
    useWatch({
      control,
      name: `${baseName}.isRelatives`,
    }) || false
  const relatives: string[] =
    useWatch({
      control,
      name: `${baseName}.relatives`,
    }) || []

  useEffect(() => {
    document.body.classList.toggle(
      'intake__modal-body-open',
      isRelativesModalVisible
    )
  }, [isRelativesModalVisible])

  return (
    <StyledRow>
      <ConditionWrapper>
        <Wrapper>
          <Typography $noMargin $transform="uppercase" $align="left">
            {t(`healthConditions.${name}`)}
          </Typography>
          <RelativesModal
            isOpen={isRelativesModalVisible}
            name={name}
            baseName={baseName}
            onConfirm={() => setIsRelativesModalVisible(false)}
            onCancel={() => setIsRelativesModalVisible(false)}
          />
          {isRelatives && (
            <>
              <StyledModalTrigger
                $noMargin
                $color="primary"
                $align="left"
                $weight="bold"
                onClick={() => setIsRelativesModalVisible(true)}
                role="button"
                tabIndex={0}
              >
                {t('healthHistory.whichRelatives')}
              </StyledModalTrigger>

              <Typography $noMargin $align="left">
                {relatives
                  ?.map((relative) => t(`relatives.${relative.toLowerCase()}`))
                  .join(', ')}
              </Typography>
            </>
          )}
        </Wrapper>
      </ConditionWrapper>

      <CheckboxWrapper>
        <Checkbox
          name={`${baseName}.isSelf`}
          ref={register}
          disabled={isFormExpired}
        />
      </CheckboxWrapper>

      <CheckboxWrapper>
        <Checkbox
          name={`${baseName}.isRelatives`}
          ref={register}
          disabled={isFormExpired}
        />
      </CheckboxWrapper>

      <input
        type="hidden"
        value={name}
        name={`${baseName}.conditionName`}
        ref={register}
        disabled={isFormExpired}
      />
    </StyledRow>
  )
}
