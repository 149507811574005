// #region ::: IMPORTS
import { Fragment, useCallback, useRef, useState } from 'react'
// LIBRARIES
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'

// UTILS
import { useFormComplete } from '@hooks/useFormUpdate'
import { DynamicAnzFormProvider } from '@root/services/DynamicAnzFormProvider'
import { confirmation, intro, steps, useFormStatus } from '@root/utils/routes'

// COMPONENTS
import { Button } from '@components/Button'
import { Loader } from '@components/Loader'
import { Modal } from '@components/Modal'
import { Typography } from '@components/Typography'
import { ReviewPage } from '../ReviewPage'
import JSONSchemaForm from './JSONSchemaForm'

//TYPES
import type { AnzForm as AnzFormType } from '@api'
import { AppointmentRecap } from '@components/AppointmentRecap'
import { Layout } from '@components/Layout'
import { StepLayout } from '@components/StepLayout'
import useWindowSizeDebounced from '@hooks/useWindowSizeDebounced'
import { useTabApiForm } from '@root/services/TabApiProvider/hooks/useTabApiForm'
import { useTabApiJsonSchema } from '@root/services/TabApiProvider/ProviderDynamicForm'
import { useFormMode } from '@hooks/useFormMode'

// #endregion

const StyledContainer = styled.div`
  padding: 3rem 2.25rem;
`

const TwoColumns = styled.div`
  position: relative;
  width: 100%;  

  & > * + * {
    margin-bottom: 40px;
  }
`

const StyledFormErrorWrapper = styled.div`
  span:first-child {
    margin-right: 0.5rem;
  }
`

export const AnzForm = () => {
  const { t } = useTranslation()
  const history = useHistory<{ step?: number }>()
  const { isFormBilled, isFormExpired, isFormArrived } = useFormStatus()
  const { isReadOnly } = useFormMode()

  const [atStep, setAtStep] = useState<number>(0)
  const [isAtReview, setIsAtReview] = useState<boolean>(false)
  const [hasValidationErrors, setHasValidationErrors] = useState<boolean>(false)
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState<boolean>(false)

  const windowSize = useWindowSizeDebounced()
  const stepLayoutRef = useRef<HTMLDivElement>(null)
  const stepLayoutHeight = stepLayoutRef.current?.getBoundingClientRect().height ?? 0

  const { data, isPending: isPendingJsonSchema } = useTabApiJsonSchema()
  const {
    data: formData,
    run,
    isPending: isPendingForm,
  } = useTabApiForm<AnzFormType>()
  const handleFormComplete = useFormComplete(formData, 'COMPLETED', run)

  const { questionAnswer, ...formInfo } = formData || {}
  if ('step' in formInfo && !!steps[atStep]) {
    formInfo.step = steps[atStep]
  }

  const stepsLength = data?.length || 0

  const [localFormData, setLocalFormData] = useState<
    AnzFormType['questionAnswer']
  >(questionAnswer || {})

  const uiSchema = { ...data?.[atStep].uiSchema, 'ui:hideError': true }

  const isUnauthorized =
    localFormData?.populateForMyself === 'Someone else' &&
    localFormData?.imAuthorized === 'No'

  const removeAuthorization = () =>
    setLocalFormData((formData) => ({
      ...formData,
      imAuthorized: undefined,
    }))

  const getSubmitButtonLabel = (): string => {
    if (isAtReview) {
      return 'core.saveChanges'
    } else {
      return atStep === stepsLength - 1 ? 'core.reviewAndSubmit' : 'core.continue'
    }
  }

  const handleSteps = (shouldGoToNextStep: boolean) => {
    const newStep =
      atStep +
      (shouldGoToNextStep ? (atStep + 1 === stepsLength ? 0 : 1) : atStep === 0 ? 0 : -1)
    setAtStep(newStep)

    // Go to review if needed
    if (shouldGoToNextStep && atStep + 1 === stepsLength) {
      setIsAtReview(true)
    }
  }

  const handleSubmitToServer = async () => {
    if (isAtReview) {
      if (!isReadOnly) {
        await handleFormComplete()
      }
      
      history.push(confirmation())
    }
  }

  const handleBackClick = () => {
    if (atStep === 0) {
      history.push(intro())
    } else {
      isAtReview ? setIsAtReview(false) : handleSteps(false)
    }
  }

  const goToStep = (step: number) => {
    if (data) {
      setAtStep(step)
      setIsAtReview(false)
    }
  }

  const handleValidation = useCallback((hasErrors: boolean) => {
    if (hasValidationErrors != hasErrors) {
      setHasValidationErrors(hasErrors)
    }
  }, [hasValidationErrors, setHasValidationErrors])

  const handleSubmit = (hasErrors: boolean) => {
    handleValidation(hasErrors)

    if (hasErrors) {
      setHasTriedToSubmit(true)
    }
    else {
      setHasTriedToSubmit(false)
      handleSteps(true)
    }
  }

  const isFormBiggerThanWindow = stepLayoutHeight > windowSize.height - 150

  return (
    <DynamicAnzFormProvider goToStep={goToStep}>
      <Layout>
        <Layout.Header
          showContinueLaterButton
          continueLaterDataElementId="X_OEE_Review_Continue-Later"
          showCompletitionTracker
          completitionTracketValue={stepsLength === 0 ? 0 : ((atStep + 1) / stepsLength) * 10}
        />

        {/*
            <AnzHeader
            completionValue={steps === 0 ? 0 : ((atStep + 1) / steps) * 10}
            saveContinueLater={() => console.log('save and continue later')}
            />
        */}

        <Layout.Content>
          <TwoColumns>
            {isAtReview ? (
              <ReviewPage />
            ) : (
              <StepLayout ref={stepLayoutRef}>
                {isPendingJsonSchema || isPendingForm ? (
                  <Loader height={200} />
                ) : null}
                {data && !isPendingForm ? (
                  <JSONSchemaForm
                    disabled={isFormBilled || isFormArrived || isFormExpired}
                    jsonSchema={data[atStep].jsonSchema}
                    uiSchema={uiSchema}
                    formData={localFormData}
                    formInfo={formInfo as Omit<AnzFormType, 'questionAnswer'>}
                    setFormData={setLocalFormData}
                    onChange={handleValidation}
                    onSubmit={handleSubmit}
                  />
                ) : null}
              </StepLayout>
            )}
          </TwoColumns>

          <AppointmentRecap additionalTop={51} />
        </Layout.Content>

        <Layout.Footer>
          <Button onClick={handleBackClick} $outlined $compact>
            {t('core.back')}
          </Button>

          <StyledFormErrorWrapper as={windowSize.width > 700 ? "div" : Fragment}>
            {hasValidationErrors && hasTriedToSubmit && isFormBiggerThanWindow && (
              <Typography $color="error" $variant="span" $weight="bold">
                {t('core.incorrectForm')}
              </Typography>
            )}
            <Button
              type="submit"
              form="theForm"
              disabled={isPendingJsonSchema || isPendingForm}
              $compact
              onClick={handleSubmitToServer}
            >
              {t(getSubmitButtonLabel())}
            </Button>
          </StyledFormErrorWrapper>
        </Layout.Footer>
      </Layout>

      <Modal
        isOpen={isUnauthorized}
        showCloseButton
        onRequestClose={removeAuthorization}
      >
        <Modal.Content>
          <StyledContainer>
            <Typography $variant="h1">
              {t('anz.sessionUnauthorized')}
            </Typography>
          </StyledContainer>
        </Modal.Content>
      </Modal>
    </DynamicAnzFormProvider>
  )
}
