import React from 'react'
import { ProviderConfigs } from './ProviderConfigs'
import { ProviderAppointment } from './ProviderAppointment'
import { ProviderForm } from './ProviderForm'
import { ProviderAnzForm } from './ProviderAnzForm'
import { ProviderDynamicForm } from './ProviderDynamicForm'
import useIsAnz from '@hooks/useIsAnz'

export const TabApiProvider: React.FC = ({ children }) => (
  <ProviderConfigs>
    <FormProviderComponent>{children}</FormProviderComponent>
  </ProviderConfigs>
)

const FormProviderComponent: React.FC = ({ children }) => {
  const isAnz = useIsAnz()

  if (isAnz) {
    return (
      <ProviderAnzForm>
        <ProviderDynamicForm>
          <ProviderAppointment>{children}</ProviderAppointment>
        </ProviderDynamicForm>
      </ProviderAnzForm>
    )
  }

  return (
    <ProviderForm>
      <ProviderAppointment>{children}</ProviderAppointment>
    </ProviderForm>
  )
}
