import { isJwtValid } from '@root/api/core/request'
import useIsAnz from './useIsAnz'
import useJwt from './useJwt'

type IsAnzAuthenticated = () => {
  isAuthenticated: boolean
}

const useIsAnzAuthenticated: IsAnzAuthenticated = () => {
  const isAnz = useIsAnz()
  const { jwt } = useJwt()
  
  const isAuthenticated = isAnz && isJwtValid(jwt)

  return {
    isAuthenticated,
  }
}

export default useIsAnzAuthenticated
