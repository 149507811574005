import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { Modal, ModalProps } from '@components/Modal'
import { LegalDocument } from '../LegalDocument'
import { DocumentType } from '@api'

interface DocumentModalProps extends ModalProps {
  onRequestClose?: () => void
  docType: DocumentType
}

const HIGHEST_Z_INDEX = 9999

const ModalStyles = createGlobalStyle`
    .intake__modal-content.intake__documentmodal {
        position: absolute;
        top: 0;
        left: unset;
        right: 0;
        height: 100vh;
        max-height: unset;
        max-width: unset;
        min-width: unset;
        min-height: unset;
        transform: unset;
        background-color: #fff;
        overflow: hidden auto;
        outline: none;
        padding: 0;
        width: 100%;

        ${({ theme }) => theme.breakpoints.media.large} {
            width: 476px;
        }
    }

    #intake__modal-description {
        height: 100%;
        font: inherit;
        text-align: center;
    }

    .intake__modal:has(div#document-modal) {
        position: fixed;
        // This value is needed when the modal is open in Connect
        // cause the modal is rendered outside the root element of the app
        // and inside Connect we have an element with z-index: 9999
        z-index: ${HIGHEST_Z_INDEX + 1};
    }
`

export const DocumentModal: React.FC<DocumentModalProps> = ({
  docType,
  ...props
}) => (
  <>
    <ModalStyles />
    <Modal
      id="document-modal"
      className="intake__documentmodal"
      ariaHideApp={true}
      {...props}
    >
      <Modal.Content>
        <LegalDocument backAction={props.onRequestClose} docType={docType} />
      </Modal.Content>
    </Modal>
  </>
)
