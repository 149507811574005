import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import {
  isStepCompleted,
  isStepDisabled,
  useFormStatus,
  useStep,
  TStatusStep,
} from '../../../utils/routes'
import { Button } from '../../../components/Button'
import { Typography } from '../../../components/Typography'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import { Loader } from '../../../components/Loader'
import { Form } from '@api'

const StyledSection = styled.section`
  border-radius: 10px;
  background-color: white;
  padding: 35px;
  position: relative;
  width: 100%;
  overflow: hidden auto;

  & h1 {
    margin-top: 0;
  }

  &:not(:first-of-type) {
    margin-top: 55px;
  }
`

const TypographyWrapper = styled.div`
  display: flex;
  align-items: baseline;

  p {
    font-weight: bold;
    font-size: 14px;
  }

  span {
    margin-left: 6px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      display: block;

      span {
        margin-left: 0px;
      }
    }
  `}
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  &:not(:first-of-type) {
    margin-top: 35px;
  }

  p {
    margin: 0;
  }

  span {
    font-size: 13px;
    letter-spacing: 0.41px;
  }

  button {
    margin-top: 15px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      flex-direction: row;
      align-items: center;

      button {
        margin-top: 0px;
      }
    }
  `}
`

export interface OtherRecapProps {}

export const OtherRecap: React.FC<OtherRecapProps> = () => {
  const { push } = useStep()
  const { data, isLoading: isLoadingForm } = useTabApiForm<Form>()
  const { t } = useTranslation()
  const { isFormExpired } = useFormStatus()
  const step = data?.step

  if (isLoadingForm) return <Loader />

  const getButtonLabel = (step: TStatusStep, apiStep: TStatusStep): string =>
    isFormExpired ? 'core.view' : 'core.viewAndEdit'

  return step ? (
    <StyledSection id="other-recap">
      <StyledDiv>
        <TypographyWrapper>
          <Typography $variant="p" $align="left" $transform="uppercase">
            {t('core.healthHistory')}
          </Typography>
          <Typography $variant="span" $align="left">
            {t(
              isStepCompleted('3_HEALTH_HISTORY', step)
                ? 'reviewSubmission.insuranceInfo.stepCompleted'
                : 'reviewSubmission.insuranceInfo.stepToBeCompleted'
            )}
          </Typography>
        </TypographyWrapper>

        <Button
          $outlined
          onClick={() => {
            push('3_HEALTH_HISTORY')
          }}
          data-element-id="X_OEE_Review_View&Edit"
          disabled={!isFormExpired && isStepDisabled('3_HEALTH_HISTORY', step)}
        >
          {t(getButtonLabel('3_HEALTH_HISTORY', step))}
        </Button>
      </StyledDiv>

      <StyledDiv>
        <TypographyWrapper>
          <Typography $variant="p" $align="left" $transform="uppercase">
            {t('core.visionHistory')}
          </Typography>
          <Typography $variant="span" $align="left">
            {t(
              isStepCompleted('4_VISION_HISTORY', step)
                ? 'reviewSubmission.insuranceInfo.stepCompleted'
                : 'reviewSubmission.insuranceInfo.stepToBeCompleted'
            )}
          </Typography>
        </TypographyWrapper>

        <Button
          $outlined
          onClick={() => {
            push('4_VISION_HISTORY')
          }}
          data-element-id="X_OEE_Review_View&Edit"
          disabled={!isFormExpired && isStepDisabled('4_VISION_HISTORY', step)}
        >
          {t(getButtonLabel('4_VISION_HISTORY', step))}
        </Button>
      </StyledDiv>

      <StyledDiv>
        <TypographyWrapper>
          <Typography $variant="p" $align="left" $transform="uppercase">
            {t('core.patientBackground')}
          </Typography>
          <Typography $variant="span" $align="left">
            {t(
              isStepCompleted('5_PATIENT_BACKGROUND', step)
                ? 'reviewSubmission.insuranceInfo.stepCompleted'
                : 'reviewSubmission.insuranceInfo.stepToBeCompleted'
            )}
          </Typography>
        </TypographyWrapper>

        <Button
          $outlined
          onClick={() => push('5_PATIENT_BACKGROUND')}
          data-element-id="X_OEE_Review_View&Edit"
          disabled={
            !isFormExpired && isStepDisabled('5_PATIENT_BACKGROUND', step)
          }
        >
          {t(getButtonLabel('5_PATIENT_BACKGROUND', step))}
        </Button>
      </StyledDiv>
    </StyledSection>
  ) : null
}
