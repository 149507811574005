import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { useFormStatus, useStep } from '../../../utils/routes'

import useIsAnz from '@hooks/useIsAnz'
import { useConfig } from '@root/Context'
import { useDynamicAnzForm } from '@root/services/DynamicAnzFormProvider'
import {
  flatFormObjectIfAnz,
  useTabApiForm,
} from '@services/TabApiProvider/hooks/useTabApiForm'
import { format, parse } from 'date-fns'
import React from 'react'
import { Button } from '../../../components/Button'
import { Loader } from '../../../components/Loader'
import { Typography } from '../../../components/Typography'
import { isoStringToDateString } from '../../../utils/date'
import * as Styles from './PersonalInfoRecap.styles'
import { useTabApiAppointment } from '@root/services/TabApiProvider/ProviderAppointment'

const DATE_FORMAT = 'dd/MM/yyyy'

const parseStringDate = (dateString: string) => {
  let formattedDate

  try {
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date())
    formattedDate = format(parsedDate, DATE_FORMAT)
    return formattedDate
  } catch (error) {
    formattedDate = ''
  }

  // fallback parse
  try {
    const parsedDate = parse(dateString, 'MM/dd/yyyy', new Date())
    formattedDate = format(parsedDate, DATE_FORMAT)
    return formattedDate
  } catch (error) {
    formattedDate = ''
  }

  return formattedDate
}

export interface PersonalInfoRecapProps { }

const EditButton = (props: React.ComponentPropsWithRef<typeof Button>) => (
  <Button $outlined {...props} />
)

const StyledEditButton = styled(EditButton)`
  margin-top: 15px;
  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      margin-top: 0;
      position: absolute;
      top: 29px;
      right: 41px;
    }
  `}
`

export const PersonalInfoRecap: React.FC<PersonalInfoRecapProps> = () => {
  const isAnz = useIsAnz()
  const { push } = useStep()
  const { brand } = useConfig()
  const { t } = useTranslation()
  const { isFormExpired } = useFormStatus()
  const { goToStep } = useDynamicAnzForm()
  const { data: formData, isLoading: isLoadingForm } = useTabApiForm()
  const data = flatFormObjectIfAnz(formData)
  const { data: appData, isLoading: isLoadingAppointment } = useTabApiAppointment()

  const dob = appData?.patient?.dob
  const step = data?.step
  const cellPhone = data && 'cellPhone' in data ? data.cellPhone : null
  const homePhone = data && 'homePhone' in data ? data.homePhone : null

  if (isLoadingForm) return <Loader height={100} />

  return step ? (
    <Styles.StyledSection storeName={brand} id="personal-info-recap">
      <Typography $variant="h1" $align="left">
        {t('reviewSubmission.infoSection.title')}
      </Typography>

      <Styles.StyledTest>
        <Styles.TypographyWrapper>
          <Typography>{t('core.firstName')}:</Typography>{' '}
          <Typography>{data?.firstName}</Typography>
        </Styles.TypographyWrapper>

        <Styles.TypographyWrapper>
          <Typography>{t('core.address')}:</Typography>{' '}
          <Typography>{data?.address}</Typography>
        </Styles.TypographyWrapper>

        <Styles.TypographyWrapper>
          <Typography>{t('core.lastName')}:</Typography>{' '}
          <Typography>{data?.lastName}</Typography>
        </Styles.TypographyWrapper>

        {isAnz ? (
          <>
            {homePhone && (
              <Styles.TypographyWrapper>
                <Typography>{t('core.homePhone')}:</Typography>{' '}
                <Typography>{homePhone}</Typography>
              </Styles.TypographyWrapper>)
            }
          </>
        ) : (
          <Styles.TypographyWrapper>
            <Typography>{t('core.phone')}:</Typography>{' '}
            <Typography>{data?.phone}</Typography>
          </Styles.TypographyWrapper>
        )}

        <Styles.TypographyWrapper>
          <Typography>{t(`core.${isAnz ? 'dateOfBirth' : 'dob'}`)}:</Typography>{' '}
          <Typography>
            {isAnz && dob
              ? parseStringDate(dob)
              : isoStringToDateString({
                isoString: dob,
              })}
          </Typography>
        </Styles.TypographyWrapper>

        {isAnz && cellPhone && (
          <Styles.TypographyWrapper>
            <Typography>{t('core.mobilePhone')}:</Typography>{' '}
            <Typography>{cellPhone}</Typography>
          </Styles.TypographyWrapper>
        )}

        <Styles.TypographyWrapper>
          <Typography>{t('core.email')}:</Typography>{' '}
          <Typography>{data?.email}</Typography>
        </Styles.TypographyWrapper>
      </Styles.StyledTest>

      <StyledEditButton
        onClick={() => {
          if (isAnz) {
            goToStep(0)
          } else {
            push('1_CONTACT_INFO')
          }
        }}
        data-element-id="X_OEE_Basics_Edit"
      >
        {t(isFormExpired ? 'core.view' : 'core.edit')}
      </StyledEditButton>
    </Styles.StyledSection>
  ) : null
}
