import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { useCommonConfigs } from '../../../hooks/useCommonConfigs'
import { twoColumnsStyle } from '../../../components/Layout/TwoColumns'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Checkbox'
import { ModalProps, Modal } from '../../../components/Modal'
import { useFormStatus } from '../../../utils/routes'

export interface RelativesModalProps extends ModalProps {
  name: string
  baseName: string
  onConfirm: () => void
  onCancel: () => void
}

const StyledModalContent = styled(Modal.Content)`
  text-align: left;

  ${twoColumnsStyle}
`

const StyledModalActions = styled(Modal.Actions)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const RelativesModal: React.FC<RelativesModalProps> = ({
  name,
  baseName,
  onConfirm,
  onCancel,
  isOpen,
  style,
  ...props
}) => {
  const { t } = useTranslation()
  const { getValues, register, reset } = useFormContext()
  const { isFormExpired } = useFormStatus()
  const commonConfigs = useCommonConfigs()
  const formState = useRef(getValues())
  const checkboxName = `${baseName}.relatives`

  useEffect(() => {
    if (isOpen) {
      formState.current = getValues()
    }
  }, [getValues, isOpen])

  if (commonConfigs) {
    return (
      <Modal
        {...props}
        id="relatives-modal"
        isOpen
        bodyOpenClassName="intake__modal-body-hidden"
        style={{
          overlay: {
            display: isOpen ? 'block' : 'none',
          },
          content: {
            display: isOpen ? 'block' : 'none',
          },
        }}
      >
        <Modal.Title>
          {t('relativesModal.title', {
            healthCondition: t(`healthConditions.${name}`),
          })}
        </Modal.Title>

        <StyledModalContent>
          {commonConfigs.relativesHealthConditions?.map(
            ({ label, value }, index) => {
              return (
                <Checkbox
                  name={checkboxName}
                  value={value}
                  key={`relatives-${label}-${index}`}
                  label={t(`relatives.${label}`)}
                  tooltip={t(`relatives.${label}Tooltip`, {
                    defaultValue: '',
                  })}
                  ref={register}
                  disabled={isFormExpired}
                />
              )
            }
          )}
        </StyledModalContent>

        <StyledModalActions>
          <Button
            $outlined
            onClick={() => {
              reset(formState.current)
              onCancel()
            }}
          >
            {t('core.close')}
          </Button>

          <Button onClick={onConfirm}>{t('core.confirm')}</Button>
        </StyledModalActions>
      </Modal>
    )
  }

  return null
}
