import { useMemo } from 'react'
import { useAvailableDocuments } from '@services/tabApi'
import { DocumentType } from '../api/models/DocumentType'
import { DocumentVisibilities } from '../api/models/DocumentVisibilities'
import { DocumentVisibility } from '../api/models/DocumentVisibility'

export const useVisibleDocuments = (): Record<
  DocumentType,
  DocumentVisibility
> => {
  const { data } = useAvailableDocuments()

  const convertArrayToObject = (array: DocumentVisibilities, key: string) => {
    const initialValue = {}

    return array.reduce((obj: any, item) => {
      return {
        [item.documentType!.toLowerCase()]: item,
        ...obj,
      }
    }, initialValue)
  }

  return useMemo(() => {
    if (data) {
      return convertArrayToObject(data, 'documentType')
    }
  }, [data])
}
