import { MemoryRouter, Redirect, Route, Switch } from 'react-router'
import React, { Suspense } from 'react'
import { ThemeProvider } from 'styled-components'
import { I18nextProvider } from 'react-i18next'
import {
  anzForm,
  anzIntro,
  confirmation,
  continueLater,
  intro,
  legal,
  step,
} from './utils/routes'

import { Config } from './types/config'
import { ConfigContext } from './Context'
import { Confirmation } from './routes/Confirmation'
import { ContinueLater } from './routes/ContinueLater'
import { ErrorBoundary } from './components/ErrorBoundary'
import { FormSteps } from './routes/FormSteps'
import { GlobalErrorModal } from './components/GlobalErrorModal'
import { Intro } from './routes/Intro/Intro'
import { LegalDocument } from './routes/LegalDocument'
import { Loader } from './components/Loader'
import { OpenAPI } from './api'
import { ScrollToTop } from './components/ScrollToTop'
import { AnzForm } from './anz'
import { TabApiProvider } from './services/TabApiProvider'
import { getFontsBasedOnStore, getThemeBasedOnStore } from './theme'
import { useI18nInstance } from './i18n'
import * as OPSM from './routes/OPSM'
import ProtectedRouteIfAnz from './routes/ProtectedRouteIfAnz'
import useIsAnz from '@hooks/useIsAnz'

export interface IntakeFormProps {
  config: Config
}

export const IntakeForm: React.FC<IntakeFormProps> = ({ config }) => {
  const i18nInstance = useI18nInstance(config.langUrl, config.lang)

  const isAnz = useIsAnz(config.country)

  OpenAPI.BASE =
    (isAnz
      ? process.env.REACT_APP_TAB_API_URL_ANZ?.replace(/\/$/, '')
      : process.env.REACT_APP_TAB_API_URL?.replace(/\/$/, '')) || OpenAPI.BASE

  return (
    <ThemeProvider theme={getThemeBasedOnStore(config.brand)}>
      <ErrorBoundary>
        {getFontsBasedOnStore(config.brand)}
        <Suspense fallback={<Loader height={200} />}>
          <I18nextProvider i18n={i18nInstance}>
            <ConfigContext.Provider value={config}>
              <MemoryRouter>
                <ScrollToTop />
                {isAnz ? (
                  <Switch>
                    <Route exact path={intro()} component={OPSM.OTPPage} />
                    <TabApiProvider>
                      <GlobalErrorModal />
                      <ProtectedRouteIfAnz
                        exact
                        path={anzIntro()}
                        component={Intro}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={legal()}
                        component={LegalDocument}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={anzForm()}
                        component={AnzForm}
                      />

                      <ProtectedRouteIfAnz
                        exact
                        path={continueLater()}
                        component={ContinueLater}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={confirmation()}
                        component={OPSM.Confirmation}
                      />
                    </TabApiProvider>
                  </Switch>
                ) : (
                  <Switch>
                    <TabApiProvider>
                      <GlobalErrorModal />
                      <Route exact path={intro()} component={Intro} />
                      <Route exact path={legal()} component={LegalDocument} />
                      <Route path={step()} component={FormSteps} />
                      <Route
                        exact
                        path={continueLater()}
                        component={ContinueLater}
                      />
                      <Route
                        exact
                        path={confirmation()}
                        component={Confirmation}
                      />
                    </TabApiProvider>
                    <Redirect to={intro()} />
                  </Switch>
                )}
              </MemoryRouter>
            </ConfigContext.Provider>
          </I18nextProvider>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
