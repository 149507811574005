import { formConstructor } from '@root/anz/types/anzTypes'
import { createContext, FC, useCallback, useContext, useMemo } from 'react'
import { useAsync } from 'react-async'
import { AnzForm, DynamicFormService } from '../../api'
import { useConfig } from '../../Context'
import { useTabApiForm } from './hooks/useTabApiForm'

// #region ::: PARTIALS
// HOOK
export const useTabApiJsonSchema = () => useContext(TabApiDynamicFormContext)

// CONTEXT
export const TabApiDynamicFormContext = createContext(
  {} as ContextJSONSchemaValueType
)

// TYPES
const useAsyncWithDynamicForm = () => useAsync<formConstructor[]>({})
type ContextJSONSchemaValueType = ReturnType<typeof useAsyncWithDynamicForm>

// Promise risolver
const mockPromiseRisolver = () => []
// #endregion

export const ProviderDynamicForm: FC = ({ children }) => {
  const {
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
    country,
  } = useConfig()

  const { data } = useTabApiForm<AnzForm>()

  const schemaVersion = useMemo(() => data?.formSchemaVersion, [data])

  const getJSONSchema = useCallback(
    () =>
      schemaVersion
        ? DynamicFormService.getJSONSchema({
            caller,
            ocpApimSubscriptionKey,
            // @ts-ignore
            acceptedLanguage: `en-${country || 'AU'}`,
            schemaVersion: schemaVersion,
          })
        : new Promise<formConstructor[]>(mockPromiseRisolver),
    [caller, country, ocpApimSubscriptionKey, schemaVersion]
  )

  const tabApiDynamicFormContextValue = useAsync<formConstructor[]>({
    promiseFn: getJSONSchema,
  })

  return (
    <TabApiDynamicFormContext.Provider value={tabApiDynamicFormContextValue}>
      {children}
    </TabApiDynamicFormContext.Provider>
  )
}
