import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Modal, ModalProps } from '../Modal'
import { Typography } from '../Typography'
import { Button } from '../Button'
import FrownIcon from '../../assets/icons/frown.svg'
import { Icon } from '../Icon'
import useIsAnz from '@hooks/useIsAnz'
import { SessionExpired } from '@root/routes/OPSM'
import { useConfig } from '@root/Context'

export interface ErrorModalProps extends ModalProps {
  errorMessage?: string
  showBackToTheFormBtn?: boolean
  error?: Error | undefined
}

export interface ErrorModalContentProps
  extends React.ComponentPropsWithoutRef<'div'> {
  errorMessage?: string
  showBackToTheFormBtn?: boolean
  onRequestClose?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}

export const Divider = styled.div`
  width: 90%;
  border: solid 0.6px #bdbdbd;
  margin: auto;
  margin-top: 24px;
`

export const StyledFrownIcon = styled(Icon).attrs({
  src: FrownIcon,
  className: 'intake__icon-frown',
})`
  margin-bottom: 30px;
`

export const StyledSecurityInfo = styled(Typography)`
  font-size: 12px;
  font-weight: 200;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #777777;
`
export const StyledNeedHelp = styled(Typography)`
  font-size: 14px;
  font-weight: 200;
`
export const StyledBackToTheForm = styled(Button)`
  margin: 40px 0;
`

export const StyledModalTitle = styled(Modal.Title)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ErrorModalContent: React.FC<ErrorModalContentProps> = ({
  errorMessage,
  onRequestClose,
  className,
  showBackToTheFormBtn = true,
}) => {
  const { t } = useTranslation()
  const { brandWebsite } = useConfig()

  return (
    <div className={className}>
      <StyledModalTitle>
        <StyledFrownIcon />
        {t('errorModal.title')}
      </StyledModalTitle>

      <Modal.Content>
        {errorMessage && <Typography>{errorMessage}</Typography>}

        {showBackToTheFormBtn && (
          <StyledBackToTheForm onClick={onRequestClose}>
            {t('errorModal.backToTheForm')}
          </StyledBackToTheForm>
        )}

        <StyledNeedHelp>
          {t('errorModal.helpText')}
          <a
            href={`${brandWebsite}find-store`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('errorModal.storeLocator')}
          </a>
          .
        </StyledNeedHelp>
        <Divider />

        <StyledSecurityInfo>{t('errorModal.securityInfo')}</StyledSecurityInfo>
      </Modal.Content>
    </div>
  )
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  errorMessage,
  onRequestClose,
  error,
  showBackToTheFormBtn,
  ...props
}) => {
  const isAnz = useIsAnz()

  if (isAnz && error?.message === 'Unauthorized') {
    return <SessionExpired type="session" />
  }

  if (isAnz && error?.message === 'Forbidden') {
    return <SessionExpired type="appointment" />
  }

  return (
    <Modal
      {...props}
      id="error-modal"
      showCloseButton
      style={{
        overlay: {
          zIndex: 3,
        },
      }}
      onRequestClose={onRequestClose}
    >
      <ErrorModalContent
        errorMessage={errorMessage}
        showBackToTheFormBtn={showBackToTheFormBtn}
        onRequestClose={onRequestClose}
      />
    </Modal>
  )
}
