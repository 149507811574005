import React from 'react'
import { useTranslation } from 'react-i18next'
import { Props as ReactModalProps } from 'react-modal'
import styled, { css, useTheme } from 'styled-components'
import { useHistory, useParams } from 'react-router'
import { useMedia } from 'react-use'

import { Layout } from '@components/Layout'
import { Button } from '@components/Button'
import { Signature } from '@components/Signature'
import { Typography } from '@components/Typography'
import { Icon } from '@components/Icon'
import ArrowIcon from '@assets/icons/arrow.svg'
import {
  flatFormObjectIfAnz,
  useTabApiForm,
} from '@services/TabApiProvider/hooks/useTabApiForm'
import { useLegalDocument } from '@services/tabApi'
import { htmlToReactComponent } from '@utils/htmlToReact'
import { intro } from '@utils/routes'
import { Loader } from '@components/Loader'
import usePageClassname from '@hooks/usePageClassname'
import { DocumentType } from '@api'
import { useTabApiAppointment } from '../services/TabApiProvider/ProviderAppointment'
import useIsAnz from '@hooks/useIsAnz'
// import { useConfig } from '@root/Context'

interface LegalDocumentProps {
  backAction: ReactModalProps['onRequestClose']
  docType: DocumentType
}

interface BackButtonProps {
  isDesktop: boolean
}

const BackButton = styled(Button)<BackButtonProps>`
  background-color: transparent;
  border-color: transparent;
  color: ${({ theme: { colors } }) => colors.darkShade};
  display: flex;
  align-items: center;
  max-width: 100%;
  ${({ isDesktop }) => !isDesktop && 'padding: 0'};

  &&&:not(disabled):hover,
  &&&:not(disabled):focus {
    box-shadow: unset;
  }
`

const BackButtonIcon = styled(Icon).attrs({
  src: ArrowIcon,
  className: 'intake__icon--arrow',
})`
  width: 25px;
  height: 25px;
  transform: rotate(-90deg);
`

const LayoutContent = styled(Layout.Content)`
  flex-direction: column !important;
  align-items: center;
`

const DocumentWrapper = styled.div`
  background-color: white;
  width: 100%;
  margin-top: 0 !important;

  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      width: auto;
    }
  `}
`

const DocumentContent = styled(Typography).attrs({
  as: 'div',
})`
  margin: auto;
  width: calc(100% - 30px);
  max-width: 1022px;
  padding: 10px;
  text-align: left;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      padding: 30px;
    }
  `}

  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      max-width: 450px;
      overflow: hidden;
    }
  `}

  iframe {
    width: 100%;
    height: 375px;
  }
`

export const LegalDocument: React.FC<LegalDocumentProps> = ({
  backAction,
  docType,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ docType: DocumentType }>()
  const isAnz = useIsAnz()
  // const { brandName } = useConfig()
  const { data: formDataHook, run } = useTabApiForm()
  const formData = flatFormObjectIfAnz(formDataHook)
  const { data: documentData, isLoading: isLoadingDocument } = useLegalDocument(
    params.docType || docType!,
    formData?.id
  )
  const { data: appointmentData } = useTabApiAppointment()
  const {
    breakpoints: { device },
  } = useTheme()
  const isDesktop = useMedia(device.large)

  const doc = params.docType || docType
  const hasLogo = !isDesktop || !!backAction
  const isDocumentPdf =
    documentData && documentData.content && documentData?.contentType === 'pdf'

  const handleSignatureSubmission = async ({
    signature,
    signatureType,
    hasAccepted,
    event,
  }: {
    signature: string
    signatureType: 'DRAW' | 'TEXT'
    hasAccepted: boolean
    event: React.MouseEvent
  }) => {
    const date = new Date().toISOString()

    if (
      params.docType === DocumentType.HEALTH ||
      docType === DocumentType.HEALTH
    ) {
      await run({
        ...formData,
        phone: formData?.phone?.length === 0 ? undefined : formData?.phone,
        optHealthInformation: {
          hasAccepted,
          signature,
          signatureType,
          date,
        },
      })
    } else if (
      params.docType === DocumentType.PRIVACY ||
      docType === DocumentType.PRIVACY
    ) {
      if (isAnz) {
        await run({
            hasAccepted,
            signature,
            signatureType,
            date,
          }, true)
      }
      else {
        await run({
          ...formData,
          phone: formData?.phone?.length === 0 ? undefined : formData?.phone,
          optPrivacyPractice: {
            hasAccepted,
            signature,
            signatureType,
            date,
          },
        })
      }
    }

    backAction ? backAction(event) : history.push(intro())
  }

  const backButtonHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => (backAction ? backAction(e) : history.push(intro()))

  const onRenderDocumentPdf = (): JSX.Element | null => {
    if (documentData && documentData?.content) {
      return (
        <>
          <iframe
            src={`${documentData.content}#toolbar=0`}
            title="privacy-practice"
          ></iframe>
          <a
            className="intake__pdf-link"
            href={documentData.content}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('core.openInNewTab')}
          </a>
        </>
      )
    } else return null
  }

  const onRenderDocumentText = (): JSX.Element | null => {
    if (documentData && documentData.content) {
      if (
        documentData.content.includes('#CLINIC_NAME#')
        // || documentData.content.includes('#BRAND_NAME#')
      ) {
        const formattedDocumentContent = documentData.content.replace(
          '#CLINIC_NAME#',
          appointmentData?.clinic.name || ''
        )
        // formattedDocumentContent = formattedDocumentContent.replaceAll(
        //   '#BRAND_NAME#',
        //   brandName || ''
        // )
        return htmlToReactComponent(formattedDocumentContent)
      } else return htmlToReactComponent(documentData.content.trim())
    } else return null
  }

  usePageClassname(`${doc.toLowerCase()}-document-page`)

  if (isLoadingDocument) return <Loader fullHeight />

  return (
    <Layout>
      <Layout.Header hideLogo={hasLogo}>
        <BackButton onClick={backButtonHandler} isDesktop={isDesktop}>
          <BackButtonIcon />
          {t('core.back')}
        </BackButton>
      </Layout.Header>
      <LayoutContent>
        <DocumentWrapper>
          <DocumentContent>
            {isDocumentPdf ? onRenderDocumentPdf() : onRenderDocumentText()}
          </DocumentContent>
        </DocumentWrapper>
        <Signature
          onSubmit={handleSignatureSubmission}
          docType={params.docType || docType}
        />
      </LayoutContent>
    </Layout>
  )
}
