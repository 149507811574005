import { Form } from '@api'
import { yupResolver } from '@hookform/resolvers/yup'
import usePageClassname from '@hooks/usePageClassname'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RouteProps } from 'react-router'
import styled from 'styled-components'
import { AppointmentRecap } from '../../components/AppointmentRecap'
import { Button } from '../../components/Button'
import { Column } from '../../components/Column'
import { InputGroup } from '../../components/InputGroup'
import { Layout } from '../../components/Layout'
import { Radio } from '../../components/Radio'
import { StepLayout } from '../../components/StepLayout'
import { TextArea } from '../../components/TextArea'
import { Typography } from '../../components/Typography'
import { useCommonConfigs } from '../../hooks/useCommonConfigs'
import { useErrorsDebug } from '../../hooks/useErrorsDebug'
import { useFormUpdate } from '../../hooks/useFormUpdate'
import { getHealthHistorySchema } from '../../types/steps/healthHistory'
import { formApiToFormState } from '../../utils/formStateToFromApi'
import { useFormStatus, useStep } from '../../utils/routes'
import { HealthCondition } from './HealthCondition'

type HealthHistoryProps = RouteProps

const StyledTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
`

const StyledRow = styled.div`
  display: flex;
  width: 100%;

  @media screen and (min-width: 675px) {
    width: calc((100% - 2rem) / 2);
  }
`

const StyledRow2 = styled.div`
  display: none;

  @media screen and (min-width: 675px) {
    display: flex;
    width: calc((100% - 2rem) / 2);
  }
`

const StyledCell = styled.div`
  display: inline-block;
  padding: 10px;
  text-align: left;
`

const StyledCell1 = styled(StyledCell)`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`

const StyledCell2 = styled(StyledCell)`
  width: 4em;
  flex-shrink: 0;
`

const StyledColumnTitle = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 13px;
`

export const HealthHistory: React.FC<HealthHistoryProps> = () => {
  const { id, previous, isFromReview } = useStep()
  const { isFormExpired } = useFormStatus()
  const { t, i18n } = useTranslation()
  const { data, run, promise, isLoading } = useTabApiForm<Form>()
  const commonConfigs = useCommonConfigs()
  
  const validationSchema = useMemo(
    () => getHealthHistorySchema(),
    []
  )
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: data ? formApiToFormState(data, i18n.language) : {},
  })

  const { watch, reset, register, handleSubmit, errors } = methods
  useErrorsDebug(errors)
  const hasAllergies = watch('hasAllergies')
  const hasCurrentlyMedications = watch('hasCurrentlyMedications')
  const hasEyeInjuriesSurgeries = watch('hasEyeInjuriesSurgeries')
  const hasTreatedMedicalConditions = watch('hasTreatedMedicalConditions')
  const isFemale = data?.gender?.toLowerCase() === 'female'

  const onSubmit = useFormUpdate(data, reset, run, promise)

  usePageClassname('health-history-page')

  if (commonConfigs) {
    return (
      <Layout>
        <Layout.Header 
          showContinueLaterButton 
          continueLaterDataElementId="X_OEE_HealtHistory_Continue-Later" 
          showCompletitionTracker 
          completitionTracketValue={5.0} 
        />

        <Layout.Content>
          <StepLayout>
            <StepLayout.Title>{t('healthHistory.title')}</StepLayout.Title>

            <StepLayout.Description>
              {t('healthHistory.description')}
            </StepLayout.Description>

            <FormProvider {...methods}>
              <form id={id} onSubmit={handleSubmit(onSubmit)}>
                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label>
                      {t('healthHistory.healthConditions')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <StyledTable>
                        <StyledRow>
                          <StyledCell1 />
                          <StyledCell2>
                            <StyledColumnTitle $noMargin $transform="uppercase">
                              {t('core.self')}
                            </StyledColumnTitle>
                          </StyledCell2>
                          <StyledCell2>
                            <StyledColumnTitle $noMargin $transform="uppercase">
                              {t('core.relatives')}
                            </StyledColumnTitle>
                          </StyledCell2>
                        </StyledRow>

                        <StyledRow2>
                          <StyledCell1 />
                          <StyledCell2>
                            <StyledColumnTitle $noMargin $transform="uppercase">
                              {t('core.self')}
                            </StyledColumnTitle>
                          </StyledCell2>
                          <StyledCell2>
                            <StyledColumnTitle $noMargin $transform="uppercase">
                              {t('core.relatives')}
                            </StyledColumnTitle>
                          </StyledCell2>
                        </StyledRow2>

                        {commonConfigs.healthConditions?.map(
                          ({ label, value }, index) => (
                            <HealthCondition
                              key={`condition-${value}-${index}`}
                              index={index}
                              name={label}
                            />
                          )
                        )}
                      </StyledTable>
                    </InputGroup.Inputs>
                  </InputGroup>
                </Column>

                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label required>
                      {t('healthHistory.hasTreatedMedicalConditions')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        label={t('core.yes')}
                        name="hasTreatedMedicalConditions"
                        value="true"
                        ref={register}
                        disabled={isFormExpired}
                      />
                      <Radio
                        label={t('core.no')}
                        name="hasTreatedMedicalConditions"
                        value="false"
                        ref={register}
                        disabled={isFormExpired}
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.hasTreatedMedicalConditions}
                      errorMessage={t(
                        errors.hasTreatedMedicalConditions?.message || '',
                        {
                          defaultValue: t('core.requiredSelection'),
                        }
                      )}
                    />
                  </InputGroup>
                </Column>

                {hasTreatedMedicalConditions === 'true' && (
                  <Column $fullWidth>
                    <TextArea
                      name="treatedMedicalConditionsDescription"
                      label={t('core.whichOnes')}
                      error={!!errors.treatedMedicalConditionsDescription}
                      errorMessage={t(
                        errors.treatedMedicalConditionsDescription?.message ||
                          '' ||
                          '',
                        {
                          defaultValue: t('core.requiredMessage'),
                        }
                      )}
                      helperText={t('core.maxCharacters', {
                        numberOfCharacter: 300,
                      })}
                      ref={register}
                      disabled={isFormExpired}
                      required
                    />
                  </Column>
                )}

                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label required>
                      {t('healthHistory.hasEyeInjuriesSurgeries')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        name="hasEyeInjuriesSurgeries"
                        label={t('core.yes')}
                        value="true"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.hasEyeInjuriesSurgeries}
                      />
                      <Radio
                        name="hasEyeInjuriesSurgeries"
                        label={t('core.no')}
                        value="false"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.hasEyeInjuriesSurgeries}
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.hasEyeInjuriesSurgeries}
                      errorMessage={t(
                        errors.hasEyeInjuriesSurgeries?.message || '',
                        {
                          defaultValue: t('core.requiredSelection'),
                        }
                      )}
                    />
                  </InputGroup>
                </Column>

                {hasEyeInjuriesSurgeries === 'true' && (
                  <Column $fullWidth>
                    <TextArea
                      name="eyeInjuriesSurgeriesDescription"
                      label={t('core.whichOnes')}
                      error={!!errors.currentlyMedicationsDescription}
                      errorMessage={t(
                        errors.currentlyMedicationsDescription?.message || '',
                        {
                          defaultValue: t('core.requiredMessage'),
                        }
                      )}
                      helperText={t('core.maxCharacters', {
                        numberOfCharacter: 300,
                      })}
                      ref={register}
                      disabled={isFormExpired}
                      required
                    />
                  </Column>
                )}

                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label required>
                      {t('healthHistory.hasMedications')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        name="hasCurrentlyMedications"
                        label={t('core.yes')}
                        value="true"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.hasCurrentlyMedications}
                      />

                      <Radio
                        name="hasCurrentlyMedications"
                        label={t('core.no')}
                        value="false"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.hasCurrentlyMedications}
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.hasCurrentlyMedications}
                      errorMessage={t(
                        errors.hasCurrentlyMedications?.message || '',
                        {
                          defaultValue: t('core.requiredSelection'),
                        }
                      )}
                    />
                  </InputGroup>
                </Column>

                {hasCurrentlyMedications === 'true' && (
                  <Column $fullWidth>
                    <TextArea
                      name="currentlyMedicationsDescription"
                      label={t('core.whichOnes')}
                      error={!!errors.currentlyMedicationsDescription}
                      errorMessage={t(
                        errors.currentlyMedicationsDescription?.message || '',
                        {
                          defaultValue: t('core.requiredMessage'),
                        }
                      )}
                      helperText={t('core.maxCharacters', {
                        numberOfCharacter: 300,
                      })}
                      ref={register}
                      disabled={isFormExpired}
                      required
                    />
                  </Column>
                )}

                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label required>
                      {t('healthHistory.hasAllergies')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        name="hasAllergies"
                        label={t('core.yes')}
                        value="true"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.hasAllergies}
                      />

                      <Radio
                        name="hasAllergies"
                        label={t('core.no')}
                        value="false"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.hasAllergies}
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.hasAllergies}
                      errorMessage={t(errors.hasAllergies?.message || '', {
                        defaultValue: t('core.requiredSelection'),
                      })}
                    />
                  </InputGroup>
                </Column>

                {hasAllergies === 'true' && (
                  <Column $fullWidth>
                    <TextArea
                      name="allergiesDescription"
                      label={t('core.whichOnes')}
                      error={!!errors.currentlyMedicationsDescription}
                      errorMessage={t(
                        errors.currentlyMedicationsDescription?.message || '',
                        {
                          defaultValue: t('core.requiredMessage'),
                        }
                      )}
                      helperText={t('core.maxCharacters', {
                        numberOfCharacter: 300,
                      })}
                      ref={register}
                      disabled={isFormExpired}
                      required
                    />
                  </Column>
                )}

                {isFemale && (
                  <>
                    <InputGroup>
                      <InputGroup.Label required>
                        {t('healthHistory.isPregnant')}
                      </InputGroup.Label>

                      <InputGroup.Inputs>
                        <Radio
                          name="isPregnant"
                          label={t('core.yes')}
                          value="true"
                          ref={register}
                          disabled={isFormExpired}
                          error={!!errors.isPregnant}
                        />
                        <Radio
                          name="isPregnant"
                          label={t('core.no')}
                          value="false"
                          ref={register}
                          disabled={isFormExpired}
                          error={!!errors.isPregnant}
                        />
                      </InputGroup.Inputs>
                      <InputGroup.Errors
                        error={!!errors.isPregnant}
                        errorMessage={t(errors.isPregnant?.message || '', {
                          defaultValue: t('core.requiredSelection'),
                        })}
                      />
                    </InputGroup>

                    <InputGroup>
                      <InputGroup.Label required>
                        {t('healthHistory.isCurrentlyNursing')}
                      </InputGroup.Label>

                      <InputGroup.Inputs>
                        <Radio
                          name="isCurrentlyNursing"
                          label={t('core.yes')}
                          value="true"
                          ref={register}
                          disabled={isFormExpired}
                          error={!!errors.isCurrentlyNursing}
                        />
                        <Radio
                          name="isCurrentlyNursing"
                          label={t('core.no')}
                          value="false"
                          ref={register}
                          disabled={isFormExpired}
                          error={!!errors.isCurrentlyNursing}
                        />
                      </InputGroup.Inputs>
                      <InputGroup.Errors
                        error={!!errors.isCurrentlyNursing}
                        errorMessage={t(
                          errors.isCurrentlyNursing?.message || '',
                          {
                            defaultValue: t('core.requiredMessage'),
                          }
                        )}
                      />
                    </InputGroup>
                  </>
                )}

                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label required>
                      {t('healthHistory.isSmoker')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        name="isSmoker"
                        label={t('core.yes')}
                        value="true"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.isSmoker}
                      />
                      <Radio
                        name="isSmoker"
                        label={t('core.no')}
                        value="false"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.isSmoker}
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.isSmoker}
                      errorMessage={t(errors.isSmoker?.message || '', {
                        defaultValue: t('core.requiredMessage'),
                      })}
                    />
                  </InputGroup>
                </Column>

                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label required>
                      {t('healthHistory.isDrinkAlcohol')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        name="isDrinkAlcohol"
                        label={t('core.yes')}
                        value="true"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.isDrinkAlcohol}
                      />

                      <Radio
                        name="isDrinkAlcohol"
                        label={t('core.no')}
                        value="false"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.isDrinkAlcohol}
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.isDrinkAlcohol}
                      errorMessage={t(errors.isDrinkAlcohol?.message || '', {
                        defaultValue: t('core.requiredMessage'),
                      })}
                    />
                  </InputGroup>
                </Column>
              </form>
            </FormProvider>
          </StepLayout>

          <AppointmentRecap />
        </Layout.Content>

        <Layout.Footer>
          <Button
            onClick={() => previous()}
            $outlined
            data-element-id={
              isFromReview ? 'X_OEE_View&Edit_Back' : 'X_OEE_HealthHistory_Back'
            }
            $compact
          >
            {t('core.back')}
          </Button>

          {!isFormExpired && (
            <Button
              type="submit"
              form={id}
              data-element-id={
                isFromReview
                  ? 'X_OEE_View&Edit_Continue'
                  : 'X_OEE_HealtHistory_Continue'
              }
              disabled={isLoading}
              $compact
            >
              {t(isFromReview ? 'core.saveChanges' : 'core.continue')}
            </Button>
          )}
        </Layout.Footer>
      </Layout>
    )
  }

  return null
}
