import { differenceInYears, isFuture } from 'date-fns'
import * as yup from 'yup'
import { localeDateStringToDate } from '../../utils/date'

export const getBasicsSchema = (locale: string) => {
  const schema = {
    firstName: yup.string().required('basics.firstNameRequiredMessage'),
    lastName: yup.string().required('basics.lastNameRequiredMessage'),
    address: yup.string().required('basics.addressRequiredMessage'),
    phone: yup
      .string()
      .matches(/^\(\d{3}\)\d{3}-\d{4}$/, 'basics.phoneInvalidMessage')
      .required('basics.phoneRequiredMessage'),
    zipCode: yup.string().when('country', {
      is: 'CA',
      then: yup
        .string()
        .matches(/^\w{6}$/, 'basics.zipCodeRegexMessage')
        .required('basics.zipCodeRequiredMessage'),
      otherwise: yup
        .string()
        .matches(/^\d{5}$/, 'basics.zipCodeRegexMessage')
        .required('basics.zipCodeRequiredMessage'),
    }),
    occupation: yup.string().required('basics.occupationRequiredMessage'),
    employer: yup.string().notRequired(),
    city: yup.string().required('basics.cityRequiredMessage'),
    country: yup
      .string()
      .oneOf(['US', 'CA'])
      .required('core.requiredSelection'),
    state: yup.string().required('core.requiredSelection'),
    email: yup
      .string()
      .email('basics.emailInvalidMessage')
      .required('basics.emailRequiredMessage'),
    preferredContactMethods: yup.array().of(yup.string().required()).required(),
    gender: yup.string().required(),
    preferredPronoun: yup.string().when('gender', {
      is: 'OTHER',
      then: yup.string().required('basics.preferredPronounRequiredMessage'),
      otherwise: yup.string().notRequired(),
    }),
    dob: yup
      .string()
      .test({
        name: 'isCentenarian',
        message: 'basics.dobMoreThan100Years',
        test: (lastEyeExamDate) =>
          lastEyeExamDate
            ? differenceInYears(
                new Date(),
                localeDateStringToDate(lastEyeExamDate, locale)
              ) <= 150
            : false,
      })
      .test({
        name: 'isMartyMcFly',
        message: 'core.yearInFuture',
        test: (dob) =>
          dob ? !isFuture(localeDateStringToDate(dob, locale)) : false,
      })
      .required('basics.dobRequiredMessage'),
    isInterpreterRequired: yup
      .string()
      .oneOf(['true', 'false'])
      .notRequired()
      .default('false'),
    languageAssistance: yup.string().notRequired(),
    isAssistanceRequired: yup
      .string()
      .oneOf(['true', 'false'])
      .notRequired()
      .default('false'),
    assistance: yup.string().when('isAssistanceRequired', {
      is: 'true',
      then: yup.string().max(300).required('basics.assistanceRequiredMessage'),
      otherwise: yup.string().max(300).notRequired(),
    }),
    ethnicity: yup.array().of(yup.string().required()).notRequired(),
  }

  return yup.object(schema).defined()
}

// TODO: fix type generation. See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/42341 for more details
export type BasicsType = Omit<
  yup.InferType<ReturnType<typeof getBasicsSchema>>,
  'preferredContactMethods'
> & {
  preferredContactMethods: string[]
}
