import { Form } from '@api'
import { yupResolver } from '@hookform/resolvers/yup'
import usePageClassname from '@hooks/usePageClassname'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RouteProps } from 'react-router'
import styled from 'styled-components'
import { AppointmentRecap } from '../components/AppointmentRecap'
import { Button } from '../components/Button'
import { Checkbox, deselectValue, selectValue } from '../components/Checkbox'
import { Column } from '../components/Column'
import { InputGroup } from '../components/InputGroup'
import { Layout } from '../components/Layout'
import { Radio } from '../components/Radio'
import { StepLayout } from '../components/StepLayout'
import { TextArea } from '../components/TextArea'
import { TextField } from '../components/TextField'
import { Typography } from '../components/Typography'
import { useCommonConfigs } from '../hooks/useCommonConfigs'
import { useErrorsDebug } from '../hooks/useErrorsDebug'
import { useFormUpdate } from '../hooks/useFormUpdate'
import { getVisionHystoryRules } from '../types/steps/visionHistory'
import { formApiToFormState } from '../utils/formStateToFromApi'
import { useFormStatus, useStep } from '../utils/routes'

type VisionHistoryProps = RouteProps

const TextInputGroup = styled(InputGroup)`
  padding: 10px 0px;
  margin: 0 0 10px;
`

export const VisionHistory: React.FC<VisionHistoryProps> = () => {
  const { id, previous, isFromReview } = useStep()
  const { isFormExpired } = useFormStatus()
  const { t, i18n } = useTranslation()
  const { data, run, promise, isLoading } = useTabApiForm<Form>()

  const [hasEyewear, setHasEyewear] = useState(true)
  const [hasContactLenses, setHasContactLenses] = useState(false)

  const validationSchema = useMemo(
    () =>
      getVisionHystoryRules({
        hasEyewear,
        hasContactLenses,
      }),
    [hasEyewear, hasContactLenses]
  )

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: data ? formApiToFormState(data, i18n.language) : {},
  })

  const { register, errors, handleSubmit, reset, watch, getValues, setValue } =
    formMethods

  useErrorsDebug(errors)

  const commonConfigs = useCommonConfigs()
  const onSubmit = useFormUpdate(data, reset, run, promise)

  const currentEyeware: string[] = useMemo(
    () => watch('eyewear') || [],
    [watch]
  )

  useEffect(() => {
    setHasEyewear(
      currentEyeware.length > 1 ||
        (currentEyeware.length === 1 &&
          currentEyeware[0].toLowerCase() !== 'none')
    )

    setHasContactLenses(currentEyeware.includes('CONTACT_LENSES'))
  }, [currentEyeware])

  usePageClassname('vision-history-page')

  const handleCheckboxChange =
    (fieldName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      // If you click on'NONE', deselect others and leave only 'NONE'
      if (value === 'NONE' && e.target.checked) {
        setValue(fieldName, ['NONE'])
        return
      }

      const previousValues = getValues(fieldName) as string[]
      let nextValues: string[] = [...previousValues]

      const all = commonConfigs[fieldName]?.map((x) => x.value) ?? []

      // If 'NONE' is selected and you click on another value, 'NONE' must be deselected
      if (previousValues.includes('NONE')) {
        nextValues = deselectValue('NONE', previousValues)
      }

      if (e.target.checked) {
        nextValues = selectValue(value, nextValues, all)
      } else {
        nextValues = deselectValue(value, nextValues)
      }

      setValue(fieldName, nextValues)
    }

  if (commonConfigs) {
    return (
      <Layout>
        <Layout.Header
          showContinueLaterButton
          continueLaterDataElementId="X_OEE_VisionHistory_Continue-Later"
          showCompletitionTracker
          completitionTracketValue={6.6}
        />

        <Layout.Content>
          <StepLayout>
            <StepLayout.Title>{t('visionHistory.title')}</StepLayout.Title>

            <FormProvider {...formMethods}>
              <form id={id} onSubmit={handleSubmit(onSubmit)}>
                <InputGroup>
                  <InputGroup.Label required>
                    {t('visionHistory.experiencingVision')}
                    <Typography $variant="span" $weight={200} $transform="none">
                      {'\n'}({t('core.selectAllThatApply')})
                    </Typography>
                  </InputGroup.Label>

                  <InputGroup.Inputs $twoColumns>
                    {commonConfigs.experiencingVision?.map(
                      ({ label, value }, index) => (
                        <Checkbox
                          name="experiencingVision"
                          label={t(`experiencingVision.${label}`)}
                          tooltip={t(`experiencingVision.${label}Tooltip`, {
                            defaultValue: '',
                          })}
                          key={`experiencingVision-${label}-${index}`}
                          onChange={handleCheckboxChange('experiencingVision')}
                          value={value}
                          ref={register}
                          disabled={isFormExpired}
                        />
                      )
                    )}
                  </InputGroup.Inputs>
                  <InputGroup.Errors
                    error={!!errors.experiencingVision}
                    errorMessage={t(
                      errors.experiencingVision?.[0]?.message || '',
                      {
                        defaultValue: t('core.requiredSelection'),
                      }
                    )}
                  />
                </InputGroup>

                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label required>
                      {t('visionHistory.eyewear')}
                      <Typography
                        $variant="span"
                        $weight={200}
                        $transform="none"
                      >
                        {'\n'}({t('core.selectAllThatApply')})
                      </Typography>
                    </InputGroup.Label>

                    <InputGroup.Inputs $twoColumns>
                      {commonConfigs.eyewear?.map(({ label, value }, index) => (
                        <Checkbox
                          name="eyewear"
                          key={`eyewear-${label}-${index}`}
                          label={t(`eyewear.${label}`)}
                          tooltip={t(`eyewear.${label}Tooltip`, {
                            defaultValue: '',
                          })}
                          onChange={handleCheckboxChange('eyewear')}
                          value={value}
                          ref={register}
                          disabled={isFormExpired}
                        />
                      ))}
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.eyewear}
                      errorMessage={t(errors.eyewear?.[0]?.message || '', {
                        defaultValue: t('core.requiredSelection'),
                      })}
                    />
                  </InputGroup>
                </Column>

                {hasEyewear && (
                  <TextInputGroup>
                    <InputGroup.Label required>
                      {t('visionHistory.yearEyeglassPrescription')}
                    </InputGroup.Label>

                    <TextField
                      label={t('visionHistory.estimatedYear')}
                      name="yearEyeglassPrescription"
                      ref={register}
                      disabled={isFormExpired}
                      error={!!errors.yearEyeglassPrescription}
                      errorMessage={t(
                        errors.yearEyeglassPrescription?.message || '',
                        {
                          defaultValue: t('core.requiredMessage'),
                        }
                      )}
                    />
                  </TextInputGroup>
                )}

                {hasContactLenses && (
                  <TextInputGroup>
                    <InputGroup.Label>
                      {t('visionHistory.contactLensesType')}
                    </InputGroup.Label>

                    <TextField
                      name="contactLensesType"
                      ref={register}
                      disabled={isFormExpired}
                      error={!!errors.contactLensesType}
                      errorMessage={t(errors.contactLensesType?.message || '', {
                        defaultValue: t('core.requiredMessage'),
                      })}
                    />
                  </TextInputGroup>
                )}

                <TextInputGroup>
                  <InputGroup.Label>
                    {t('visionHistory.lastEyeExam')}
                  </InputGroup.Label>

                  <TextField
                    label={t('visionHistory.estimatedYear')}
                    name="lastEyeExam"
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.lastEyeExam}
                    errorMessage={t(errors.lastEyeExam?.message || '', {
                      defaultValue: t('core.requiredMessage'),
                    })}
                  />
                </TextInputGroup>

                {!hasContactLenses && (
                  <InputGroup>
                    <InputGroup.Label required>
                      {t('visionHistory.isInterestedInContactLens')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        label={t('core.yes')}
                        name="isInterestedInContactLens"
                        value="true"
                        ref={register}
                        disabled={isFormExpired}
                      />
                      <Radio
                        label={t('core.no')}
                        name="isInterestedInContactLens"
                        value="false"
                        ref={register}
                        disabled={isFormExpired}
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.isInterestedInContactLens}
                      errorMessage={t(
                        errors.isInterestedInContactLens?.message || '',
                        {
                          defaultValue: t('core.requiredMessage'),
                        }
                      )}
                    />
                  </InputGroup>
                )}

                <TextArea
                  label={t('visionHistory.shareExtraVisionInfo')}
                  name="shareExtraVisionInfo"
                  ref={register}
                  disabled={isFormExpired}
                  error={!!errors.shareExtraVisionInfo}
                  errorMessage={t(errors.shareExtraVisionInfo?.message || '', {
                    defaultValue: t('core.requiredMessage'),
                  })}
                  helperText={t('core.maxCharacters', {
                    numberOfCharacter: 300,
                  })}
                />
              </form>
            </FormProvider>
          </StepLayout>

          <AppointmentRecap />
        </Layout.Content>

        <Layout.Footer>
          <Button
            onClick={() => previous()}
            $outlined
            data-element-id={
              isFromReview ? 'X_OEE_View&Edit_Back' : 'X_OEE_VisionHistory_Back'
            }
            $compact
          >
            {t('core.back')}
          </Button>

          {!isFormExpired && (
            <Button
              type="submit"
              form={id}
              data-element-id={
                isFromReview
                  ? 'X_OEE_View&Edit_Continue'
                  : 'X_OEE_VisionHistory_Continue'
              }
              disabled={isLoading}
              $compact
            >
              {t(isFromReview ? 'core.saveChanges' : 'core.continue')}
            </Button>
          )}
        </Layout.Footer>
      </Layout>
    )
  }

  return null
}
