import { Form } from '@api'
import { yupResolver } from '@hookform/resolvers/yup'
import usePageClassname from '@hooks/usePageClassname'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RouteProps } from 'react-router'
import { AppointmentRecap } from '../components/AppointmentRecap'
import { Button } from '../components/Button'
import { Checkbox } from '../components/Checkbox'
import { Column } from '../components/Column'
import { InputGroup } from '../components/InputGroup'
import { Layout } from '../components/Layout'
import { Radio } from '../components/Radio'
import { StepLayout } from '../components/StepLayout'
import { TextArea } from '../components/TextArea'
import { Typography } from '../components/Typography'
import { useCommonConfigs } from '../hooks/useCommonConfigs'
import { useErrorsDebug } from '../hooks/useErrorsDebug'
import { useFormUpdate } from '../hooks/useFormUpdate'
import { getPatientBackgroundSchema } from '../types/steps/patientBackground'
import { formApiToFormState } from '../utils/formStateToFromApi'
import { useFormStatus, useStep } from '../utils/routes'

type PatientBackgroudProps = RouteProps

export const PatientBackgroud: React.FC<PatientBackgroudProps> = () => {
  const { id, previous, isFromReview } = useStep()
  const { isFormExpired } = useFormStatus()
  const { t, i18n } = useTranslation()
  const { data, run, promise, isLoading } = useTabApiForm<Form>()

  const validationSchema = useMemo(() => getPatientBackgroundSchema(), [])
  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: data ? formApiToFormState(data, i18n.language) : {},
  })
  const { register, handleSubmit, errors, reset, watch } = formMethods

  useErrorsDebug(errors)

  const commonConfigs = useCommonConfigs()

  const environmentWorkType: string[] =
    watch('environmentWorkType.values') || []
  const likeToDo: string[] = watch('likeToDo.values') || []

  const onSubmit = useFormUpdate(data, reset, run, promise)

  usePageClassname('patient-background-page')

  if (commonConfigs) {
    return (
      <Layout>
        <Layout.Header
          showContinueLaterButton
          continueLaterDataElementId="X_OEE_PatientBackground_Continue-Later"
          showCompletitionTracker
          completitionTracketValue={8.3}
        />

        <Layout.Content>
          <StepLayout>
            <StepLayout.Title>{t('patientBackground.title')}</StepLayout.Title>

            <StepLayout.Description>
              {t('patientBackground.description')}
            </StepLayout.Description>

            <FormProvider {...formMethods}>
              <form id={id} onSubmit={handleSubmit(onSubmit)}>
                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label>
                      {t('patientBackground.environmentWorkType')}
                      <Typography $variant="span" $weight={200}>
                        {'\n'}({t('core.selectAllThatApply')})
                      </Typography>
                    </InputGroup.Label>

                    <InputGroup.Inputs $twoColumns>
                      {commonConfigs.workEnvironment?.map(
                        ({ label, value }, index) => (
                          <Checkbox
                            name="environmentWorkType.values"
                            key={`environmentWorkType-${label}-${index}`}
                            value={value}
                            label={t(`environmentWorkType.${label}`)}
                            tooltip={t(`environmentWorkType.${label}Tooltip`, {
                              defaultValue: '',
                            })}
                            ref={register}
                            disabled={isFormExpired}
                          />
                        )
                      )}
                    </InputGroup.Inputs>

                    {environmentWorkType.includes('OTHER') && (
                      <TextArea
                        name="environmentWorkType.other"
                        label={t('core.whichOnes')}
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.environmentWorkType}
                        errorMessage={t(
                          errors.environmentWorkType?.other?.message || '',
                          {
                            defaultValue: t('core.requiredMessage'),
                          }
                        )}
                      />
                    )}

                    <InputGroup.Errors
                      error={!!errors.environmentWorkType?.values}
                      errorMessage={t(
                        errors.environmentWorkType?.values?.[0]?.message || '',
                        {
                          defaultValue: t('core.requiredSelection'),
                        }
                      )}
                    />
                  </InputGroup>
                </Column>

                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label>
                      {t('patientBackground.hoursDayUsing')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        name="hoursDayUsing"
                        value="8HoursPlus"
                        label="8 Hours Plus"
                        ref={register}
                        disabled={isFormExpired}
                        $transform="uppercase"
                      />
                      <Radio
                        name="hoursDayUsing"
                        value="between4and8Hours"
                        label="4-8 Hours"
                        ref={register}
                        disabled={isFormExpired}
                        $transform="uppercase"
                      />
                      <Radio
                        name="hoursDayUsing"
                        value="lessThan4Hours"
                        label="Less than 4 Hours"
                        ref={register}
                        disabled={isFormExpired}
                        $transform="uppercase"
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.hoursDayUsing}
                      errorMessage={t(errors.hoursDayUsing?.message || '', {
                        defaultValue: t('core.requiredSelection'),
                      })}
                    />
                  </InputGroup>
                </Column>

                <Column $fullWidth>
                  <InputGroup>
                    <InputGroup.Label>
                      {t('patientBackground.likeToDo')}
                      <Typography $variant="span" $weight={200}>
                        {'\n'}({t('core.selectAllThatApply')})
                      </Typography>
                    </InputGroup.Label>

                    <InputGroup.Inputs $twoColumns>
                      {commonConfigs.likeToDo?.map(
                        ({ label, value }, index) => (
                          <Checkbox
                            name="likeToDo.values"
                            key={`likeToDo-${label}-${index}`}
                            value={value}
                            label={t(`likeToDo.${label}`)}
                            tooltip={t(`likeToDo.${label}Tooltip`, {
                              defaultValue: '',
                            })}
                            ref={register}
                            disabled={isFormExpired}
                          />
                        )
                      )}
                    </InputGroup.Inputs>

                    {likeToDo.includes('OTHER') && (
                      <TextArea
                        name="likeToDo.other"
                        label={t('core.whichOnes')}
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.likeToDo?.other}
                        errorMessage={t(errors.likeToDo?.other?.message || '', {
                          defaultValue: t('core.requiredSelection'),
                        })}
                      />
                    )}

                    <InputGroup.Errors
                      error={!!errors.likeToDo?.values}
                      errorMessage={t(
                        errors.likeToDo?.values?.[0]?.message || '',
                        {
                          defaultValue: t('core.requiredSelection'),
                        }
                      )}
                    />
                  </InputGroup>
                </Column>
              </form>
            </FormProvider>
          </StepLayout>

          <AppointmentRecap additionalTop={51} />
        </Layout.Content>

        <Layout.Footer>
          <Button
            onClick={() => previous()}
            $outlined
            data-element-id={
              isFromReview
                ? 'X_OEE_View&Edit_Back'
                : 'X_OEE_PatientBackground_Back'
            }
            $compact
          >
            {t('core.back')}
          </Button>

          {!isFormExpired && (
            <Button
              type="submit"
              form={id}
              data-element-id={
                isFromReview
                  ? 'X_OEE_View&Edit_Continue'
                  : 'X_OEE_PatientBackground_Continue'
              }
              disabled={isLoading}
              $compact
            >
              {t(isFromReview ? 'core.saveChanges' : 'core.continue')}
            </Button>
          )}
        </Layout.Footer>
      </Layout>
    )
  }

  return null
}
