import * as yup from 'yup'
import { MultipleChoiceWithOther } from '../../api'

export const getPatientBackgroundSchema = () => {
  const schema = {
    environmentWorkType: yup
      .object({
        values: yup.array().of(yup.string().required()).notRequired(),
        other: yup.string().max(300).notRequired(),
      })
      .notRequired(),
    hoursDayUsing: yup.string().notRequired(),
    likeToDo: yup
      .object({
        values: yup.array().of(yup.string().required()).notRequired(),
        other: yup.string().max(300).notRequired(),
      })
      .notRequired(),
  }

  return yup.object(schema).defined()
}

export type PatientBackgroundType = Omit<
  yup.InferType<ReturnType<typeof getPatientBackgroundSchema>>,
  'environmentWorkType' | 'likeToDo'
> & {
  environmentWorkType: MultipleChoiceWithOther
  likeToDo: MultipleChoiceWithOther
}
