import React from 'react'
import clsx from 'clsx'
import styled, { css } from 'styled-components'
import { Button, LinkButton, ButtonProps } from '../../components/Button'
import { Modal, ModalProps } from '../../components/Modal'
import { Typography } from '../../components/Typography'
import ArrowIcon from '../../assets/icons/arrow.svg'
import CheckIcon from '../../assets/icons/check.svg'
import ExclamationIcon from '../../assets/icons/exclamation.svg'
import { useTranslation } from 'react-i18next'
import { anzForm, step, steps } from '../../utils/routes'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import { DocumentType } from '../../api'
import { Icon } from '../../components/Icon'
import useIsAnz from '@hooks/useIsAnz'

interface LegalSignModalProps extends ModalProps {
  documentPageHandler: (docType: DocumentType) => void
  availableDocuments: Record<DocumentType, boolean>
}

const DocumentWrapper = styled.div`
  background-color: #f1f1f1;
  padding: 8px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    margin-top: 20px;
  }

  &:not(:first-of-type) {
    margin-top: 20px;
  }
`

const StyledIcon = styled(Icon)`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
`

const StyledArrowButton = styled(Button).attrs({
  src: ArrowIcon,
})<
  ButtonProps & {
    src: string
  }
>`
  width: 25px;
  height: 25px;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transform: rotate(90deg);
  padding: 5px;
  transition: transform 0.25s ease-in-out;

  &&&:not(disabled):hover,
  &&&:not(disabled):focus {
    box-shadow: unset;
    transform: rotate(90deg) translateY(-5px);
  }
`

const TypographyWrapper = styled.div`
  display: flex;
  align-items: center;

  & p {
    text-align: left;
    overflow: hidden;
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.media.large} {
    & p {
      margin-left: 20px;
    }
  }
`

const StyledModalActions = styled(Modal.Actions)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;

  & * {
    width: 60%;
  }

  & > * + * {
    margin-bottom: 15px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      flex-direction: row;

      & * {
        width: auto;
      }

      & > * + * {
        margin-bottom: 0px;
        margin-left: 30px;
      }
    }
  `}
`

export const LegalSignModal: React.FC<LegalSignModalProps> = ({
  documentPageHandler,
  availableDocuments,
  ...props
}) => {
  const { t } = useTranslation()
  const { data } = useTabApiForm()
  const isAnz = useIsAnz()

  const isPrivacyPracticeSigned = data?.optPrivacyPractice?.signature
  const isHealthInformationSigned = data?.optHealthInformation?.signature

  return (
    <Modal
      {...props}
      id="legal-sign-modal"
      data-element-id="X_OEE_Intro_Legal-Overlay"
    >
      <Modal.Content>
        <Typography>{t('legalSignModal.description')}</Typography>

        <section>
          {availableDocuments.privacy && !isPrivacyPracticeSigned && (
            <DocumentWrapper>
              <TypographyWrapper>
                <StyledIcon
                  className={clsx({
                    'intake__icon-check': isPrivacyPracticeSigned,
                    'intake__icon-exclamation': !isPrivacyPracticeSigned,
                  })}
                  src={isPrivacyPracticeSigned ? CheckIcon : ExclamationIcon}
                />
                <Typography $weight="normal">
                  {t('intro.privacyDescription')}
                </Typography>
              </TypographyWrapper>

              <StyledArrowButton
                onClick={() => documentPageHandler(DocumentType.PRIVACY)}
              />
            </DocumentWrapper>
          )}

          {availableDocuments.health && !isHealthInformationSigned && (
            <DocumentWrapper>
              <TypographyWrapper>
                <StyledIcon
                  className={clsx({
                    'intake__icon-check': isHealthInformationSigned,
                    'intake__icon-exclamation': !isHealthInformationSigned,
                  })}
                  src={isHealthInformationSigned ? CheckIcon : ExclamationIcon}
                />
                <Typography $weight="normal">
                  {t('intro.healthDescription')}
                </Typography>
              </TypographyWrapper>

              <StyledArrowButton
                onClick={() => documentPageHandler(DocumentType.HEALTH)}
              />
            </DocumentWrapper>
          )}
        </section>
      </Modal.Content>

      <StyledModalActions>
        <Button $outlined onClick={props.onRequestClose}>
          {t('core.goBack')}
        </Button>
        <LinkButton to={isAnz ? anzForm() : step(steps[0])}>
          {t('core.understand')}
        </LinkButton>
      </StyledModalActions>
    </Modal>
  )
}
