import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RouteProps } from 'react-router'
import styled, { css } from 'styled-components'

import { useCommonConfigs } from '@hooks/useCommonConfigs'
import { useEligibilityCheckEnabled } from '@hooks/useEligibilityCheckEnabled'
import { useErrorsDebug } from '@hooks/useErrorsDebug'
import { useFormUpdate } from '@hooks/useFormUpdate'
import { useInsuranceFieldVisible } from '@hooks/useInsuranceFieldVisible'
import usePageClassname from '@hooks/usePageClassname'

import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import { useTabApiAppointment } from '@services/TabApiProvider/ProviderAppointment'

import { getInsuranceSchema } from '@custom-types/steps/insurance'

import { formApiToFormState } from '@utils/formStateToFromApi'
import { useFormStatus, useStep } from '@utils/routes'

import { Form } from '@api'
import { AppointmentRecap } from '@components/AppointmentRecap'
import { Button } from '@components/Button'
import { Checkbox } from '@components/Checkbox'
import { Column } from '@components/Column'
import { InputGroup } from '@components/InputGroup'
import { Layout } from '@components/Layout'
import { ThreeColumns } from '@components/Layout/ThreeColumns'
import { TwoColumns } from '@components/Layout/TwoColumns'
import { Loader } from '@components/Loader'
import { ModalEligibility } from '@components/ModalEligibility'
import { Radio } from '@components/Radio'
import { StepLayout } from '@components/StepLayout'
import { TextField } from '@components/TextField'
import { Tooltip } from '@components/Tooltip'
import { TypeAhead } from '@components/TypeAhead'
import { Typography } from '@components/Typography'
import InsuranceData from './InsuranceData'

const isNewInsuranceDataSectionVisible = process.env.REACT_APP_SHOW_NEW_INSURANCE_SECTION === 'true'

export type InsuranceTooltipHelperProps = {
  label: string
}

const InsuranceTooltip = styled(Tooltip)`
  display: inline-block;
  ${({ theme }) => css`
    ${theme.breakpoints.media.medium} {
      margin-right: auto;
      min-width: 280px;
    }
  `}
`

export const InsuranceTooltipHelper: React.FC<InsuranceTooltipHelperProps> = ({
  label,
  children,
}) => {
  return (
    <InsuranceTooltip
      position="top"
      label={
        <Typography $noMargin $align="initial" $color="primary">
          {label}
        </Typography>
      }
    >
      {children}
    </InsuranceTooltip>
  )
}

type InsuranceProps = RouteProps

export const Insurance: React.FC<InsuranceProps> = () => {
  const { id, previous, isFromReview } = useStep()
  const commonConfigs = useCommonConfigs()
  const isFieldVisible = useInsuranceFieldVisible()
  const isEligibilityCheckEnabled = useEligibilityCheckEnabled()
  const { isFormExpired } = useFormStatus()
  const { t, i18n } = useTranslation()
  const { data, run, promise, isLoading } = useTabApiForm<Form>()
  const { data: appointmentData, isLoading: isLoadingAppointment } =
    useTabApiAppointment()

  const insuranceSchema = useMemo(
    () => getInsuranceSchema(isNewInsuranceDataSectionVisible, isFieldVisible, i18n.language),
    [isFieldVisible, i18n.language]
  )
  const formMethods = useForm({
    resolver: yupResolver(insuranceSchema),
    mode: 'onBlur',
    defaultValues: data ? formApiToFormState(data, i18n.language) : {},
  })
  const { register, handleSubmit, errors, reset, watch } = formMethods

  useErrorsDebug(errors)
  const hasInsurancePlan = watch('hasInsurancePlan')
  const insuranceProvider = watch('insuranceProvider')
  const hasMedicalInsurancePlan = watch('hasMedicalInsurancePlan')
  const medicalInsuranceProvider = watch('medicalInsuranceProvider')
  const isPrimaryHolder = watch('isPrimaryHolder')
  const isMedicalInsurancePrimaryHolder = watch(
    'isMedicalInsurancePrimaryHolder'
  )

  const primaryMemberID = watch('primaryMemberID')
  const primaryMemberSSN = watch('primaryMemberSSN')
  const primaryHolderFirstName = watch('primaryHolderFirstName')
  const primaryHolderLastName = watch('primaryHolderLastName')
  const primaryHolderDOB = watch('primaryHolderDOB')
  const primaryHolderSSN = watch('primaryHolderSSN')

  const onSubmit = useFormUpdate(data, reset, run, promise, isFieldVisible)

  usePageClassname('insurance-page')

  if (appointmentData) {
    const {
      clinic: { country },
    } = appointmentData

    if (isLoadingAppointment) return <Loader height={100} />

    return (
      <Layout>
        <Layout.Header 
          showContinueLaterButton 
          continueLaterDataElementId="X_OEE_Insurance_Continue-Later" 
          showCompletitionTracker 
          completitionTracketValue={3.3} 
        />

        <Layout.Content>
          <StepLayout>
            <StepLayout.Title>{t('insurance.title')}</StepLayout.Title>

            <StepLayout.Description>
              {t('insurance.description')}
            </StepLayout.Description>

            <FormProvider {...formMethods}>
              <form id={id} onSubmit={handleSubmit(onSubmit)}>
                <Column $fullWidth>
                  <Checkbox
                    name="hasInsurancePlan"
                    label={t('insurance.hasInsurancePlan')}
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.hasInsurancePlan}
                    errorMessage={t(errors.hasInsurancePlan?.message || '', {
                      defaultValue: t('core.requiredSelection'),
                    })}
                  />
                </Column>

                {hasInsurancePlan && (
                  <>
                    <Column $fullWidth>
                      <TypeAhead
                        name="insuranceProvider"
                        label={t('insurance.insuranceProvider')}
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.insuranceProvider}
                        errorMessage={t(
                          errors.insuranceProvider?.message || '',
                          {
                            defaultValue: t('core.requiredSelection'),
                          }
                        )}
                        defaultValue=""
                        required
                        helperText={
                          <div>
                            {isPrimaryHolder !== 'false' &&
                              isEligibilityCheckEnabled(insuranceProvider) && (
                                <ModalEligibility
                                  insuranceProvider={insuranceProvider}
                                  primaryHolderDOB={primaryHolderDOB}
                                  primaryHolderFirstName={
                                    primaryHolderFirstName
                                  }
                                  primaryHolderLastName={primaryHolderLastName}
                                  primaryHolderSSN={primaryHolderSSN}
                                  primaryMemberID={primaryMemberID}
                                  primaryMemberSSN={primaryMemberSSN}
                                />
                              )}
                          </div>
                        }
                      >
                        <TypeAhead.Option value="" disabled>
                          --
                        </TypeAhead.Option>
                        {commonConfigs.visionInsuranceProvider?.map(
                          ({ label, value }, index) => (
                            <TypeAhead.Option
                              value={value}
                              key={`provider-${label}-${index}`}
                            >
                              {t(`provider.${label}`, {
                                defaultValue: value,
                              })}
                            </TypeAhead.Option>
                          )
                        )}
                      </TypeAhead>
                    </Column>
                    <ThreeColumns>
                      {isFieldVisible('primaryMemberID', insuranceProvider) && (
                        <TextField
                          name="primaryMemberID"
                          label={t('insurance.primaryMemberID')}
                          ref={register}
                          disabled={isFormExpired}
                          error={!!errors.primaryMemberID?.message}
                          errorMessage={t(
                            errors.primaryMemberID?.message || '',
                            {
                              defaultValue: t('core.requiredMessage'),
                            }
                          )}
                          $fullWidth
                          required
                          helperText={
                            <InsuranceTooltipHelper
                              label={t('insurance.primaryMemberIDHelperText')}
                            >
                              {t('insurance.primaryMemberIDTooltip')}
                            </InsuranceTooltipHelper>
                          }
                        />
                      )}

                      {isFieldVisible(
                        'primaryMemberSSN',
                        insuranceProvider
                      ) && (
                          <TextField
                            name="primaryMemberSSN"
                            label={t('insurance.primaryMemberSSN')}
                            ref={register}
                            disabled={isFormExpired}
                            error={!!errors.primaryMemberSSN?.message}
                            errorMessage={t(
                              errors.primaryMemberSSN?.message || '',
                              {
                                defaultValue: t('core.requiredMessage'),
                              }
                            )}
                            required
                            styleLabel={{ whiteSpace: 'nowrap' }}
                          />
                        )}
                      {isFieldVisible('primaryMemberID', insuranceProvider) &&
                        isFieldVisible(
                          'primaryMemberSSN',
                          insuranceProvider
                        ) && <div></div>}
                    </ThreeColumns>
                    {isFieldVisible('isPrimaryHolder', insuranceProvider) && (
                      <InputGroup>
                        <InputGroup.Label required>
                          {t('insurance.isPrimaryHolder')}
                        </InputGroup.Label>

                        <InputGroup.Inputs>
                          <Radio
                            name="isPrimaryHolder"
                            label={t('core.yes')}
                            value="true"
                            ref={register}
                            disabled={isFormExpired}
                            error={!!errors.isPrimaryHolder}
                          />

                          <Radio
                            name="isPrimaryHolder"
                            label={t('core.no')}
                            value="false"
                            ref={register}
                            disabled={isFormExpired}
                            error={!!errors.isPrimaryHolder}
                          />
                        </InputGroup.Inputs>
                        <InputGroup.HelperText>
                          <InsuranceTooltipHelper
                            label={t('insurance.isPrimaryHolderHelperText')}
                          >
                            {t('insurance.isPrimaryHolderTooltip')}
                          </InsuranceTooltipHelper>
                        </InputGroup.HelperText>
                        <InputGroup.Errors
                          error={!!errors.isPrimaryHolder}
                          errorMessage={t(
                            errors.isPrimaryHolder?.message || '',
                            {
                              defaultValue: t('core.requiredSelection'),
                            }
                          )}
                        />
                      </InputGroup>
                    )}
                    {isPrimaryHolder === 'false' && (
                      <>
                        <InsuranceData
                          isNewInsuranceDataSectionVisible={isNewInsuranceDataSectionVisible}
                          insuranceProvider={insuranceProvider}
                          relationshipFieldName="relationshipWithHolder"
                          firstNameFieldName="primaryHolderFirstName"
                          lastNameFieldName="primaryHolderLastName"
                          genderFieldName="primaryHolderGender"
                          dobFieldName="primaryHolderDOB"
                          addressFieldName="primaryHolderAddress"
                          cityFieldName="primaryHolderCity"
                          stateFieldName="primaryHolderState"
                          zipCodeFieldName="primaryHolderZipCode"
                          ssnFieldName="primaryHolderSSN"
                          countryFieldName="primaryHolderCountry"
                        />
                      </>
                    )}
                  </>
                )}

                <Column $fullWidth>
                  <Checkbox
                    name="hasMedicalInsurancePlan"
                    label={t('insurance.hasMedicalInsurancePlan')}
                    ref={register}
                    disabled={isFormExpired}
                    error={!!errors.hasMedicalInsurancePlan}
                    errorMessage={t(
                      errors.hasMedicalInsurancePlan?.message || '',
                      {
                        defaultValue: t('core.requiredSelection'),
                      }
                    )}
                  />
                </Column>

                {hasMedicalInsurancePlan && (
                  <>
                    <TwoColumns>
                      <TypeAhead
                        name="medicalInsuranceProvider"
                        label={t('insurance.medicalInsuranceProvider')}
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.medicalInsuranceProvider}
                        errorMessage={t(
                          errors.medicalInsuranceProvider?.message || '',
                          {
                            defaultValue: t('core.requiredSelection'),
                          }
                        )}
                        defaultValue=""
                        required
                        helperText={
                          <div>
                            {isMedicalInsurancePrimaryHolder !== 'false' &&
                              isEligibilityCheckEnabled(medicalInsuranceProvider) && (
                                <ModalEligibility
                                  insuranceProvider={medicalInsuranceProvider}
                                  primaryHolderDOB={primaryHolderDOB}
                                  primaryHolderFirstName={
                                    primaryHolderFirstName
                                  }
                                  primaryHolderLastName={primaryHolderLastName}
                                  primaryHolderSSN={primaryHolderSSN}
                                  primaryMemberID={primaryMemberID}
                                  primaryMemberSSN={primaryMemberSSN}
                                />
                              )}
                          </div>
                        }
                      >
                        <TypeAhead.Option value="" disabled>
                          --
                        </TypeAhead.Option>
                        {commonConfigs.medicalInsuranceProvider?.map(
                          ({ label, value }, index) => (
                            <TypeAhead.Option
                              value={value}
                              key={`provider-${label}-${index}`}
                            >
                              {t(`provider.${label}`, {
                                defaultValue: value,
                              })}
                            </TypeAhead.Option>
                          )
                        )}
                      </TypeAhead>

                      <TextField
                        name="medicalInsuranceProviderMemberId"
                        label={t('insurance.medicalInsuranceProviderMemberId')}
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.medicalInsuranceProviderMemberId}
                        errorMessage={t(
                          errors.medicalInsuranceProviderMemberId?.message ||
                          '',
                          {
                            defaultValue: t('core.requiredMessage'),
                          }
                        )}
                        $fullWidth
                        required
                        helperText={
                          <InsuranceTooltipHelper
                            label={t(
                              'insurance.medicalInsuranceProviderMemberIdHelperText'
                            )}
                          >
                            {t(
                              'insurance.medicalInsuranceProviderMemberIdTooltip'
                            )}
                          </InsuranceTooltipHelper>
                        }
                      />
                    </TwoColumns>

                    {isNewInsuranceDataSectionVisible && (
                      <>
                        {!!medicalInsuranceProvider && (
                          <InputGroup>
                            <InputGroup.Label required>
                              {t('insurance.isPrimaryHolder')}
                            </InputGroup.Label>

                            <InputGroup.Inputs>
                              <Radio
                                name="isMedicalInsurancePrimaryHolder"
                                label={t('core.yes')}
                                value="true"
                                ref={register}
                                disabled={isFormExpired}
                                error={!!errors.isMedicalInsurancePrimaryHolder}
                              />

                              <Radio
                                name="isMedicalInsurancePrimaryHolder"
                                label={t('core.no')}
                                value="false"
                                ref={register}
                                disabled={isFormExpired}
                                error={!!errors.isMedicalInsurancePrimaryHolder}
                              />
                            </InputGroup.Inputs>
                            <InputGroup.HelperText>
                              <InsuranceTooltipHelper
                                label={t('insurance.isPrimaryHolderHelperText')}
                              >
                                {t('insurance.isPrimaryHolderTooltip')}
                              </InsuranceTooltipHelper>
                            </InputGroup.HelperText>
                            <InputGroup.Errors
                              error={!!errors.isMedicalInsurancePrimaryHolder}
                              errorMessage={t(
                                errors.isMedicalInsurancePrimaryHolder?.message ||
                                '',
                                {
                                  defaultValue: t('core.requiredSelection'),
                                }
                              )}
                            />
                          </InputGroup>
                        )}
                        {isMedicalInsurancePrimaryHolder === 'false' && (
                          <>
                            <InsuranceData
                              isNewInsuranceDataSectionVisible={isNewInsuranceDataSectionVisible}
                              insuranceProvider={undefined}
                              relationshipFieldName="relationshipWithMedicalPrimaryHolder"
                              firstNameFieldName="medicalInsuranceHolderFirstName"
                              lastNameFieldName="medicalInsuranceHolderLastName"
                              genderFieldName="medicalInsuranceHolderGender"
                              dobFieldName="medicalInsuranceHolderDOB"
                              addressFieldName="medicalInsuranceHolderAddress"
                              cityFieldName="medicalInsuranceHolderCity"
                              stateFieldName="medicalInsuranceHolderState"
                              zipCodeFieldName="medicalInsuranceHolderZipCode"
                              ssnFieldName="medicalInsurancePrimaryHolderSSN"
                              countryFieldName="medicalInsurancePrimaryHolderCountry"
                          />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                <Column $fullWidth>
                  <Typography $weight="normal" $align="left">
                    {t('insurance.membershipDescription')}
                  </Typography>
                </Column>

                <TwoColumns>
                  <InputGroup>
                    <InputGroup.Label required>
                      {t('insurance.isAAAMember')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        name="isAAAMember"
                        label={t('core.yes')}
                        value="true"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.isAAAMember}
                      />

                      <Radio
                        name="isAAAMember"
                        label={t('core.no')}
                        value="false"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.isAAAMember}
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.isAAAMember}
                      errorMessage={t(errors.isAAAMember?.message || '', {
                        defaultValue: t('core.requiredSelection'),
                      })}
                    />
                  </InputGroup>

                  <InputGroup>
                    <InputGroup.Label required>
                      {t('insurance.isAARPMember')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        name="isAARPMember"
                        label={t('core.yes')}
                        value="true"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.isAARPMember}
                      />

                      <Radio
                        name="isAARPMember"
                        label={t('core.no')}
                        value="false"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.isAARPMember}
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.isAARPMember}
                      errorMessage={t(errors.isAARPMember?.message || '', {
                        defaultValue: t('core.requiredSelection'),
                      })}
                    />
                  </InputGroup>
                </TwoColumns>

                {country === 'CA' && (
                  <InputGroup>
                    <InputGroup.Label required>
                      {t('insurance.isHMOMember')}
                    </InputGroup.Label>

                    <InputGroup.Inputs>
                      <Radio
                        name="isHMOMember"
                        label={t('core.yes')}
                        value="true"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.isHMOMember}
                      />

                      <Radio
                        name="isHMOMember"
                        label={t('core.no')}
                        value="false"
                        ref={register}
                        disabled={isFormExpired}
                        error={!!errors.isHMOMember}
                      />
                    </InputGroup.Inputs>
                    <InputGroup.Errors
                      error={!!errors.isHMOMember}
                      errorMessage={t(errors.isHMOMember?.message || '', {
                        defaultValue: t('core.requiredSelection'),
                      })}
                    />
                  </InputGroup>
                )}
              </form>
            </FormProvider>
          </StepLayout>

          <AppointmentRecap />
        </Layout.Content>

        <Layout.Footer>
          <Button
            $outlined
            onClick={() => previous()}
            data-element-id={
              isFromReview ? 'X_OEE_View&Edit_Back' : 'X_OEE_Insurance_Back'
            }
            $compact
          >
            {t('core.back')}
          </Button>

          {!isFormExpired && (
            <Button
              type="submit"
              form={id}
              data-element-id={
                isFromReview
                  ? 'X_OEE_View&Edit_Continue'
                  : 'X_OEE_Insurance_Confirm'
              }
              disabled={isLoading}
              $compact
            >
              {t(isFromReview ? 'core.saveChanges' : 'core.continue')}
            </Button>
          )}
        </Layout.Footer>
      </Layout>
    )
  }

  return null
}