import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import clsx from 'clsx'

import { Typography } from '../Typography'
import { useConfig } from '@root/Context'
import { StyledSpan } from './Radio.styling'

export interface RadioProps extends React.ComponentPropsWithRef<'input'> {
  label: string
  error?: boolean
  errorMessage?: string
  $transform?: CSSProperties['textTransform']
}

const StyledInput = styled.input<Omit<RadioProps, 'label'>>`
  display: none;

  & + span::before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 1rem;
    height: 1rem;
    border-style: solid;
    border-width: 1px;
    border-color: ${({ error, theme: { colors } }) =>
      error ? colors.error : colors.darkShade};
    margin-right: 10px;
    border-radius: 50%;
  }

  &:checked + span::before {
    content: '';
    color: white;
    text-align: center;
    background-color: ${({ theme: { colors } }) => colors.primary};
    box-shadow: inset 0px 0px 0px 2px white;
  }
`

const StyledLabel = styled.label`
  position: relative;
`

const StyledError = styled(Typography)`
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: left;

  &::first-letter {
    text-transform: uppercase;
  }
`

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      label,
      className,
      error,
      errorMessage,
      $transform = 'capitalize',
      ...props
    },
    ref
  ) => {
    const { brand } = useConfig()

    return (
      <StyledLabel
        className={clsx(
          'intake__radio',
          {
            'intake__radio--error': error,
          },
          className
        )}
      >
        <StyledInput
          type="radio"
          error={error}
          {...props}
          ref={ref}
          aria-invalid={error ? 'true' : 'false'}
        />
        {label && (
          <StyledSpan
            storeName={brand}
            $variant="span"
            $transform={$transform}
            required={props.required}
          >
            {label}
          </StyledSpan>
        )}
        {error && (
          <StyledError $variant="span" $color="error" role="alert">
            {errorMessage}
          </StyledError>
        )}
      </StyledLabel>
    )
  }
)
