/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Appointment } from '../models/Appointment'
import { request as __request } from '../core/request'

export class AppointmentService {
  /**
   * Get appointment information using own ID.
   * This method retrieve information about the appointment related to a form, with some data about the patient and the clinic.
   * @returns Appointment OK
   * @throws ApiError
   */
  public static async getAppointment({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<Appointment> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/appointment/${appointmentId}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  public static async getAnzAppointment({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<Appointment> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/appointment/${appointmentId}/detail`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }
}
