import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  background-color: white;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  height: ${({ theme: { footerHeight } }) => footerHeight};
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;
`

const Wrapper = styled.div`
  margin: auto;
  width: calc(100% - 30px);
  max-width: 1022px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

export interface FooterProps extends React.ComponentPropsWithRef<'footer'> {}

export const Footer: React.FC<FooterProps> = ({ children, ...props }) => (
  <StyledFooter {...props}>
    <Wrapper>{children}</Wrapper>
  </StyledFooter>
)
