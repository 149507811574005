import React from 'react'
import SmoothCollapse, {
  Props as SmoothCollapseProps,
} from 'react-smooth-collapse'
import {
  isoStringToDateString,
  isoStringToLocaleDateString,
} from '../../utils/date'
import { useTheme } from 'styled-components'
import { useMedia, useToggle, useUpdateEffect } from 'react-use'
import { useTranslation } from 'react-i18next'

import * as Styles from './themes/AppointmentRecapStyles'
import { useFormMode } from '../../hooks/useFormMode'
import { useConfig } from '@root/Context'
import { Appointment } from '../../api'

import { Icon } from '../Icon'
import { Loader } from '../Loader'
import { Typography } from '../Typography'
import clsx from 'clsx'
import { useTabApiAppointment } from '../../services/TabApiProvider/ProviderAppointment'

import EyeIcon from '../../assets/icons/eye.svg'
import CalendarEyeIcon from '../../assets/icons/opsm/calendar-eye.svg'

export interface AppointmentRecapProps
  extends Omit<SmoothCollapseProps, 'expanded'> {
  expanded?: boolean
  data?: Appointment
  isLoading: boolean
  additionalTop?: number
}

export const AppointmentRecapWidget: React.FC<AppointmentRecapProps> = ({
  children,
  className,
  expanded = false,
  data,
  isLoading,
  additionalTop,
  ...props
}) => {
  const {
    breakpoints: { device },
  } = useTheme()
  const isDesktop = useMedia(device.large)
  const { i18n, t } = useTranslation()
  const locale = i18n.language
  const [isExpanded, setExpanded] = useToggle(expanded || isDesktop)

  const { brand } = useConfig()

  useUpdateEffect(() => {
    setExpanded(expanded)
  }, [expanded])

  useUpdateEffect(() => {
    setExpanded(isDesktop)
  }, [isDesktop])

  if (isLoading) {
    return (
      <Styles.StyledWrapper
        className={clsx(
          'intake__appointmentrecap',
          {
            'intake__appointmentrecap--expanded': isExpanded,
          },
          className
        )}
        storeName={brand}
        additionalTop={additionalTop}
      >
        <Loader height={290} />
      </Styles.StyledWrapper>
    )
  }

  if (data) {
    const { doctor, patient, clinic, date } = data

    const DOB: React.FC = () =>
      patient.dob ? (
        <Styles.StyledInfoWrapper>
          <Typography $align="left">{t('core.dob')}</Typography>
          <Typography $align="left">
            {isoStringToDateString({
              isoString: patient.dob,
              brand,
            })}
          </Typography>
        </Styles.StyledInfoWrapper>
      ) : null

    return (
      <Styles.StyledWrapper
        className={clsx(
          'intake__appointmentrecap',
          {
            'intake__appointmentrecap--expanded': isExpanded,
          },
          className
        )}
        storeName={brand}
        additionalTop={additionalTop}
      >
        <Styles.StyledHeader>
          {brand === 'LC' ? (
            <Icon className="intake__icon--eye" src={EyeIcon} />
          ) : null}
          {brand === 'OPSM' ? (
            <Icon className="intake__icon--eye" src={CalendarEyeIcon} />
          ) : null}
          <Typography
            $weight={brand === 'OPSM' ? 'bold' : 'normal'}
            $align="left"
          >
            {t('core.yourEyeExam', { clinicName: clinic.name })}
          </Typography>
          {!isDesktop && (
            <Styles.StyledOpener onClick={setExpanded}>
              <Styles.StyledArrowIcon expanded={isExpanded} />
            </Styles.StyledOpener>
          )}
        </Styles.StyledHeader>
        <SmoothCollapse expanded={isExpanded} {...props}>
          <Styles.StyledColumnWrapper
            as={isDesktop ? Styles.StyledColumnWrapper : Styles.StyledFragment}
          >
            <Styles.Columns
              style={{
                width: `${isDesktop ? '77%' : 'auto'}`,
              }}
            >
              <Styles.StyledInfoWrapper>
                <Typography>{t('core.patient')}</Typography>
                <Typography>
                  {patient.firstName} {patient.lastName}
                </Typography>
              </Styles.StyledInfoWrapper>

              {doctor ? (
                <Styles.StyledInfoWrapper>
                  <Typography>{t('core.doctor')}</Typography>
                  <Typography>
                    {doctor.firstName} {doctor.middleName} {doctor.lastName}
                  </Typography>
                </Styles.StyledInfoWrapper>
              ) : null}

              <Styles.StyledInfoWrapper>
                <Typography>{t('core.when')}</Typography>
                <Typography
                  style={{
                    overflow: 'visible',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {isoStringToLocaleDateString({
                    isoString: date,
                    locale,
                    includeHours: true,
                    customMask: 'PPPp',
                  })}
                </Typography>
              </Styles.StyledInfoWrapper>

              {!isDesktop && <DOB />}
            </Styles.Columns>
            {isDesktop && (
              <Styles.Columns>
                <DOB />
              </Styles.Columns>
            )}
          </Styles.StyledColumnWrapper>
        </SmoothCollapse>
      </Styles.StyledWrapper>
    )
  }

  return null
}

export const AppointmentRecap: React.FC<
  Pick<AppointmentRecapProps, 'expanded' | 'additionalTop'>
> = ({ expanded = false, additionalTop }) => {
  const { data, isLoading: isLoadingAppointment } = useTabApiAppointment()
  const { showAppointmentInfo } = useFormMode()

  if (showAppointmentInfo) {
    return (
      <AppointmentRecapWidget
        data={data}
        expanded={expanded}
        isLoading={isLoadingAppointment}
        additionalTop={additionalTop}
      />
    )
  }

  return null
}
