import { useMemo } from 'react'
import { useConfig } from '../Context'
import {
  isAnzForm,
  useTabApiForm,
} from '@services/TabApiProvider/hooks/useTabApiForm'

export type IntakeFormMode = {
  error: Error | undefined
  isLoading: boolean
  isReadOnly: boolean
  isBackOffice: boolean
  startFromReview: boolean
  shouldUpdateStep: boolean
  shouldUpdateUserData: boolean
  showContinueLater: boolean
  showAppointmentInfo: boolean
}

const readonlyAppointmentStatuses = ['BILLED', 'ARRIVED']

export const useFormMode = (): IntakeFormMode => {
  const { isBackOffice } = useConfig()
  const { data, isLoading, error } = useTabApiForm()

  const isCompleted = data?.step === 'COMPLETED'
  const isCancelled = data?.step === 'CANCELLED'
  const isFormExpired = isAnzForm(data)
    ? data?.statusForm === 'EXPIRED'
    : data?.status === 'EXPIRED'

  const isReadOnly =
    isFormExpired ||
    (isAnzForm(data) &&
      readonlyAppointmentStatuses.includes(data?.statusAppointment ?? ''))

  return useMemo(() => {
    return {
      isLoading,
      error,
      startFromReview: isReadOnly || isCompleted || isCancelled,
      isReadOnly,
      isBackOffice,
      shouldUpdateStep: true,
      shouldUpdateUserData: !isReadOnly,
      showContinueLater: !isBackOffice && !isReadOnly,
      showAppointmentInfo: !isBackOffice && !isFormExpired,
    }
  }, [isLoading, error, isBackOffice, isReadOnly, isCompleted, isCancelled])
}
