import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css, useTheme } from 'styled-components'
import { Toggle } from '../Toggle'
import { TextField } from '../TextField'
import { Checkbox } from '../Checkbox'
import { Button } from '../Button'
import { InputGroup } from '../InputGroup'
import { useToggle, useWindowSize } from 'react-use'
import SignatureCanvas from 'react-signature-canvas'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import { DocumentType } from '../../api'
import isBase64 from 'is-base64'
import useIsAnz from '@hooks/useIsAnz'
export interface SignatureProps {
  onSubmit: (data: {
    signature: string
    signatureType: 'DRAW' | 'TEXT'
    hasAccepted: boolean
    event: React.MouseEvent
  }) => void
  docType: DocumentType
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f2f2f2;
  padding: 20px 0;
  margin: 0 !important;
  text-align: left;
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      padding: 40px;
    }
  `}

  & > * + * {
    margin-bottom: 33px;
  }
`

const SignatureCanvasWrapper = styled.div`
  position: relative;

  &::after {
    content: '';
    width: 90%;
    height: 1px;
    background-color: #b5b5b5;
    position: absolute;
    left: 5%;
    top: 75%;
  }
`

const SignaturePadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      align-items: flex-end;
      flex-direction: row;
    }
  `}

  & canvas {
    border-radius: 10px;
    border: 2px solid #666;
  }
`

const ClearDrawnSignature = styled.button`
  background: none;
  color: ${({ theme: { colors } }) => colors.primary};
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline;
  text-transform: capitalize;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      margin-left: 21px;
    }
  `}

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme: { colors } }) => colors.disabled};
  }
`

const ConfirmAndSubmitButton = styled(Button)`
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      width: auto;
    }
  `}
`

export const Signature: React.FC<SignatureProps> = ({ onSubmit, docType }) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const isAnz = useIsAnz()
  const { data: formData } = useTabApiForm()
  const isPrivacyDocument = docType === DocumentType.PRIVACY
  const isHealthDocument = docType === DocumentType.HEALTH
  const isSignedPrivacy = !!formData?.optPrivacyPractice?.signature
  const isSignedHealth = !!formData?.optHealthInformation?.signature
  const isSigned = isPrivacyDocument ? isSignedPrivacy : isSignedHealth
  const [hasAccepted, toggleHasAccepted] = useToggle(isSigned)
  const [signature, setSignature] = useState<string>(() => {
    if (isPrivacyDocument) {
      return formData?.optPrivacyPractice?.signature || ''
    }

    if (isHealthDocument) {
      return formData?.optHealthInformation?.signature || ''
    }

    return ''
  })
  const [drawIt, toggleSignatureKind] = useToggle(
    isBase64(signature, {
      allowEmpty: false,
      mimeRequired: true,
    })
  )
  const sigRef = useRef<SignatureCanvas>(null)
  const { width } = useWindowSize()

  const handleSubmit = (event: React.MouseEvent) => {
    onSubmit({
      signature,
      hasAccepted,
      signatureType: drawIt ? 'DRAW' : 'TEXT',
      event,
    })
  }

  const handleDrawEnd = () => {
    if (sigRef.current) {
      const signature = sigRef.current.toDataURL()

      setSignature(signature)
    }
  }

  const handleDrawClear = () => {
    if (sigRef.current) {
      sigRef.current.clear()

      setSignature('')
    }
  }

  useEffect(() => {
    if (isSigned) {
      if (signature) {
        if (
          isBase64(signature, {
            mimeRequired: true,
            allowEmpty: false,
          })
        ) {
          if (sigRef.current) {
            sigRef.current.fromDataURL(signature)
            sigRef.current.off()
          }
        }
      }
    }
  }, [isSigned, signature])

  return (
    <StyledWrapper>
      {!isSigned && (
        <InputGroup>
          <InputGroup.Label>
            {t('legalDocument.legalGuardianSignature')}
          </InputGroup.Label>

          <Toggle
            offLabel={t('core.typeIt')}
            onLabel={t('core.drawIt')}
            name="signatureKind"
            value="signature"
            checked={drawIt}
            onChange={() => {
              setSignature('')
              toggleSignatureKind()
            }}
          />
        </InputGroup>
      )}

      {!drawIt && (
        <TextField
          label={t('core.fullName')}
          name="signature"
          value={signature}
          onChange={({ currentTarget: { value } }) => setSignature(value)}
          required
          disabled={isSigned}
        />
      )}

      {drawIt && (
        <SignaturePadWrapper>
          <SignatureCanvasWrapper>
            <SignatureCanvas
              penColor={colors.darkShade}
              canvasProps={{
                width: Math.min(width - 30, 335),
                height: 87,
              }}
              ref={sigRef}
              clearOnResize={false}
              backgroundColor="white"
              onEnd={handleDrawEnd}
            />
          </SignatureCanvasWrapper>

          {!isSigned && (
            <ClearDrawnSignature
              onClick={handleDrawClear}
              disabled={signature.length === 0}
            >
              {t('clear')}
            </ClearDrawnSignature>
          )}
        </SignaturePadWrapper>
      )}

      {!isSigned && (
        <>
          <Checkbox
            checked={hasAccepted}
            label={t(`legalDocument.healthInformationAuthorization${isAnz ? 'Anz' : 'Us'}`)}
            onChange={toggleHasAccepted}
          />
          <ConfirmAndSubmitButton
            disabled={!(hasAccepted && signature.length !== 0)}
            onClick={handleSubmit}
            data-element-id="X_OEE_Intro_Legal-Submit"
          >
            {t('core.confirmAndSubmit')}
          </ConfirmAndSubmitButton>
        </>
      )}
    </StyledWrapper>
  )
}
