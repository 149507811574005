import type { Otp, OtpCheck, CensoredContacts } from '../models/Otp'
import { request as __request } from '../core/request'
import { CountryType } from '@root/types/config'

export class OtpService {
  /**
   * Returns censored phone and email.
   * @returns Otp OK
   * @throws ApiError
   */
  public static async getCensoredContacts({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
    acceptedLanguage,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** Language in use */
    acceptedLanguage: 'en-NZ' | 'en-AU'
  }): Promise<CensoredContacts> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/appointment/${appointmentId}/otp/censoredContacts`,
      headers: {
        caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Accept-Language': acceptedLanguage,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })

    return result.body
  }

  /**
   * Generate OTP before showing form.
   * @returns Otp OK
   * @throws ApiError
   */
  public static async generateOtp({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
    acceptedLanguage,
    system,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** Language in use */
    acceptedLanguage: `en-${CountryType}`
    /** Device where receive OTP */
    system: 'mail' | 'sms'
  }): Promise<Otp> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/appointment/${appointmentId}/otp`,
      query: {
        system,
      },
      headers: {
        caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Accept-Language': acceptedLanguage,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })

    return result.body
  }

  /**
   * Check if OTP is correct.
   * @returns Appointment OK
   * @throws ApiError
   */
  public static async checkOtp({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
    otp,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** OTP Code */
    otp: string
  }): Promise<OtpCheck> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/appointment/${appointmentId}/checkOTP`,
      query: {
        otp,
      },
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },

      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })

    return result.body
  }

  /**
   * Check if OTP is correct.
   * @returns Appointment OK
   * @throws ApiError
   */
  public static async invalidateOtp({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<'OTP invalidated'> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/appointment/${appointmentId}/invalidateOTP`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },

      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })

    return result.body
  }
}
