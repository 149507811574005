import * as yup from 'yup'
import { Form } from '../../api'

export const getHealthHistorySchema = () => {
  const schema = {
    hasTreatedMedicalConditions: yup
      .string()
      .oneOf(['true', 'false'])
      .notRequired(),
    treatedMedicalConditionsDescription: yup
      .string()
      .when('hasTreatedMedicalConditions', {
        is: 'true',
        then: yup.string().max(300).required('core.requiredMessage'),
        otherwise: yup.string().max(300).notRequired(),
      }),
    hasEyeInjuriesSurgeries: yup.string().oneOf(['true', 'false']).required(),
    eyeInjuriesSurgeriesDescription: yup
      .string()
      .when('hasEyeInjuriesSurgeries', {
        is: 'true',
        then: yup.string().max(300).required('core.requiredMessage'),
        otherwise: yup.string().max(300).notRequired(),
      }),
    hasCurrentlyMedications: yup
      .string()
      .oneOf(['true', 'false'])
      .required('healthHistory.hasMedicationsRequiredMessage'),
    currentlyMedicationsDescription: yup
      .string()
      .when('hasCurrentlyMedications', {
        is: 'true',
        then: yup.string().max(300).required('core.requiredMessage'),
        otherwise: yup.string().max(300).notRequired(),
      }),
    hasAllergies: yup
      .string()
      .oneOf(['true', 'false'])
      .required('healthHistory.hasAllergiesRequiredMessage'),
    allergiesDescription: yup.string().when('hasAllergies', {
      is: 'true',
      then: yup
        .string()
        .max(300)
        .required('healthHistory.allergiesDescriptionRequiredMessage'),
      otherwise: yup.string().max(300).notRequired(),
    }),
    isPregnant: yup.string().oneOf(['true', 'false']).notRequired(),
    isCurrentlyNursing: yup.string().oneOf(['true', 'false']).notRequired(),
    // .required('healthHistory.isCurrentlyNursingRequiredMessage'),  TODO: this should be required when gender female
    isSmoker: yup
      .string()
      .oneOf(['true', 'false'])
      .required('healthHistory.isSmokerRequiredMessage'),
    isDrinkAlcohol: yup
      .string()
      .oneOf(['true', 'false'])
      .required('healthHistory.isDrinkAlcoholRequiredMessage'),
    healthConditions: yup
      .array()
      .of(
        yup
          .object({
            conditionName: yup.string().required(),
            isSelf: yup.boolean().required(),
            isRelatives: yup.boolean().required(),
            relatives: yup.array().of(yup.string().required()).notRequired(),
          })
          .required()
      )
      .notRequired(),
  }

  return yup.object(schema).defined()
}

export type HealthHistoryType = Omit<
  yup.InferType<ReturnType<typeof getHealthHistorySchema>>,
  'healthConditions'
> & {
  healthConditions: Form['healthConditions']
}
