import React, { createContext, FC, useCallback, useContext } from 'react'
import { useAsync } from 'react-async'
import { Appointment, AppointmentService } from '../../api'
import { useConfig } from '../../Context'
import useIsAnz from '@hooks/useIsAnz'

// #region ::: PARTIALS
// HOOK
export const useTabApiAppointment = () => useContext(TabApiAppointmentContext)

// CONTEXT
export const TabApiAppointmentContext = createContext(
  {} as ContextAppointmentValueType
)

// TYPES
const useAsyncWithAppointment = () => useAsync<Appointment>({})
type ContextAppointmentValueType = ReturnType<typeof useAsyncWithAppointment>
// #endregion

export const ProviderAppointment: FC = ({ children }) => {
  const {
    brand,
    appointmentId,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
  } = useConfig()

  const isAnz = useIsAnz()

  const getAppointment = useCallback(() => {
    if (isAnz) {
      return AppointmentService.getAnzAppointment({
        appointmentId,
        brand,
        caller,
        ocpApimSubscriptionKey,
      })
    }

    return AppointmentService.getAppointment({
      appointmentId,
      brand,
      caller,
      ocpApimSubscriptionKey,
    })
  }, [appointmentId, brand, caller, isAnz, ocpApimSubscriptionKey])

  const tabApiAppointmentContextValue = useAsync<Appointment>({
    promiseFn: getAppointment,
  })

  return (
    <TabApiAppointmentContext.Provider value={tabApiAppointmentContextValue}>
      {children}
    </TabApiAppointmentContext.Provider>
  )
}
