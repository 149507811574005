// #region ::: IMPORTS

import { fields, widgets } from '@components/RJSF'
import { validateFormat } from '@components/RJSF/customFormats'
import CustomGridLayoutTemplate from '@components/RJSF/CustomGridLayoutTemplate'
import { useFormMode } from '@hooks/useFormMode'
import Form, {
  AjvError,
  ErrorSchema,
  FormValidation,
  IChangeEvent
} from '@rjsf/core'
import { formConstructor } from '@root/anz/types/anzTypes'
import {
  AnzForm,
  AnzForm as AnzFormType,
  QuestionAnswer
} from '@root/api/models/Form'
import { useConfig } from '@root/Context'
import { useTabApiForm } from '@root/services/TabApiProvider/hooks/useTabApiForm'
import { isObjectEmpty } from '@root/utils/objectUtils'
import { diff } from 'deep-object-diff'
import { get, mapValues } from 'lodash'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  checkMutuallyExclusiveProperties,
  clearFieldIfNotUsed, medicareCardNumberValidationFn, medicareDvaNumberValidationFn, parsedError
} from './RJSF.utils'

// #endregion

type JSONSchemaFormProps = {
  disabled: boolean
  jsonSchema: formConstructor['jsonSchema']
  uiSchema: formConstructor['uiSchema']
  formData: QuestionAnswer
  formInfo: Omit<AnzForm, 'questionAnswer'>
  setFormData: (form: QuestionAnswer) => void
  onChange: (hasErrors: boolean) => void
  onSubmit: (hasErrors: boolean) => void
}

const removeEmptyArrays = (formData: QuestionAnswer) => mapValues(formData, (value) => {
  if (Array.isArray(value) && value.length === 0) {
    return undefined
  }

  return value
})

/**
 * For the future/for long term support, discuss with BE about
 * adding an array in JSONSchema that return us mutually exclusive properties.
 */
const mutuallyExclusiveProperties = ['cellPhone', 'homePhone']
const dependentPropertyGroups = [
  ['medicareCardNumber', 'medicareDvaNumber']
]

const customValidationRules = [
  {
    page: '1',
    validationFn: medicareDvaNumberValidationFn,
  },
  {
    page: '1',
    validationFn: medicareCardNumberValidationFn,
  }
]

const JSONSchemaForm: React.FC<JSONSchemaFormProps> = ({
  disabled,
  jsonSchema,
  uiSchema,
  formData,
  formInfo,
  setFormData,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const { country } = useConfig()
  const { isReadOnly } = useFormMode()

  const { run } = useTabApiForm<AnzFormType>()

  const formRef = useRef<Form<QuestionAnswer>>(null)

  const [liveErrors, setLiveErrors] = useState<ErrorSchema>({})

  const handleChange = (e: IChangeEvent) => {
    const fieldsChanged: Record<string, any> = diff(formData, e.formData)

    if (!isObjectEmpty(fieldsChanged)) {
      const newErrorSchema: ErrorSchema = {}
      const diffKey = Object.keys(fieldsChanged)[0]

      // If the edited field is an array, when the diff function checks the difference,
      // it will return as an object and will show an undesidered error. So, if it is
      // an array, the object is converted to array.
      if (Array.isArray(formData[diffKey])) {
        const arrayValues = e.formData[diffKey]

        fieldsChanged[diffKey] = arrayValues.length !== 0 ? arrayValues : undefined
      }

      const validationResult = formRef.current?.validate(
        { ...(fieldsChanged as QuestionAnswer), isValidationSingleField: true }
      )

      const errorSchemaForSelectedKey = get(
        validationResult?.errorSchema,
        diffKey
      )

      if (mutuallyExclusiveProperties.includes(diffKey)) {
        mutuallyExclusiveProperties.forEach((p) => {
          const errorSchemaForMutuallyExclusiveKey = get(
            validationResult?.errorSchema,
            p
          )

          newErrorSchema[p] = errorSchemaForMutuallyExclusiveKey!
        })
      } else {
        newErrorSchema[diffKey] = errorSchemaForSelectedKey!
      }

      const touchedDependantProperties = dependentPropertyGroups.find(group => group.includes(diffKey))
      if (!!touchedDependantProperties?.length) {
        const dependantPropertiesObject = touchedDependantProperties.reduce((acc, currKey) => ({ ...acc, [currKey]: e.formData[currKey] }), {})

        const validationResult = formRef.current?.validate(
          {
            ...dependantPropertiesObject,
            isValidationSingleField: true,
          }
        )

        touchedDependantProperties.forEach(prop => {
          const errorSchemaForDependantProperties = get(
            validationResult?.errorSchema,
            prop
          )

          newErrorSchema[prop] = errorSchemaForDependantProperties!
        })
      }

      setLiveErrors((currentErrorSchema) => ({
        ...currentErrorSchema,
        ...newErrorSchema,
      }))
    }

    setFormData(e.formData)

    const dataToValidate = removeEmptyArrays(e.formData)
    onChange(!!formRef.current?.validate(dataToValidate as any)?.errors.length)
  }

  const customFormats = validateFormat(country || 'AU')

  const transformErrors = (errors: AjvError[]): AjvError[] =>
    errors.map((error) => ({
      ...error,
      message: parsedError(error.name, error.message, t),
    }))

  const validate = (formData: QuestionAnswer, errors: FormValidation) => {
    const properties = jsonSchema.properties!

    // This if is necessary because when validation is called on fingle field (inside handleChange),
    // the formData will contain only the modified field and if it is empty the checkMutuallyExclusiveProperties will always return error
    // because the other formData will not container the other "mutually excluded field"
    if (formData.isValidationSingleField !== true) {
      checkMutuallyExclusiveProperties(
        errors,
        formData,
        properties,
        mutuallyExclusiveProperties,
        t
      )
    }

    customValidationRules.forEach(rule => {
      if (rule.page === jsonSchema.page) {
        rule.validationFn({ formData, errors, t, customFormats })
      }
    })

    return errors
  }

  const handleSubmit = (compiledFormData: QuestionAnswer) => {
    if (isReadOnly) {
      onSubmit(false)
      return
    }

    const parsedFormData = removeEmptyArrays(compiledFormData)

    const validationResult = formRef.current?.validate(
      parsedFormData as QuestionAnswer
    )

    if (validationResult?.errors.length === 0) {
      const clearedFormData = clearFieldIfNotUsed(jsonSchema, compiledFormData)

      // Send to server
      run({
        ...formInfo,
        questionAnswer: clearedFormData,
      })

      // Update local state
      setFormData(clearedFormData)

      // Going to next page if necessary
      onSubmit(false)
    } else {
      setLiveErrors(validationResult?.errorSchema || {})
      onSubmit(true)
    }
  }

  return (
    <Form
      ref={formRef}
      noValidate
      onChange={handleChange}
      disabled={disabled}
      validate={validate}
      extraErrors={liveErrors}
      showErrorList={false}
      onSubmit={({ formData }) => handleSubmit(formData)}
      schema={jsonSchema}
      widgets={widgets}
      fields={fields}
      uiSchema={uiSchema}
      formData={formData}
      ObjectFieldTemplate={CustomGridLayoutTemplate}
      transformErrors={transformErrors}
      customFormats={customFormats}
      id="theForm"
    />
  )
}

export default JSONSchemaForm
