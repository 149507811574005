import { Helmet } from 'react-helmet'
import {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'

export type StoreNameProps = {
  storeName: BrandName
}

export type BrandName = 'LC' | 'OPSM' | 'PV' | 'TO' | 'FE' | 'VT'

const size = {
  huge: '1440px',
  large: '1170px',
  medium: '768px',
  small: '450px',
} as const

const device = {
  small: `(min-width: ${size.small})`,
  medium: `(min-width: ${size.medium})`,
  large: `(min-width: ${size.large})`,
  huge: `(min-width: ${size.huge})`,
} as const

const media = {
  small: `@media only screen and ${device.small}`,
  medium: `@media only screen and ${device.medium}`,
  large: `@media only screen and ${device.large}`,
  huge: `@media only screen and ${device.huge}`,
  tabletOnly: `@media only screen and ${device.small} and (max-width: ${size.large})`,
} as const

declare module 'styled-components' {
  type Color =
    // LC - default
    Record<'primary' | 'error' | 'disabled' | 'darkShade', string> &
      // OPSM
      Partial<
        Record<
          | 'gold'
          | 'offWhite'
          | 'white'
          | 'offer'
          | 'green'
          | 'blue'
          | 'grey'
          | 'mediumGrey'
          | 'softGrey'
          | 'lightGrey',
          string
        >
      >

  export interface DefaultTheme {
    headerHeight: string
    footerHeight: string
    fonts: {
      heading: string
      paragraph: string
    }

    colors: Color

    breakpoints: {
      size: typeof size
      device: typeof device
      media: typeof media
    }
  }
}

export const defaultTheme: DefaultTheme = {
  headerHeight: '54px',
  footerHeight: '64px',
  fonts: {
    heading: 'normal normal 500 25px/normal sofia-pro',
    paragraph: 'normal normal 200 16px/normal sofia-pro',
  },
  colors: {
    primary: '#326FA8',
    disabled: '#666666',
    darkShade: '#333333',
    error: '#D0021B',
  },
  breakpoints: {
    size,
    device,
    media,
  },
}

const opsmTheme: DefaultTheme = {
  headerHeight: '54px',
  footerHeight: '64px',
  fonts: {
    heading: 'normal normal 600 32px/normal Barlow',
    paragraph: 'normal normal 400 16px/normal Barlow',
  },
  colors: {
    primary: '#2D2A26',
    gold: '#B58B34',
    offWhite: '#F7F6F4',
    white: '#FFFFFF',

    darkShade: '#333333',
    disabled: '#666666',
    grey: '#999999',
    mediumGrey: '#CCCCCC',
    softGrey: '#E5E5E5',
    lightGrey: '#F6F6F6',

    error: '#E1523E',
    offer: '#B63535',
    green: '#3F8350',
    blue: '#3D87CB',
  },
  breakpoints: {
    size,
    device,
    media,
  },
}

export const getThemeBasedOnStore = (
  storeName: BrandName = 'LC'
): DefaultTheme => {
  switch (storeName) {
    case 'OPSM':
      return opsmTheme
    case 'LC':
      return defaultTheme
    case 'PV':
      return defaultTheme
    case 'TO':
      return defaultTheme
    case 'FE':
      return defaultTheme
    case 'VT':
      return defaultTheme
  }
}

export const getFontsBasedOnStore = (
  storeName: BrandName = 'LC'
): JSX.Element => {
  switch (storeName) {
    case 'LC':
      return (
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/tzs3jig.css" />
        </Helmet>
      )
    case 'PV':
      return (
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/tzs3jig.css" />
        </Helmet>
      )
    case 'TO':
      return (
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/tzs3jig.css" />
        </Helmet>
      )
    case 'FE':
      return (
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/tzs3jig.css" />
        </Helmet>
      )
    case 'VT':
      return (
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/tzs3jig.css" />
        </Helmet>
      )
    case 'OPSM':
      return (
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin=""
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
            rel="stylesheet"
          />
        </Helmet>
      )
  }
}

export const stylesBasedOnThemes = ({
  storeName,
  styles,
}: {
  storeName: BrandName
  styles: Record<BrandName, FlattenInterpolation<ThemeProps<DefaultTheme>>>
}) => styles[storeName]
