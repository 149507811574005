import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import clsx from 'clsx'
import { useMedia, useToggle } from 'react-use'
import { DocumentModal } from './DocumentModal'
import { LegalSignModal } from './LegalSignModal'

import { Icon } from '@components/Icon'
import { Typography } from '@components/Typography'
import { Loader } from '@components/Loader'
import { Button } from '@components/Button'

import CheckIcon from '@assets/icons/check-black.svg'
import ExclamationIcon from '@assets/icons/exclamation-black.svg'

import { DocumentType } from '@api'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'

import { legal } from '@utils/routes'
import useIsAnz from '@hooks/useIsAnz'

interface DocumentSectionProps {
  isLegalSignModalVisible: boolean
  toggleLegalSignModalVisibility: () => void
  isPrivacyDocumentVisible: boolean
  isHealthDocumentVisible: boolean
}

const DocumentWrapper = styled.div`
  background-color: white;
  padding: 25px 35px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.media.medium} {
    flex-direction: row;
  }
`

const StyledIcon = styled(Icon)`
  width: 35px;
  height: 35px;
`

const TypographyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.media.medium} {
    flex-direction: row;

    & p {
      margin-left: 20px;
    }
  }
`

const Wrapper = styled.section`
  margin-bottom: 40px;

  & p {
    margin-left: 5px;
  }
`

const DocumentSection: React.FC<DocumentSectionProps> = ({
  isLegalSignModalVisible,
  toggleLegalSignModalVisibility,
  isPrivacyDocumentVisible,
  isHealthDocumentVisible,
}) => {
  const isAnz = useIsAnz()
  const history = useHistory()
  const [documentModalType, setDocumentModalType] = useState<DocumentType>(
    DocumentType.PRIVACY
  )
  const {
    breakpoints: { device },
  } = useTheme()
  const isDesktop = useMedia(device.large)
  const { t } = useTranslation()
  const { data, isLoading: isLoadingForm } = useTabApiForm()

  const isPrivacyPracticeSigned = data?.optPrivacyPractice?.signature
  const isHealthInformationSigned = data?.optHealthInformation?.signature

  const [isDocumentModalVisible, toggleDocumentModalVisibility] =
    useToggle(false)

  const documentPageHandler = (docType: DocumentType) => {
    toggleDocumentModalVisibility(true)
    setDocumentModalType(docType)
  }

  if (isLoadingForm) {
    return <Loader height={150} />
  }

  if (data) {
    return (
      <>
        <Wrapper id="document-section">
          <Typography $variant="h2">
            {t(
              isPrivacyPracticeSigned && isHealthInformationSigned
                ? 'intro.legalTitleDocumentSigned'
                : (isAnz ? 'intro.legalTitleDocumentSignToProcede' : 'intro.legalTitleDocumentNotSigned')
            )}
          </Typography>

          {isPrivacyDocumentVisible && (
            <DocumentWrapper id="privacy-document">
              <TypographyWrapper>
                <StyledIcon
                  className={clsx({
                    'intake__icon-check': isPrivacyPracticeSigned,
                    'intake__icon-exclamation': !isPrivacyPracticeSigned,
                  })}
                  src={isPrivacyPracticeSigned ? CheckIcon : ExclamationIcon}
                />
                <Typography $weight="normal">
                  {t('intro.privacyDescription')}
                </Typography>
              </TypographyWrapper>

              <Button
                $outlined
                onClick={() => documentPageHandler(DocumentType.PRIVACY)}
                data-element-id={
                  isPrivacyPracticeSigned
                    ? 'X_OEE_Intro_Legal-Read'
                    : 'X_OEE_Intro_Legal-ReadandSign'
                }
              >
                {t(isPrivacyPracticeSigned ? 'core.read' : 'core.readAndSign')}
              </Button>
            </DocumentWrapper>
          )}

          {isHealthDocumentVisible && (
            <DocumentWrapper id="health-document">
              <TypographyWrapper>
                <StyledIcon
                  className={clsx({
                    'intake__icon-check': isPrivacyPracticeSigned,
                    'intake__icon-exclamation': !isPrivacyPracticeSigned,
                  })}
                  src={isHealthInformationSigned ? CheckIcon : ExclamationIcon}
                />
                <Typography $weight="normal">
                  {t('intro.healthDescription')}
                </Typography>
              </TypographyWrapper>

              <Button
                $outlined
                onClick={() => documentPageHandler(DocumentType.HEALTH)}
                data-element-id={
                  isHealthInformationSigned
                    ? 'X_OEE_Intro_Health-Read'
                    : 'X_OEE_Intro_Health-ReadandSign'
                }
              >
                {t(
                  isHealthInformationSigned ? 'core.read' : 'core.readAndSign'
                )}
              </Button>
            </DocumentWrapper>
          )}
        </Wrapper>

        <DocumentModal
          isOpen={isDocumentModalVisible}
          onRequestClose={toggleDocumentModalVisibility}
          docType={documentModalType}
        />

        <LegalSignModal
          isOpen={isLegalSignModalVisible}
          onRequestClose={toggleLegalSignModalVisibility}
          documentPageHandler={documentPageHandler}
          availableDocuments={{
            [DocumentType.HEALTH]: isHealthDocumentVisible || false,
            [DocumentType.PRIVACY]: isPrivacyDocumentVisible || false,
          }}
        />
      </>
    )
  }

  return null
}

export default DocumentSection
