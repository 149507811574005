import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { useAsync } from 'react-async'
import { useToggle } from 'react-use'
import {
  Form,
  FormService,
  Config as ConfigServiceType,
  ConfigService,
  Appointment,
  AppointmentService,
  FormId,
  Document,
  DocumentService,
  SignatureService,
  DocumentType,
} from '../api'
import { useConfig } from '../Context'
import { ErrorModal } from '../components/ErrorModal'

const neverEndingFormPromise = new Promise<Form>(() => { })
const neverEndingDocumentPromise = new Promise<Document>(() => { })

const useAsyncWithForm = () => useAsync<Form>({})
const useAsyncWithFormConfigs = () => useAsync<ConfigServiceType>({})
const useAsyncWithAppointment = () => useAsync<Appointment>({})

type ContextFormValueType = ReturnType<typeof useAsyncWithForm>
type ContextFormConfigsValueType = ReturnType<typeof useAsyncWithFormConfigs>
type ContextAppointmentValueType = ReturnType<typeof useAsyncWithAppointment>

export const TabApiFormContext = createContext({} as ContextFormValueType)

export const TabApiFormConfigsContext = createContext(
  {} as ContextFormConfigsValueType
)

export const TabApiAppointmentContext = createContext(
  {} as ContextAppointmentValueType
)

export const useTabApiForm = () => useContext(TabApiFormContext)

export const useTabApiConfig = () => useContext(TabApiFormConfigsContext)

export const useTabApiAppointment = () => useContext(TabApiAppointmentContext)

export const useLegalDocument = (
  docType: DocumentType,
  formId: string | undefined
) => {
  const {
    brand,
    lang = 'en',
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
    country,
  } = useConfig()

  const language = lang.split('-')[0].toUpperCase()

  const getDocuments = useCallback(
    () =>
      formId
        ? DocumentService.getDocuments({
          brand,
          docType: docType.toUpperCase(),
          language,
          caller,
          ocpApimSubscriptionKey,
          formId,
          // @ts-ignore
          acceptedLanguage: country ? `en-${country}` : undefined,
        })
        : neverEndingDocumentPromise,
    [formId, brand, docType, language, caller, ocpApimSubscriptionKey, country]
  )

  return useAsync({
    promiseFn: getDocuments,
  })
}

export const useAvailableDocuments = () => {
  const { brand, caller, subscriptionKey: ocpApimSubscriptionKey } = useConfig()

  const getAvailableDocuments = useCallback(
    () =>
      DocumentService.getDocumentVisibility({
        brand,
        caller,
        ocpApimSubscriptionKey,
      }),
    [brand, caller, ocpApimSubscriptionKey]
  )

  return useAsync({
    promiseFn: getAvailableDocuments,
  })
}

export const useSignature = (docType: DocumentType) => {
  const {
    brand,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
    appointmentId,
  } = useConfig()

  const getSignature = useCallback(
    () =>
      SignatureService.getSignatureByApptId({
        brand,
        docType,
        caller,
        ocpApimSubscriptionKey,
        appointmentId,
      }),
    [appointmentId, brand, docType, caller, ocpApimSubscriptionKey]
  )

  return useAsync({
    promiseFn: getSignature,
  })
}

export const TabApiProvider: React.FC = ({ children }) => {
  const {
    brand,
    appointmentId,
    formId: configFormId,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
  } = useConfig()
  const [appointmentFormId, setAppointmentFormId] = useState<string>()
  const formId = configFormId || appointmentFormId

  const getFormIdFromAppointment = useCallback(
    () =>
      FormService.getFormByApptId({
        brand,
        appointmentId,
        caller,
        ocpApimSubscriptionKey,
      }),
    [brand, caller, appointmentId, ocpApimSubscriptionKey]
  )

  const getForm = useCallback(
    () =>
      formId
        ? FormService.getFormById({
          brand,
          id: formId,
          caller,
          ocpApimSubscriptionKey,
        })
        : neverEndingFormPromise,
    [brand, caller, formId, ocpApimSubscriptionKey]
  )

  const getAppointment = useCallback(
    () =>
      AppointmentService.getAppointment({
        appointmentId,
        brand,
        caller,
        ocpApimSubscriptionKey,
      }),
    [appointmentId, brand, caller, ocpApimSubscriptionKey]
  )

  const getFormConfigs = useCallback(
    () =>
      ConfigService.getConfigs({
        brand,
        caller,
        ocpApimSubscriptionKey,
      }),
    [brand, caller, ocpApimSubscriptionKey]
  )

  const putForm = useCallback(
    ([requestBody]: [Form]) => {
      return formId
        ? FormService.upsertFormById({
          brand,
          id: formId,
          caller,
          ocpApimSubscriptionKey,
          requestBody,
        })
        : neverEndingFormPromise
    },
    [brand, caller, formId, ocpApimSubscriptionKey]
  )

  useAsync<FormId>({
    promiseFn: getFormIdFromAppointment,
    onResolve: ({ id }) => setAppointmentFormId(id),
  })

  const tabApiFormContextValue = useAsync<Form>({
    promiseFn: getForm,
    deferFn: putForm as (...args: any[]) => Promise<Form>,
  })

  const tabApiFormConfigsContextValue = useAsync<ConfigServiceType>({
    promiseFn: getFormConfigs,
  })

  const originRun = tabApiFormConfigsContextValue.run
  tabApiFormConfigsContextValue.run = (...args) => {
    originRun.call(tabApiFormConfigsContextValue, args)
  }

  const tabApiAppointmentContextValue = useAsync<Appointment>({
    promiseFn: getAppointment,
  })

  const [isErrorModalVisible, toggleErrorModalVisibility] = useToggle(false)
  const error =
    tabApiFormContextValue.error ||
    tabApiFormConfigsContextValue.error ||
    tabApiAppointmentContextValue.error

  useEffect(() => {
    if (error) {
      toggleErrorModalVisibility(true)
      console.error(error)
    } else {
      toggleErrorModalVisibility(false)
    }
  }, [error, toggleErrorModalVisibility])

  return (
    <TabApiFormConfigsContext.Provider value={tabApiFormConfigsContextValue}>
      <TabApiFormContext.Provider value={tabApiFormContextValue}>
        <TabApiAppointmentContext.Provider
          value={tabApiAppointmentContextValue}
        >
          {children}
          <ErrorModal
            isOpen={isErrorModalVisible}
            onRequestClose={toggleErrorModalVisibility}
          />
        </TabApiAppointmentContext.Provider>
      </TabApiFormContext.Provider>
    </TabApiFormConfigsContext.Provider>
  )
}
